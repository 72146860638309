import styled from "@emotion/styled";

export const Root = styled.div`
  background-color: #f5f7fa;
  margin-left: 72px;
  min-height: 100vh;
  width: calc(100% - 72px);
  .react-datepicker-wrapper input {
    border: 1px solid #ccc;
    border-radius: 4px !important;
    padding: 2px 6px;
    font-size: 12px;
    border-radius: 10px;
    height: 35px;
    width: 150px;
    background-color: none;
    cursor: pointer;
  }
  .page-header {
    z-index: 100;
    align-items: center;
    background-color: #fff;
    box-shadow:
      0px 1px 2px rgba(46, 46, 54, 0.1),
      2px 1px 3px rgba(46, 46, 54, 0.05);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    font-weight: 500;
    height: 54px;
    padding: 0 24px;
    position: relative;
  }
  .list-header_wrap {
    align-items: center;
    background-color: #fff;
    color: #555;
    display: grid;
    font-size: 16px;
    font-weight: 600;
    grid-column-gap: 12px;
    grid-template-columns: 8fr 10fr 10fr 10fr 7fr 7fr 6fr;
    /* max-width: 1450px; */
    padding: 16px 24px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 150;
  }
  .list-header_wrap-filter {
    padding: 8px 24px;
    background-color: #f4f6f8;
    svg:hover {
      cursor: pointer;
      g {
        stroke: #4440db;
      }
    }
  }
  .button-styles_clear {
    align-items: center;
    background-color: #e5e5e5;
    border-radius: 4px;
    display: flex;
    font-size: 12px;
    height: 32px;
    justify-content: center;
    padding: 8px;
    /* position: absolute; */
    /* right: 8px; */
    text-transform: capitalize;
    z-index: 50;
    cursor: pointer;
    :hover {
      background-color: #d1d1d1;
    }
  }
  .date-header_wrap {
    align-items: center;
    background-color: #f4f6f8;

    color: #555;
    font-size: 12px;
    font-weight: 500;
    padding: 10px 10px;
  }
  .orders-list_wrap {
    background-color: #fff;
    border-radius: 4px;
    box-shadow:
      0px 1px 2px rgba(46, 46, 54, 0.1),
      0px 1px 3px rgba(46, 46, 54, 0.05);
    height: calc(100vh - 90px);
    margin-top: 16px;
    max-height: calc(100vh - 90px);
    max-width: 1600px;
    overflow: hidden;
    padding: 0px 0px 8px;
    position: relative;
    width: 100%;
  }

  .order-row_wrap {
    border-bottom: 1px solid #ccc;
  }
  .textFieldStyles .MuiOutlinedInput-root {
    border-radius: 4px;
    /* height: 35px; */
    width: 140px;
    cursor: pointer;
    background-color: #fff;
  }

  .select-styles .MuiSelect-select {
    border-radius: 10px;
    height: 35px;
    width: 120px;
    background-color: none;
    background-color: #fff;
  }
`;

export const SortArrow = styled.div<{ flip: boolean }>`
  margin-left: 4px;
  transform: ${(props) => (props.flip ? "rotate(180deg)" : "rotate(0deg)")};
  transition: "all 0.2s";
  cursor: pointer;
  :hover {
    color: #4440db;
  }
  svg {
    position: relative;
    top: 1px;
  }
`;
