/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Fragment, useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { AutoSizer, List } from "react-virtualized";

import "react-virtualized/styles.css";
import { isMobile } from "../../helpers/formatters";
import { useDocuments } from "../../Providers/DocumentsProvider";
import ActionBar from "./ActionsBar";
import CategorySection from "./CategorySection";
import DocumentRow from "./DocumentRow";
import {
  DocumentsStyles,
  DragOverlay,
  ScrollToTop,
  SkeletonWrap,
  TabsWrap,
  DocumentsNotFound
} from "./documentsStyles";
import { ScrollUpIcon } from "../../../src/ui/icons";
import { ModalType } from "../../Providers/types";
import { Bones } from "../../ui/Skeleton";

const DocumentsSection = ({
  vertical,
  hideSide,
  isDocumentsLoading,
  hasOrder
}: {
  vertical: boolean;
  hideSide: boolean;
  isDocumentsLoading: boolean;
  hasOrder: boolean;
}) => {
  const {
    documentsByCategory,
    documentViewMode,
    isTabView,
    setIsTabView,
    activeTabCategory,
    setActiveTabCategory,
    handleUploadDrop
  } = useDocuments();
  const tableRef = useRef(null);
  const [clickedLinks, setClickedLinks] = useState([]);
  const onLinkClick = (id: number) => {
    setClickedLinks([...clickedLinks, id]);
  };
  const flatDocuments = documentsByCategory
    .flatMap((c) => c.subcategories)
    .flatMap((s) => s.documents)
    .sort((a, b) => (a.date1 > b.date1 ? -1 : 1));

  const getRenderSection = () => {
    if (documentViewMode === "category") {
      return documentsByCategory.map((c) => <CategorySection documentCategory={c} key={c.name} />);
    }

    if (isMobile()) {
      const renderRow = ({ index, key, style }) => {
        const doc = flatDocuments[index];
        return (
          <div style={style} key={key}>
            <DocumentRow
              key={doc.id}
              document={doc}
              hasBeenClicked={!!clickedLinks.find((d) => doc.id === d)}
              onLinkClick={() => onLinkClick(doc.id)}
            />
          </div>
        );
      };
      return (
        <AutoSizer>
          {({ height, width }) => {
            return (
              <List
                width={width}
                height={height}
                rowHeight={66}
                rowRenderer={renderRow}
                rowCount={flatDocuments.length}
              />
            );
          }}
        </AutoSizer>
      );
    } else {
      return flatDocuments.map((doc) => (
        <DocumentRow
          key={doc.id}
          document={doc}
          hasBeenClicked={!!clickedLinks.find((d) => doc.id === d)}
          onLinkClick={() => onLinkClick(doc.id)}
        />
      ));
    }
  };

  useEffect(() => {
    if (documentViewMode === "date") {
      tableRef.current?.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  }, [documentViewMode]);

  const tabItems = documentsByCategory.map((c, i) => (
    <div key={i} className={c.name === activeTabCategory && "active-tab"} onClick={() => setActiveTabCategory(c.name)}>
      {c.name}
    </div>
  ));

  const { handleScrollClick } = useDocuments();
  const { currentOpenModal, isNotesBarSectionOpen, isEditBarSectionOpen } = useDocuments();
  const [scrollPosition, setScrollPosition] = useState(0);

  function getScrollPositionCat() {
    var ContainerElement = document.getElementById("table");
    setScrollPosition(ContainerElement.scrollTop);
  }

  function getScrollPositionTab() {
    var ContainerElement = document.getElementById("tabView");
    setScrollPosition(ContainerElement.scrollTop);
  }

  function ScrollToTopByDate() {
    tableRef.current.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }

  return (
    <div>
      {hasOrder && (
        <ActionBar
          setTabView={() => setIsTabView(true)}
          setListView={() => setIsTabView(false)}
          tabView={isTabView}
          hideSide={hideSide}
        />
      )}
      <DocumentsStyles vertical={vertical} tabView={isTabView}>
        {isDocumentsLoading && <LoadingSkeleton />}
        {hasOrder && (
          <div>
            {isTabView ? (
              <Fragment>
                <TabsWrap>{tabItems}</TabsWrap>
                <div className="tab-list_wrap" id="tabView" onScroll={getScrollPositionTab}>
                  <CategorySection
                    tabView={isTabView}
                    documentCategory={
                      documentsByCategory.find((dbc) => dbc.name === activeTabCategory) || documentsByCategory[0]
                    }
                  />
                </div>
              </Fragment>
            ) : (
              <div ref={tableRef} id="table" className="full-list_wrap" onScroll={getScrollPositionCat}>
                {isMobile() && !!documentsByCategory.length && getRenderSection()}
                {isMobile() && documentsByCategory.length === 0 && (
                  <DocumentsNotFound>There are no documents for this order.</DocumentsNotFound>
                )}
                {!isMobile() && (
                  <Dropzone noClick onDrop={handleUploadDrop} multiple={false}>
                    {({ getRootProps, getInputProps, isDragActive }) => {
                      return (
                        <div {...getRootProps()}>
                          <DragOverlay show={isDragActive}>
                            <div className="drag-overlay-message">Drop files here to upload</div>
                          </DragOverlay>
                          <input {...getInputProps()} />
                          {!isDocumentsLoading && (
                            <div style={{ paddingBottom: 80 }}>
                              {!!documentsByCategory.length ? (
                                getRenderSection()
                              ) : (
                                <DocumentsNotFound>There are no documents for this order.</DocumentsNotFound>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    }}
                  </Dropzone>
                )}
                <div>
                  <ScrollToTop
                    show={
                      currentOpenModal === ModalType.None &&
                      !isNotesBarSectionOpen &&
                      !isEditBarSectionOpen &&
                      documentViewMode !== "date" &&
                      scrollPosition > 100
                    }
                    onClick={() => handleScrollClick(documentsByCategory[0].subcategories[0].scrollToName)}
                  >
                    <ScrollUpIcon />
                  </ScrollToTop>
                  <ScrollToTop
                    show={
                      currentOpenModal === ModalType.None &&
                      !isNotesBarSectionOpen &&
                      !isEditBarSectionOpen &&
                      documentViewMode === "date" &&
                      scrollPosition > 100
                    }
                    onClick={ScrollToTopByDate}
                  >
                    <ScrollUpIcon />
                  </ScrollToTop>
                </div>
              </div>
            )}
          </div>
        )}
      </DocumentsStyles>
    </div>
  );
};

export default DocumentsSection;

function LoadingSkeleton() {
  return (
    <SkeletonWrap>
      <div>
        <Bones />
        <Bones />
        <Bones />
        <Bones />
        <Bones />
        <Bones />
        <Bones />
        <Bones />
        <Bones />
        <Bones />
        <Bones />
        <Bones />
        <Bones />
        <Bones />
        <Bones />
        <Bones />
        <Bones />
        <Bones />
        <Bones />
        <Bones />
        <Bones />
        <Bones />
        <Bones />
        <Bones />
        <Bones />
        <Bones />
        <Bones />
      </div>
    </SkeletonWrap>
  );
}
