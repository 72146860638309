import styled from "@emotion/styled";

export const DropzoneWrap = styled.div`
  padding: 16px;
  border-top: 1px solid #e5e5ea;
`;

export const DocumentOptions = styled.div<{ required?: boolean }>`
  border: ${(props) => (!props.required ? "1.4px dashed #e5e5ea" : "1px solid red")};
  padding: 8px;
  border-radius: 4px;
`;
export const UploadButton = styled.button`
  background-color: #ff5252;
  border-radius: 5px;
  color: #fff;
  height: 32px;
  padding: 5px 16px;
  margin-right: 16px;
  font-weight: bold;
  cursor: pointer;
`;
export const DragDrop = styled.span`
  padding-left: 16px;
  border-left: 2px solid #e5e5ea;
`;
