import styled from "@emotion/styled";

export const Root = styled.div<{ vertical: boolean }>``;
export const LoadingCard = styled.div`
  /* box-shadow: 0px 1px 2px rgba(46, 46, 54, 0.1), 0px 1px 3px rgba(46, 46, 54, 0.05); */
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  color: #1f2b5e;
  position: relative;
  padding: 24px;
  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
  }
`;
export const InfoCard = styled.div<{ vertical: boolean; showMore?: boolean; hidePropInfo?: boolean }>`
  background-color: #fff;
  border-top: none;
  color: #1f2b5e;
  display: inline-flex;
  flex-direction: ${(props) => props.vertical && "column"};
  position: relative;
  width: 100%;
  .parcels_of_parcel {
    display: flex;
    align-items: flex-end;
    position: relative;
    justify-content: space-between;
    .value {
      font-size: 16px;
      /* font-weight: 400; */
    }
  }
  .copied:before {
    content: "COPY";
    align-items: center;
    background-color: rgba(59, 63, 67, 0.95);
    border-radius: 4px;
    bottom: 0;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    text-transform: uppercase;
    top: 0;
    width: 100%;
    z-index: 3;
  }
  .copied:hover:before,
  .copied:before {
    content: "Copied!";
  }

  .mobile-hide-data {
    background-color: #fff;
    bottom: ${(props) => (props.showMore ? "0" : "-100%")};
    box-shadow: 0px -5px 15px rgba(31, 43, 94, 0.09);
    position: fixed;
    transition: all 0.25s;
    width: 100vw;
    z-index: 100;
    border-radius: 20px 20px 0 0;
    border-radius: ${(props) => (props.showMore ? "20px 20px 0 0" : "0px")};
    overflow: hidden;
    z-index: 1010;
  }

  .close-sidebar.close-sidebar {
    display: none;
  }
  .mobile-subject-line {
    position: relative;
    font-size: 12px;
    display: flex;
    align-items: flex-start;
    line-height: 1.2;
    padding: 20px;
    svg {
      max-width: 20px;
      margin-top: 2px;
      margin-right: 4px;
    }
  }
  .order-address_wrap {
    .value {
      color: #000e48;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5em;
    }
    .title {
      color: #707898;
      font-size: 12px;
      font-weight: 600;
      line-height: 1.5em;
      line-height: 18px;
      margin-top: 4px;
    }
  }
  .info-cells_wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;
    border-top: 1px solid #ebecf1;
    .property-info,
    .basic-info {
      display: grid;
      grid-column-gap: 2%;
      grid-row-gap: 16px;
      grid-template-columns: 49% 49%;
      width: 100%;
      padding: 24px 24px 4px;
      border-top: 1px solid #ebecf1;
    }
    .property-info {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;
      justify-content: space-between;
      .order-address_wrap {
        width: 100%;
      }
      .block {
        width: auto;
      }
    }
    .address-tools {
      display: flex;
      font-size: 10px;
      font-weight: bold;
      justify-content: space-between;
      letter-spacing: 0.1em;
      margin-top: 12px;
      padding: 8px 16px 4px;
      width: 100%;
      border-top: 1px solid #ebecf1;
      align-items: center;
      svg {
        max-width: 16px;
      }
      .pagination-arrows {
        align-items: center;
        display: flex;
        svg {
          max-width: 12px;
          cursor: pointer;
          :first-of-type {
            transform: rotate(-180deg);
          }
          :hover {
            polyline {
              stroke: #00b8d4;
            }
          }
        }
        div {
          margin: 0 4px;
          font-size: 12px;
        }
      }
      .map-copy {
        align-items: center;
        display: flex;
        a {
          align-items: center;
          background-color: transparent;
          border-radius: 50%;
          display: inline-flex;
          height: 28px;
          justify-content: center;
          margin-left: 12px;
          transition: all 0.5s;
          width: 28px;
          flex: 1 0 auto;
          :first-of-type {
            margin-left: 0;
          }
        }
        .search-in-maps,
        .search-in-google {
          :hover {
            background-color: #eee;
            svg {
              path:nth-of-type(2) {
                fill: #4caf50;
              }
              path:first-of-type {
                fill: #1976d2;
              }
              path:nth-of-type(3) {
                fill: #ff3d00;
              }
              path:nth-of-type(4) {
                fill: #4caf50;
              }
              path:last-of-type {
                fill: #ffc107;
              }
            }
          }
        }
        svg {
          cursor: pointer;
          height: 100%;
          max-width: 100%;
          transition: all 0.5s;
          width: 100%;
          path {
            transition: all 0.5s;
          }
        }
      }
    }
  }
  .close-sidebar.close-sidebar {
    width: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    svg {
      stroke: #00b8d4;
      stroke-width: 1.5;
      transition: all 0.1s;
      width: 20px;
      max-width: 20px;
    }
    :hover {
      svg {
        stroke: #1f2b5e;
      }
    }
  }
  @media (min-width: 900px) {
    border-radius: 0px;
    padding: 8px 0px;
    flex: 0 1 auto;
    border: 1px solid #efefef;
    .title-number {
      font-size: 28px;
    }

    .info-cells_wrap {
      margin-top: 16px;
      .basic-info {
        padding-top: 16px;
        grid-row-gap: 22px;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        border-top: none;
      }
      .address-tools {
        padding-bottom: 0;
      }
    }
    .mobile-subject-line,
    .account-rep_mobile {
      display: none;
    }
    .mobile-hide-data {
      height: auto;
      position: relative;
      box-shadow: none;
      width: 100%;
      overflow: initial;
    }
    .property-info {
      margin-top: 0px;
    }

    .value {
      font-size: 18px;
    }
    .title {
      font-size: 12px;
    }
    .order-address_wrap {
      .value {
        font-size: 16px;
        margin-top: 2px;
      }
      .title {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
  .collapse-prop-info_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    position: absolute;
    width: calc(100% - 48px);
    margin-top: -12px;
    svg {
      position: absolute;
      right: -4px;
      width: 18px;
      height: 18px;
      top: 2px;
      transform: rotate(${(props) => (props.hidePropInfo ? "0deg" : "180deg")});
      transition: all 0.1s;
      cursor: pointer;
    }
  }
`;

export const OrderHeader = styled.div`
  background-color: #000e48;
  color: #fff;
  .mobile-title_wrap {
    align-items: center;
    display: flex;
    padding: 8px;
    justify-content: space-between;
  }
  .title-number {
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    position: relative;
    transition: all 0.15s;
    display: none;
    > * {
      align-items: center;
      display: inline-flex;
    }
    svg {
      height: 18px;
      width: 18px;
      display: none;
    }
  }
  .oijeveoijswr {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #47517b;
    color: #fff;
    svg {
      max-width: 20px;
      width: 20px;
      height: auto;
    }
  }
  .account-rep_mobile {
    align-items: center;
    display: inline-flex;
    justify-content: flex-end;
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    svg {
      max-width: 22px;
      margin-right: 4px;
      stroke-width: 1;
    }
  }
  .desktop-sub-info {
    padding-top: 0;
    display: flex;
    align-items: flex-end;
    display: none;
  }
  .order-details {
    width: calc(100% - 40px);
    padding-right: 12px;
    max-height: 40px;
    overflow-y: hidden;
    font-size: 12px;
  }

  .order-details {
    align-items: flex-start;
    display: flex;
    font-weight: 300;
    justify-content: space-between;
    line-height: 20px;
    margin-top: 10px;
    position: relative;
    transition: all 0.15s;
    display: none;
    svg {
      max-width: 18px;
      margin-top: 2px;
      height: 18px;
      display: none;
      cursor: pointer;
      :hover {
        path {
          stroke: #00b8d4;
        }
      }
    }
    span {
      font-size: 12px;
      width: 100%;
      max-width: calc(100% - 22px);
      overflow: hidden;
      max-height: 58px;
      overflow-y: auto;
      line-height: 1.3;
    }
  }
  @media (min-width: 900px) {
    background-color: #fff;
    color: #000e48;
    .account-rep_mobile {
      display: none;
    }
    .subject-line {
      display: flex;
      padding: 8px;
      align-items: flex-start;
      width: 100%;
      padding-right: 0px;
      overflow: hidden;
      font-size: 13px;
      font-weight: 300;
      line-height: 1.3;
      position: relative;
      cursor: pointer;
      :hover {
        color: #00b8d4;
      }
      span {
        width: 100%;
        max-width: calc(100% - 24px);
        overflow: hidden auto;
        max-height: 58px;
        padding-left: 4px;
      }
      svg {
        max-width: 20px;
      }
    }
    .order-details {
      margin-top: 16px;
      width: 100%;
      padding-right: 0;
      overflow: hidden;
      span {
        overflow: hidden;
      }
    }
    .desktop-sub-info {
      display: flex;
    }
    .title-number {
      display: flex;
      width: 100%;
      align-items: center;
      cursor: pointer;
      :hover {
        span {
          color: #00b8d4;
        }
      }
      svg {
        display: block;
        max-width: 18px;
        margin-top: 2px;
        margin-right: 4px;
        cursor: pointer;
        :hover {
          path {
            stroke: #00b8d4;
          }
        }
      }
      span {
        width: calc(100% - 22px);
        font-size: 20px;
      }
    }
    .oijeveoijswr {
      display: none;
    }
  }
`;

export const InfoCellStyles = styled.div`
  align-self: flex-start;
  position: relative;
  .value {
    color: #000e48;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.3em;
    margin-top: 2px;
    max-height: 200px;
    overflow: auto;
    cursor: pointer;
  }
  .title {
    color: #707898;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5em;
  }
  @media (min-width: 900px) {
    .value {
      font-size: 18px;
    }
    .title {
      font-size: 12px;
      font-weight: 400;
    }
  }
`;

export const SVGAnim = styled.div`
  rect {
    stroke-dasharray: 300;
    /* stroke-dashoffset: 1000; */
    animation: dash 3s linear forwards;
    animation-iteration-count: infinite;
    animation-direction: reverse;
  }

  @keyframes dash {
    to {
      stroke-dashoffset: 1000;
    }
  }
`;

export const RelatedOrdersWrap = styled.div<{ showRelatedOrders?: boolean }>`
  display: inline-block;
  margin-left: 16px;
  margin-top: 8px;
  position: relative;
  .pesach {
    align-items: center;
    color: #000e48;
    cursor: pointer;
    display: inline-flex;
    font-size: 12px;
    font-weight: 300;
    margin-left: 10px;
    transition: all 0.1s ease-in-out;
    svg {
      margin-left: 6px;
      margin-top: 2px;
      max-width: 12px;
      transform: ${(props) => (props.showRelatedOrders ? "rotate(180deg)" : "rotate(0deg)")};
      transition: all 0.1s ease-in-out;
      path {
        stroke-linecap: butt;
        stroke-width: 1;
        stroke: currentColor;
        transition: all 0.1s ease-in-out;
      }
    }
    :hover {
      color: #00b8d4;
      path {
        stroke: #00b8d4;
      }
    }
  }
  .dropdown {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ebecf1;
    display: ${(props) => (props.showRelatedOrders ? "block" : "none")};
    min-width: 200px;
    padding: 4px 0px;
    position: absolute;
    top: 26px;
    width: auto;
    z-index: 1090;
    div {
      border-bottom: 1px solid #ebecf1;
      font-size: 13px;
      line-height: 2;
      padding: 4px 16px;
      transition: all 0.1s;
      a {
        align-items: center;
        display: flex;
        white-space: nowrap;
        svg {
          color: #000e48;
          padding-left: 4px;
          padding-top: 4px;
          width: 20px;
        }
      }
      :last-of-type {
        border-bottom: none;
      }
      :hover {
        background-color: #f5f5f5;
        color: #333;
      }
    }
  }
`;

export const OuterStatusWrap = styled.div`
  .closed-completed {
    .title,
    .value {
      color: rgb(68, 64, 219);
    }
  }
  .adjourned-status {
    .title {
      color: #b72136;
    }
  }
  .empty-value {
    color: #000e48;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.3em;
    margin-top: 2px;
  }
`;

export const OrderStatusWrap = styled.div`
  align-items: center;
  color: #000e48;
  display: inline-flex;
  font-size: 14px;
  font-weight: 600;
  height: 100%;
  line-height: 1.5em;
  text-transform: uppercase;
  width: 100%;
  div {
    background: rgb(57, 181, 74, 0.12);
    border-radius: 4px;
    color: rgb(57, 181, 74);
    padding: 4px 8px;
    text-align: center;
    width: 100%;
  }
  .Duplicate,
  .Hold {
    background: rgba(255, 165, 0, 0.12);
    color: #ffa500;
  }
  .Canceled {
    color: #ca0000;
    background: rgba(199, 0, 0, 0.12);
  }
  .Pending {
    color: #0066ff;
    background: rgba(139, 196, 248, 0.8);
  }
  .Closed {
    color: #ffde00;
    background: rgb(254, 255, 175);
  }
`;

export const MobileOrderHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: #000e48;
  height: 48px;
  position: absolute;
  top: 0px;
  z-index: 90;
  width: 100vw;
  padding: 0 8px;
  justify-content: space-between;
  color: #fff;
  .mobile-title-number {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
  }
  .momentum-logo {
    border-radius: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    svg {
      max-height: 40px;
      path {
        fill: #fff;
      }
    }
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

export const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 99;
  background: rgba(0, 14, 72, 0.52);
  backdrop-filter: blur(1px);
  @media (min-width: 900px) {
    display: none;
  }
`;
