import styled from "@emotion/styled";

import bgcImage from "./images/menorah-bgc.svg";
// import bgcImage2 from "./images/chanukah2.svg";
type LaytoutProps = {
  open?: boolean;

  hideSide?: boolean;
  allChecked?: boolean;
};
export const ActionBarWrap = styled.div<LaytoutProps>`
  border: 1px solid #efefef;
  background-color: #fff;
  font-size: 13px;
  font-weight: 600;
  justify-content: space-between;
  margin: 0px;
  padding: 8px;
  position: relative;
  width: 100%;
  z-index: 50;
  .mobile-merge-print {
    display: block;
  }
  .top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .left-side,
    .right-side {
      display: flex;
      align-items: center;
    }
    .left-side {
      justify-content: flex-start;
      display: none;
    }
  }
  .account-rep_override {
    display: none;
  }
  .middle {
    /* height: ${(props) => (props.hideSide ? "fit-content" : "0")}; */
    overflow: hidden;
    font-size: large;
    font-weight: 600;
    display: ${(props) => (props.hideSide ? "none" : "block")};
  }
  .right-side {
    width: 100%;
    justify-content: space-between;
  }
  .bottom-row {
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    display: none;
    flex-direction: row;
    height: 40px;
    justify-content: space-between;
    margin-top: 12px;

    .check-all-button {
      background-color: ${(props) => (props.allChecked ? "#1f2b5e" : "#fff")};
      color: ${(props) => (props.allChecked ? "#fff" : "#000e48")};
    }
  }
  .order-search_wrap-syles {
    display: none;
  }
  @media (min-width: 768px) {
    .mobile-merge-print {
      display: none;
    }
    .top-row .left-side {
      display: flex;
    }
  }
  @media (min-width: 900px) {
    border-radius: 0 0 4px 4px;
    .bottom-row {
      display: flex;
    }
    .right-side {
      width: auto;
    }
    .order-search_wrap-syles {
      display: flex;
      .order-search_icon {
        background-color: #fff;
        height: 30px;
        width: 30px;
        padding: 0px;
        svg {
          max-width: 16px;
          path {
            stroke: #1f2b5e;
          }
        }
      }
    }

    .account-rep_override {
      display: block;
      span {
        white-space: nowrap;
      }
    }
  }
  @media (min-width: 1200px) {
    .middle {
      display: ${(props) => (props.hideSide ? "block" : "none")};
    }
  }
`;

export const ActionButton = styled.div`
  align-items: center;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-right: 8px;
  position: relative;
  width: 40px;
  svg {
    max-width: 24px;
    height: auto;
  }
  :hover {
    background-color: #1f2b5e;
    svg * {
      stroke: #fff;
    }
    .show-tooltip {
      opacity: 1;
    }
  }
`;

export const ActionButtonDark = styled(ActionButton)`
  background-color: #1f2b5e;
  color: #fff;
  svg * {
    stroke: #fff;
  }
`;

export const DateCategoryToggle = styled.div`
  display: flex;
  overflow: hidden;
  margin-top: 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
  div {
    align-items: center;
    border-radius: 0 4px 4px 0;
    color: #999;
    cursor: pointer;
    display: inline-flex;
    font-size: 10px;
    font-weight: 900;
    height: 28px;
    justify-content: center;
    letter-spacing: 0.04em;
    padding: 0 8px;
    text-transform: uppercase;
    transition: all 0.1s;
    :first-of-type {
      padding: 0 8px;
      border-radius: 4px 0 0 4px;
    }
    :hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  .selected-item {
    background-color: #1f2b5e;
    color: #fff;
    cursor: default;
    :hover {
      background-color: #1f2b5e;
    }
  }
  @media (min-width: 768px) {
    font-size: 10px;
    margin-right: 16px;
    margin-top: 0px;
    div {
      height: 30px;
      padding: 0 12px;
      :first-of-type {
        padding: 0 12px;
      }
    }
  }
`;

export const RepWrap = styled.div`
  align-items: center;
  /* background-color: #1f2b5e; */
  color: #1f2b5e;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  max-width: 174px;
  padding: 6px 8px;
  text-align: center;
  .icon-box {
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 2px;
    display: flex;
    height: 32px;
    justify-content: center;
    margin-right: 12px;
    width: 32px;
    svg {
      max-width: 20px;
    }
  }
  :hover {
    color: #00b8d4;
    svg path {
      stroke: #00b8d4;
    }
  }
`;

export const SearchWrap = styled.div`
  align-items: stretch;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dfe1e6;
  display: flex;
  height: 30px;
  padding: 0 16px;
  transition: all 0.1s;
  margin-top: 8px;
  max-width: 160px;
  svg {
    margin-right: 8px;
    max-width: 16px;
  }
  input {
    width: 100%;
    ::placeholder {
      color: #707898;
      font-size: 12px;
      padding-bottom: 2px;
    }
  }
  :hover {
    border: 1px solid #c2c5d3;
  }
  @media (min-width: 768px) {
    margin-top: 0px;
    height: 32px;
    max-width: auto;
    max-width: 200px;
  }
`;

export const FileNotesWrap = styled.div`
  align-items: center;
  border-radius: 4px;
  border: 1px solid #ebecf1;
  display: flex;
  font-size: 14px;
  margin-right: 16px;
  padding-right: 10px;
  padding: 4px;
  .icon-box {
    align-items: center;
    background: rgba(153, 159, 182, 0.1);
    border-radius: 4px;
    display: flex;
    height: 32px;
    justify-content: center;
    margin-right: 8px;
    width: 32px;
  }
`;

export const InfoHeaderWrap = styled.div`
  padding: 16px;
  border-bottom: solid 1px #e5e5ea;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .notes-icon {
    max-width: 22px;
  }
  .close-notes-button {
    cursor: pointer;
    stroke-width: 2;
    transition: all 0.1s;
    flex: 0 0 24px;
    :hover {
      stroke: #00b8d4;
    }
  }
  .contacts_notes_header {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const TabsWrap = styled.div<{ current?: boolean }>`
  height: 48px;
  border-radius: 5px;
  border: solid 1px #e5e5ea;
  background-color: #f7f7f9;
  display: flex;
  width: 248px;
  position: relative;
  ::before {
    position: absolute;
    content: "";
    height: 48px;
    border-radius: 5px;
    border: solid 1px #e5e5ea;
    background-color: #fff;
    width: 122px;
    left: ${(props) => (props.current ? "-1px" : "125px")};
    transition: all 0.1s;
    top: -1px;
  }
`;

export const Tab = styled.div<{ current?: boolean }>`
  position: relative;
  cursor: ${(props) => !props.current && "pointer"};
  display: inline-flex;
  height: 100%;
  color: ${(props) => (props.current ? "#333" : "#8e8e93")};
  align-items: center;
  justify-content: center;
  font-weight: bold;
  width: 122px;
  :hover {
    color: #333;
  }
`;

export const InfoContentWrap = styled.div`
  padding: 16px;
`;

export const NoteCard = styled.div<{ displayOnOrderOpen: boolean }>`
  background-color: #f7f7f9;
  border: ${(props) => (props.displayOnOrderOpen ? "1px solid #1f2b5e;" : null)};
  border-radius: 5px;
  font-size: 16px;
  line-height: 1.5;
  margin-top: 16px;
  color: #000e48;
  padding: 16px;
  .note-author {
    margin-top: 2px;
    font-weight: 600;
    font-size: 14px;
    span {
      color: #ee765c;
    }
  }
`;

export const OrderSearchWrap = styled.div<{ openSearch: boolean }>`
  border-radius: 4px;
  display: flex;
  margin-left: 24px;
  position: relative;
  flex-direction: row-reverse;
  .order-search_icon {
    align-items: center;
    border-radius: ${(props) => (props.openSearch ? "4px 0 0 4px" : "4px")};
    display: inline-flex;
    flex: 1 0 32px;
    height: 32px;
    justify-content: center;
    margin: 0;
    position: relative;
    width: 32px;
    z-index: 5;
    svg {
      max-width: 20px;
      path {
        stroke: #fff;
      }
    }
  }
  .order-search_search {
    background-color: #fff;
    border-radius: 4px;
    height: 30px;
    width: ${(props) => (props.openSearch ? "200px" : "0px")};
    padding: ${(props) => (props.openSearch ? "0 16px" : "0")};
    transition: all 0.1s;
    /* width: 100%; */
    z-index: 2;
    position: absolute;
    left: ${(props) => (props.openSearch ? "-200px" : "0px")};
    input {
      height: 100%;
      width: 100%;
      ::placeholder {
        color: #999;
      }
    }
  }
  @media (min-width: 900px) {
    border: 1px solid #1f2b5e;
    margin-left: 16px;
    overflow: auto;
    width: auto;
    flex-direction: row;
    .order-search_icon {
      border-radius: 0px;
      border-right: none;
      flex: 1 0 30px;
      width: 30px;
      height: 30px;
      path {
        stroke: #fff;
      }
    }
    .order-search_search {
      max-width: ${(props) => (props.openSearch ? "210px" : "0px")};
      border-radius: ${(props) => (props.openSearch ? "0 4px 4px 0" : "4px")};
      padding: ${(props) => (props.openSearch ? "0 16px 0 4px" : "0px")};
      position: relative;
      left: unset;
    }
  }
`;

export const SwitchViewWrap = styled.div<{ tabView?: boolean }>`
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  display: none;
  background-color: #f2f2f2;
  div {
    padding: 8px;
    display: flex;
    border-right: 1px solid #dfe1e6;
    cursor: pointer;
    transition: all 0.1s;
    :last-of-type {
      margin-right: 0;
      border-right: none;
    }
  }
  .tabview-rotate {
    transform: scaleX(-1);
  }
  svg {
    max-width: 16px;
    height: auto;
    transition: all 0.1s;
  }
  :hover {
    background-color: #eee;
    color: #000e48;
    /* svg,
    svg path {
      stroke: #000e48;
    } */
  }
  .current-view {
    background-color: #000e48;
    cursor: default;
    svg path {
      fill: #fff;
    }
  }
  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const TopToaster = styled.div`
  align-items: center;
  background-color: #ee765c;
  border-radius: 5px;
  color: #fff;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 0 8px;
  width: auto;
  svg {
    height: auto;
    max-width: 20px;
    path {
      stroke: #fff;
    }
  }
  span {
    color: #1f2b5e;
    margin: 0 4px;
  }
  .seymore {
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    border-radius: 3px;
    padding: 2px 6px;
    background-color: rgba(2, 224, 255, 0.8);
    margin-left: 8px;
    cursor: pointer;
    transition: all 0.1s;
    :hover {
      background-color: #17dffb;
    }
  }
  .nomore {
    cursor: pointer;
    padding: 2px;
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    background-color: #fff;
    width: 24px;
    height: 24px;
    transition: all 0.1s;
    svg {
      height: auto;
      stroke: #333;
    }
    :hover {
      background-color: #f7f7f9;
    }
  }
`;

export const ImportantNoteWrap = styled.div`
  background-color: #ee765c;
  padding: 0 24px;
  @media (max-width: 767px) {
    display: none;
  }
`;

export const ImportantNote = styled.div`
  color: #fff;
  padding: 8px 16px;
  font-size: 14px;
  text-align: center;
  border-bottom: 1px solid #ff9b86;
  font-weight: bold;
  :last-of-type {
    border-bottom: none;
  }
`;

export const CheckAllButton = styled.div`
  border: 1px solid red;
`;

export const ChanukaWrap = styled.div`
  align-items: center;
  color: #085ba7;
  font-size: 16px;
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.1s;
  cursor: pointer;
  display: none;
  @media (min-width: 1300px) {
    display: flex;
  }
  svg {
    max-width: 40px;
    margin: 0 10px;
    height: auto;
  }
  span {
    margin-bottom: 4px;
    border-bottom: 1px solid currentColor;
    letter-spacing: 0.15em;
  }
  :hover {
    color: #064884;
  }
`;

export const ChanukaModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50000;
  .chanuka-card {
    background-color: #fff;
    position: relative;
    z-index: 50;
    img {
      width: 1200px;
      max-width: 80vw;
    }
  }
`;

export const ChanukaModalWrap = styled.div`
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 15000;
  display: flex;
  align-items: center;
  justify-content: center;
  .wrapper {
    align-items: end;
    background: url(${bgcImage}) no-repeat center;
    box-shadow: 0px 4px 6px rgba(112, 120, 152, 0.1);
    box-shadow:
      0px 1px 2px rgba(46, 46, 54, 0.1),
      2px 1px 3px rgba(46, 46, 54, 0.05);
    color: #fff;
    color: #c79544;
    display: flex;
    flex-direction: column;
    font-family: serif;
    font-size: 32px;
    height: 700px;
    justify-content: center;
    letter-spacing: 0.02em;
    line-height: 1.5;
    max-width: 1000px;
    overflow: hidden;
    padding: 40px;
    padding-right: 130px;
    position: relative;
    text-align: right;
    width: 100%;
    z-index: 16000;
    img {
      width: 100%;
      height: auto;
      position: absolute;
    }
    .close_icon-dsfd {
      position: absolute;
      top: 24px;
      right: 10px;
      stroke-width: 2px;
      :hover {
        cursor: pointer;
      }
      z-index: 1000000000;
      color: white;
    }
    .happy_hanuka {
      font-size: 50px;
      font-family: "belda-normal";
    }
    .mt4 {
      font-family: interstate, sans-serif;
      font-weight: 200;
      font-style: normal;
    }
  }
  img {
    display: block;
  }
`;
