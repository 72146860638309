import styled from "@emotion/styled";

export const Toggle = styled.div<{ width?: string; toggled?: boolean }>`
  background-color: #f5f5fa;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  font-size: 13px;
  font-weight: 600;
  height: 40px;
  margin-right: 16px;
  padding: 4px;
  position: relative;
  /* width: 180px; */
  > div {
    width: ${(props) => props.width || "86px"};
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 3;
  }
  ::before {
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(103, 111, 141, 0.05);
    border-radius: 4px;
    cursor: default;
    content: "";
    position: absolute;
    width: ${(props) => props.width || "86px"};
    height: 32px;
    left: ${(props) => {
      let left = props.toggled ? (props.width ? parseInt(props.width.replace("px", "")) + 4 + "px" : "90px") : "4px";
      return left;
    }};
    transition: all 0.1s;
  }
`;
