import React, { useEffect, useState } from "react";
import { Grid, Checkbox, TextField, Typography, Switch, FormControlLabel, Autocomplete } from "@mui/material";
import { produce } from "immer";
import { Root, ToggleButtonWrap, JointInfoWrap } from "./buyerSellerFormStyles";
import validateEmail from "../../../utils/validateEmail";

export type TransactingPartyAddress = {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  email: string;
};

export type TransactingPartyIndividual = {
  firstName: string;
  middleName: string;
  lastName: string;
  gender?: string;
  vesting?: string;
  address?: TransactingPartyAddress;
};

export type TransactingPartyOrganization = {
  name: string;
  organizationType: string;
  payeeName: string;
  vesting: string;
  address: TransactingPartyAddress;
  isPayeeNameSameAsName: boolean;
};

export type TransactingPartyJoint = {
  relationshipType: string;
  jointOne: TransactingPartyIndividual;
  jointTwo: TransactingPartyIndividual;
};

export type BuyerSellerFormProps = {
  individual?: TransactingPartyIndividual;
  setIndividual: (individual: TransactingPartyIndividual) => void;
  organization?: TransactingPartyOrganization;
  setOrganization: (organization: TransactingPartyOrganization) => void;
  joint?: TransactingPartyJoint;
  setJoint: (joint: TransactingPartyJoint) => void;
  address: TransactingPartyAddress;
  setAddress: (address: TransactingPartyAddress) => void;
  sameAsPropertyAddress: boolean;
  setSameAsPropertyAddress: (same: boolean) => void;
  isJoint: boolean;
  setIsJoint: (joint: boolean) => void;
  contactType: string;
  isOrganization: boolean;
  setIsOrganization: (value: boolean) => void;
  isEdit: boolean;
};

export type TransactingParty = {
  individual?: TransactingPartyIndividual;
  organization?: TransactingPartyOrganization;
  joint?: TransactingPartyJoint;
  sameAsPropertyAddress: boolean;
};

export default function BuyerSellerForm({
  individual,
  setIndividual,
  organization,
  setOrganization,
  joint,
  setJoint,
  sameAsPropertyAddress,
  setSameAsPropertyAddress,
  address,
  setAddress,
  isJoint,
  setIsJoint,
  contactType,
  isOrganization,
  setIsOrganization,
  isEdit
}: BuyerSellerFormProps) {
  const vestings = [
    ",a corporation",
    ",a Georgia limited liability company",
    ",a limited liability company",
    ",a Limited Liability Partnership",
    ",a partnership",
    ",as community property",
    ",as her sole and separate property",
    ",as his sole and separate property",
    ",as joint tenants",
    ",as sole and separate property",
    ",as tenants by the entirety",
    ",as tenants in common",
    "Purchaser with contractual rights under a purchase agreement with the vested owner identified at Item 4 below.",
    "Trust"
  ];

  const organizationTypes = [
    "Corporation",
    "Limited Liability Corp.",
    "Limited Liability Company",
    "Limited Partnership",
    "Partnership",
    "Trust",
    "Estate",
    "Other"
  ];
  const [isValid, setIsValid] = useState(true);

  const handleIsPayeeNameSameAsNameChange = (value: boolean) => {
    setOrganization({ ...organization, isPayeeNameSameAsName: value });
  };

  return (
    <Root>
      <div className="bold ml1 mb4 fs16">{contactType}</div>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={6} sx={{ paddingTop: 16 }}>
          <ToggleButtonWrap>
            <div className={!isOrganization ? "active" : ""} onClick={() => setIsOrganization(false)}>
              Individual
            </div>

            <div
              className={isOrganization ? "active" : ""}
              onClick={() => {
                setIsOrganization(true);
                setIsJoint(false);
              }}
            >
              Organization
            </div>
          </ToggleButtonWrap>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ justifyContent: "flex-end", display: "flex" }}>
          {!isOrganization && (
            <FormControlLabel
              sx={{ marginRight: 0 }}
              control={<Switch checked={isJoint} onChange={() => setIsJoint(!isJoint)} />}
              label="Is Joint"
            />
          )}
        </Grid>
        {isJoint ? (
          <JointInfo joint={joint} setJoint={setJoint} />
        ) : (
          <>
            {isOrganization ? (
              <>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={organization.isPayeeNameSameAsName}
                        onChange={(e) => handleIsPayeeNameSameAsNameChange(e.target.checked)}
                      />
                    }
                    label="Payee same as name"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    placeholder="Name"
                    label="Name"
                    fullWidth
                    value={organization?.name}
                    onChange={(e) => setOrganization({ ...organization, name: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    fullWidth
                    multiple={false}
                    placeholder="Start typing..."
                    value={organization?.organizationType}
                    options={organizationTypes}
                    onChange={(e, value) =>
                      setOrganization({
                        ...organization,
                        organizationType: value
                      })
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Organization Type" placeholder="Start typing..." />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    placeholder="Payee Name"
                    fullWidth
                    label="Payee Name"
                    value={(organization.isPayeeNameSameAsName ? organization?.name : organization?.payeeName) || ""}
                    onChange={(e) =>
                      setOrganization({
                        ...organization,
                        payeeName: e.target.value
                      })
                    }
                    disabled={organization.isPayeeNameSameAsName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    multiple={false}
                    value={organization?.vesting}
                    options={vestings}
                    onChange={(e, value) => setJoint({ ...joint, relationshipType: value })}
                    renderInput={(params) => <TextField {...params} label="Vested In" placeholder="Start typing..." />}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    placeholder="First Name"
                    fullWidth
                    label="First Name"
                    value={individual?.firstName}
                    onChange={(e) =>
                      setIndividual({
                        ...individual,
                        firstName: e.target.value
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    placeholder="Middle Name"
                    fullWidth
                    label="Middle Name"
                    value={individual?.middleName}
                    onChange={(e) =>
                      setIndividual({
                        ...individual,
                        middleName: e.target.value
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    placeholder="Last Name"
                    fullWidth
                    label="Last Name"
                    value={individual?.lastName}
                    onChange={(e) => setIndividual({ ...individual, lastName: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ToggleButtonWrap>
                    <div
                      className={individual?.gender === "Male" ? "active" : null}
                      onClick={(e) => setIndividual({ ...individual, gender: "Male" })}
                    >
                      Male
                    </div>
                    <div
                      className={individual?.gender === "Female" ? "active" : null}
                      onClick={(e) => setIndividual({ ...individual, gender: "Female" })}
                    >
                      Female
                    </div>
                  </ToggleButtonWrap>
                </Grid>
              </>
            )}
          </>
        )}
        <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            checked={sameAsPropertyAddress}
            onChange={() => setSameAsPropertyAddress(!sameAsPropertyAddress)}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <Typography variant="body2">Address same as property</Typography>
        </Grid>
        {!sameAsPropertyAddress && (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                placeholder="Address"
                fullWidth
                label="Address"
                value={address?.address1}
                onChange={(e) => setAddress({ ...address, address1: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                placeholder="Address 2"
                label="Address 2"
                fullWidth
                value={address?.address2}
                onChange={(e) => setAddress({ ...address, address2: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                placeholder="City"
                fullWidth
                label="City"
                value={address?.city}
                onChange={(e) => setAddress({ ...address, city: e.target.value })}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                placeholder="State"
                fullWidth
                label="State"
                value={address?.state}
                onChange={(e) => setAddress({ ...address, state: e.target.value })}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                placeholder="Zip"
                fullWidth
                label="Zip"
                value={address?.zip}
                onChange={(e) => setAddress({ ...address, zip: e.target.value })}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            placeholder="Phone"
            fullWidth
            label="Phone"
            type="tel"
            value={address?.phone}
            onChange={(e) => setAddress({ ...address, phone: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            placeholder="Email"
            fullWidth
            label="Email"
            type="email"
            value={address?.email}
            error={!isValid}
            helperText={!isValid && "Invalid Email"}
            onFocus={() => setIsValid(true)}
            onChange={(e) => {
              setIsValid(true);
              setAddress({ ...address, email: e.target.value });
            }}
            onBlur={(e) => {
              setIsValid(validateEmail(e.target.value));
            }}
          />
        </Grid>
      </Grid>
    </Root>
  );
}

function JointInfo({
  joint,
  setJoint
}: {
  joint?: TransactingPartyJoint;
  setJoint: (joint: TransactingPartyJoint) => void;
}) {
  const { jointOne, jointTwo } = joint;
  const relationshipTypes = [
    ", a married man",
    ", a married person",
    ", a married woman",
    ", a single man",
    ", a single person",
    ", a single woman",
    ", a widow",
    ", a widower",
    ", an unmarried man",
    ", an unmarried person",
    ", an unmarried woman",
    ", as Executrix",
    ", her husband",
    ", his wife",
    ", husband and wife",
    ", wife and husband",
    "and",
    "and husband,",
    "and wife",
    "and wife,",
    "his wife",
    "husband and wife",
    "husband,",
    ", as Trustee",
    ", joint tenants with right of survivorship",
    ", unmarried",
    ", tenants by the entirety",
    ", single",
    ", married",
    ", as Administrator"
  ];

  const handleSetJoint = (
    prop1: keyof TransactingPartyJoint,
    prop2: keyof TransactingPartyIndividual,
    value: string
  ) => {
    setJoint(
      produce(joint, (draft) => {
        draft[prop1][prop2] = value;
      })
    );
  };

  return (
    <JointInfoWrap>
      <Grid item md={12} sx={{ textAlign: "center", marginTop: "20px" }}>
        <Autocomplete
          multiple={false}
          sx={{ width: "360px", margin: "0 auto 32px" }}
          value={joint?.relationshipType}
          onChange={(e, value) => setJoint({ ...joint, relationshipType: value })}
          options={relationshipTypes}
          renderInput={(params) => <TextField {...params} label="Joint Relationship" placeholder="Start typing..." />}
        />
      </Grid>
      <div className="sideBySide">
        <div>
          <div className="bold">Joint #1</div>
          <TextField
            placeholder="First Name"
            fullWidth
            label="First Name"
            value={jointOne?.firstName}
            onChange={(e) => handleSetJoint("jointOne", "firstName", e.target.value)}
          />
          <TextField
            placeholder="Middle Name"
            fullWidth
            label="Middle Name"
            value={jointOne?.middleName}
            onChange={(e) => handleSetJoint("jointOne", "middleName", e.target.value)}
          />
          <TextField
            placeholder="Last Name"
            fullWidth
            label="Last Name"
            value={jointOne?.lastName}
            onChange={(e) => handleSetJoint("jointOne", "lastName", e.target.value)}
          />
          <ToggleButtonWrap>
            <div
              className={jointOne?.gender === "Male" ? "active" : null}
              onClick={() => handleSetJoint("jointOne", "gender", "Male")}
            >
              Male
            </div>
            <div
              className={jointOne?.gender === "Female" ? "active" : null}
              onClick={() => handleSetJoint("jointOne", "gender", "Female")}
            >
              Female
            </div>
          </ToggleButtonWrap>
        </div>
        <div>
          <div className="bold">Joint #2</div>
          <TextField
            placeholder="First Name"
            fullWidth
            label="First Name"
            value={jointTwo?.firstName}
            onChange={(e) => handleSetJoint("jointTwo", "firstName", e.target.value)}
          />

          <TextField
            placeholder="Middle Name"
            fullWidth
            label="Middle Name"
            value={jointTwo?.middleName}
            onChange={(e) => handleSetJoint("jointTwo", "middleName", e.target.value)}
          />

          <TextField
            placeholder="Last Name"
            fullWidth
            label="Last Name"
            value={jointTwo?.lastName}
            onChange={(e) => handleSetJoint("jointTwo", "lastName", e.target.value)}
          />

          <ToggleButtonWrap>
            <div
              className={jointTwo?.gender === "Male" ? "active" : null}
              onClick={() => handleSetJoint("jointTwo", "gender", "Male")}
            >
              Male
            </div>
            <div
              className={jointTwo?.gender === "Female" ? "active" : null}
              onClick={() => handleSetJoint("jointTwo", "gender", "Female")}
            >
              Female
            </div>
          </ToggleButtonWrap>
        </div>
      </div>
    </JointInfoWrap>
  );
}
