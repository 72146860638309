import styled from "@emotion/styled";

const rowGrid = "14px 12fr 1fr 6fr 5fr 5fr";
const rowGridDesktop = "14px 12fr 2fr 4fr 100px 6fr";
// const rowGridDesktop = '20px 50px 12fr 4fr 100px 6fr';

export const MobileRowStyles = styled.div`
  background-color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding: 8px;
  border-bottom: 1px solid #5b6596;
  :last-of-type {
    border-bottom: none;
  }
  .check-desc {
    width: calc(100% - 100px);
    display: inline-flex;
    align-items: flex-start;
    input {
      margin-right: 10px;
    }
  }
  .row-one {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* height: 28px; */
    margin-bottom: 4px;
  }
  .doc-title {
    font-size: 12px;
    line-height: 1;
    margin-top: 0px;
  }
  .user-name {
    font-weight: 500;
  }
  .row-two {
    align-items: center;
    display: flex;
    justify-content: space-between;
    .mobile-date {
      font-weight: 400;
    }
    svg {
      max-width: 17px;
    }
  }
  @media (min-width: 900px) {
    display: none;
  }
`;

export const RowStyles = styled.div<{
  fileExists: boolean;
  isChecked: boolean;
}>`
  align-items: center;
  background-color: #fff;
  color: #000;
  /* border-radius: ${(props) => (props.fileExists ? "4px" : "0 4px 4px 0")}; */
  border: ${(props) => (props.isChecked ? "1px solid blue" : "1px solid #fff")};
  display: none;
  font-size: 12px;
  grid-column-gap: 16px;
  grid-template-columns: ${rowGrid};
  min-height: 38px;
  padding: 0 8px;
  position: relative;
  transition: all 0.2s;
  :first-of-type {
    margin-top: 0px;
  }
  :nth-of-type(odd) {
    background-color: #f5f6f8;
    border: ${(props) => (props.isChecked ? "1px solid blue" : "1px solid #f5f6f8")};
  }
  :last-of-type {
    .tooltip {
      top: unset;
      bottom: 24px;
    }
  }
  .doc-title {
    transition: all 0.2s;
    :hover {
      color: #00b8d4;
    }
  }
  :hover {
    /* ::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border: 1px solid #aaa;
      top: 0;
      left: 0;
    } */
    /* background-color: rgba(235, 236, 241, 0.2);
    :nth-child(odd) {
      background-color: rgba(235, 236, 241, 0.2);
    } */
    border: 1px solid #1f2b5e;
    border-radius: 4px;
  }
  input {
    cursor: pointer;
  }
  .actions_wrap {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    > * {
      margin-left: 5%;
    }
    svg {
      cursor: pointer;
      max-width: 16px;
      width: 100%;
      path {
        transition: all 0.15s;
      }
      :hover {
        path {
          stroke: #1f2b5e;
        }
      }
    }
    .fedex_wrap_loading,
    .fedex_wrap {
      /* width: 42px; */
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      z-index: 5;
      ::before {
        position: absolute;
        width: calc(100% + 8px);
        height: calc(100% + 8px);
        background-color: #ddd;
        top: -4px;
        left: -4px;
        border-bottom: 1px solid #aaa;
      }
      :hover {
        background-color: #f2f2f2;
        ::before {
          content: "";
        }
        .fedex-tooltip {
          display: block;
        }
      }
      .fedex-tooltip {
        position: absolute;
        display: none;
        top: -10px;
        left: -110px;
        background-color: #fff;
        border: 1px solid #aaa;
        border-radius: 4px;
        padding: 8px;
        font-size: 12px;
        font-weight: 500;
        color: #000;
        z-index: 10;
        text-transform: capitalize;
        white-space: nowrap;
      }
      svg {
        width: 100%;
        max-width: 34px;
        height: auto;
        position: relative;
        z-index: 10;
        :hover {
          path {
            stroke: initial;
          }
        }
      }
      div {
        position: relative;
        z-index: 10;
        line-height: 10px;
        font-weight: 500;
        text-align: left;
        text-transform: uppercase;
      }
    }
    .fedex_wrap_loading {
      align-items: center;
      .loader_dots {
        margin-left: 4px;
        width: auto;
        display: block;
        height: 20px;
      }
    }
  }
  @media (min-width: 900px) {
    display: grid;
  }
  @media (min-width: 1024px) {
    padding: 0 16px;
  }
  @media (min-width: 1440px) {
    grid-template-columns: ${rowGridDesktop};
    .actions_wrap {
      > * {
        margin-left: 20px;
      }
    }
  }
`;
