import React from "react";
import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/react";

type LaytoutProps = {
  vertical?: boolean;
  hideSide: boolean;
};

const boneStructure = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  flexDirection: "column",
  pointerEvents: "none"
};

const pulse = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
`;

export const Bones = styled.div({
  backgroundColor: "#f7f7f9",
  borderRadius: 8,
  height: 16,
  width: "100%",
  marginBottom: 10,
  background: "linear-gradient(-90deg, #F0F0F0 0%, #F8F8F8 50%, #F0F0F0 100%)",
  backgroundSize: "400% 400%",
  animation: `${pulse} 2s infinite linear`,
  ":last-of-type": { marginBottom: 0, width: "75%" }
});

export const Root = styled.div`
  background-color: #f8f8fc;
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
  font-size: 14px;
  overflow: inherit;
  line-height: 1.4em;
  font-weight: 400;
  .with-overlay-override {
    height: calc(100vh - 47px);
    top: 47px;
  }
  * {
    box-sizing: inherit;
    font-family: inherit;
  }
  @media (min-width: 900px) {
    .with-overlay-override {
      width: calc(100vw - 72px);
      left: 72px;
      top: 0;
      height: 100vh;
    }
  }
`;

export const MainContentStyles = styled.div<LaytoutProps>`
  margin-top: 47px;
  background-color: #f8f8fc;
  .hide-button {
    align-items: center;
    background-color: #c2c5d3;
    border-radius: 0 24px 24px 0;
    color: #00b8d4;
    cursor: pointer;
    display: none;
    height: 48px;
    justify-content: center;
    position: absolute;
    top: 48px;
    transition: all 0.1s;
    width: 24px;
    z-index: 210;
    svg {
      max-width: 16px;
    }
  }
  @media (min-width: 900px) {
    margin-top: 0;
    display: flex;
    margin-left: 72px;
    min-height: 100vh;
    .info-sidebar_wrap {
      width: 300px;
      transition: all 0.2s;
      position: fixed;
      height: 100vh;
      left: ${(props) => (props.hideSide ? "72px" : "-310px")};
      background-color: rgba(0, 0, 0, 0.5);
      padding: ${(props) => props.hideSide && " 0 8px"};
      z-index: ${(props) => (props.hideSide ? "60" : "0")};
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .hide-button {
      transform: rotate(${(props) => (props.hideSide ? "180" : "0")}deg);
      display: flex;
      left: ${(props) => (props.hideSide ? "56px" : "72px")};
    }
  }
  @media (min-width: 1240px) {
    .hide-button {
      transform: rotate(${(props) => (props.hideSide ? "0" : "180")}deg);
      left: ${(props) => (props.hideSide ? "72px" : "59px")};
    }
    .info-sidebar_wrap {
      background-color: transparent;
      left: ${(props) => (props.hideSide ? "-310px" : "82px")};
      /* position: unset; */
      /* transition: width 0.2s; */
      /* padding: ${(props) => (props.hideSide ? "0px" : "0 16px")}; */
      /* width: ${(props) => (props.hideSide ? "0px" : "300px")}; */
      /* overflow: ${(props) => (props.hideSide ? "hidden" : "auto")}; */
    }
  }
`;

export const OrderNotFound = styled.h1`
  left: 0;
  line-height: 200px;
  margin-top: -100px;
  position: absolute;
  text-align: center;
  top: 40%;
  font-size: 40px;
  width: 100%;
`;

export const AppLoadingSpinner = () => {
  const style: any = {
    position: "fixed",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: 9999,
    background: "url('/images/loading-gif2.gif') 50% 50% no-repeat"
  };
  return <div style={style}></div>;
};
