import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "./ui/modal";
import styled from "@emotion/styled";

const Pre = styled.pre`
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}`;

const ErrorWrapper = styled.div`
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 16px;
`;

const GlobalError = () => {
  const [visible, setVisible] = useState(false);
  const [globalErrorMessage, setGlobalErrorMessage] = useState(<></>);

  const buildErrorBody = (error: any) => {
    if (!error || !error.response || !error.response.data) {
      window.location.reload();
    }

    return (
      <>
        <p>
          A server side error occurred. Please try again. If this problem persists, please contact the developers by
          emailing <a href="mailto:afriedman@madisoncres.com">afriedman@madisoncres.com</a>. Please include the
          information below:
        </p>
        <ErrorWrapper>
          <Pre style={{ height: 400, overflow: "scroll" }}>{JSON.stringify(error.response.data, null, 2)}</Pre>
        </ErrorWrapper>
      </>
    );
  };

  useEffect(() => {
    axios.interceptors.response.use(null, function (error) {
      setVisible(true);
      setGlobalErrorMessage(buildErrorBody(error));
      throw error;
    });
  }, []);

  return (
    <>
      {visible && (
        <Modal closeClick={() => setVisible(false)} confirmText="Refresh" confirmClick={() => window.location.reload()}>
          {globalErrorMessage && (
            <div>
              <div className="bold">An error occured. Please click the refresh button to continue.</div>
              <div className="mt2">{globalErrorMessage}</div>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default GlobalError;
