import { Alert, Box, Button, Snackbar, Stack, Typography } from "@mui/material";
import React from "react";
import { BlackListResult, NameSearchResult } from "./types";

export default function BlackListSearchAlert({
  showBlackListSearchResultAlert,
  setShowBlackListSearchResultAlert,
  blackListSearchResult
}: {
  showBlackListSearchResultAlert: boolean;
  setShowBlackListSearchResultAlert: React.Dispatch<React.SetStateAction<boolean>>;
  blackListSearchResult: BlackListResult[];
}) {
  const getNames = () => {
    if (!blackListSearchResult) {
      return "";
    }
    return blackListSearchResult.map((bl, index) => `${index + 1}) ${bl.information}`).join(", ");
  };
  return (
    <Alert severity="error">
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography variant="body2">
          DO NOT PROCEED! Blacklists matches found: {getNames()}.{" "}
          <span style={{ fontWeight: "bold", color: "red" }}>DO NOT CONTINUE </span> transaction until confirmed in
          writing with your manager!
        </Typography>
        <Box>
          <Button
            color="error"
            variant="contained"
            size="small"
            onClick={() => setShowBlackListSearchResultAlert(false)}
            sx={{ textTransform: "unset", width: 100 }}
          >
            I understand
          </Button>
        </Box>
      </Stack>
    </Alert>
  );
}
