import { IGovernmentContact, Contact } from "../Providers/types";

export const transformGovernmentContactToContact = (govContact: IGovernmentContact): Contact => {
  return {
    id: null,
    branchId: null,
    address1: govContact.addressLine1,
    address2: govContact.addressLine2,
    city: govContact.city,
    state: govContact.state,
    zip: govContact.zip,
    cityStateZip: `${govContact.city}, ${govContact.state} ${govContact.zip}`,
    code: "", // Not available in IGovernmentContact
    email: "", // Not available in IGovernmentContact
    emailsSplit: [], // Not available in IGovernmentContact
    fax: govContact.fax,
    isGroup: false, // Default value
    isMarketingSource: false, // Default value
    lookupCode: govContact.lookupCode,
    name: govContact.name,
    notes: "", // Not available in IGovernmentContact
    payeeName: govContact.payeeName,
    phone: govContact.phone,
    cell: "", // Not available in IGovernmentContact
    referenceNumber: "", // Not available in IGovernmentContact
    represents: "", // Not available in IGovernmentContact
    type: "Government", // Default type, since it's a government contact
    url: "", // Not available in IGovernmentContact
    role: "", // Not available in IGovernmentContact
    company: "", // Not available in IGovernmentContact
    salesRep: "", // Not available in IGovernmentContact
    otherContactType: "", // Not available in IGovernmentContact
    contactType: "Government", // Default type, since it's a government contact
    emailPolicyTo: ""
  };
};
