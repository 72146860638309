import styled from "@emotion/styled";

export const Root = styled.div`
  display: flex;
  display: block;
`;

export const ToggleButtonWrap = styled.div`
  display: inline-flex;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(145, 158, 171, 0.16);
  padding: 4px;
  gap: 4px;
  div {
    display: inline-flex;
    align-items: center;
    height: 38px;
    justify-content: center;
    padding: 0px 8px;
    font-size: 0.8125rem;
    font-weight: 700;
    color: rgb(99, 115, 129);
    border-radius: 8px;
    cursor: pointer;
    :hover:not(.active) {
      background-color: rgba(24, 23, 168, 0.08);
    }
  }
  .active {
    color: rgb(24, 23, 168);
    background-color: rgba(24, 23, 168, 0.08);
    cursor: default;
  }
`;

export const JointInfoWrap = styled.div`
  margin: 20px auto 0;
  margin-left: 24px;
  .sideBySide {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    > div {
      padding: 20px;
      border-radius: 12px;
      background-color: rgb(244, 246, 248);
      > * {
        margin-top: 18px;
        :first-of-type {
          margin-top: 0;
        }
      }
    }
  }
`;
