export default function validateEmail(email: string) {
  const emails = email.split(";").map((e) => e.trim());

  for (const email of emails) {
    if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email.toLowerCase()
      )
    ) {
      return false; // At least one email is invalid
    }
  }

  return true; // All emails are valid
}
