import styled from "@emotion/styled";

export const ButtonGroup = styled.div<{ allChecked?: any }>`
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 4px;
  display: flex;
  font-size: 12px;
  overflow: hidden;
  > .file-action-button,
  > .buttonGroup-button {
    align-items: center;
    color: #555;
    display: flex;
    justify-content: center;
    padding: 4px 8px;
    transition: all 0.1s;
    white-space: nowrap;
    cursor: pointer;
    :hover {
      background-color: #eee;
      color: #333;
    }
  }
  .active-button {
    background-color: ${(props) => props.allChecked && "#1f2b5e"};
    color: ${(props) => props.allChecked && "#fff"};
  }
  @media (min-width: 1440px) {
    font-size: 13px;
  }
`;
