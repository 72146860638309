import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1f2b5e"
    },
    secondary: {
      main: "#4440DB"
    },
    text: {
      primary: "#1f2b5e"
    }
  },
  status: {
    danger: "orange"
  },
  //@ts-ignore
  typography: {
    fontWeight: 900,
    fontSize: 14,
    fontFamily: [
      "Inter",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      "-apple-system",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(",")
  },
  overrides: {
    MuiInputBase: {
      input: {
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
          transitionProperty: "background-color, color"
        }
      }
    }
  }
});

export default theme;
