import React from "react";
import styled from "@emotion/styled";

export const Root = styled.div<{ iframeDisplay?: boolean }>`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  padding: 40px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 49944;
  .MuiOutlinedInput-root {
    border-radius: 8px;
  }
  .tile {
    background-color: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    max-height: calc(100vh - 48px);
    max-width: 720px;
    overflow: auto;
    padding: 32px;
    position: absolute;
    width: 100%;
    right: ${(props) => props.iframeDisplay && "756px"};
    transition: all 0.3s ease;
  }
  .tile_header_wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    .tile_header {
      font-size: 28px;
      font-weight: 600;
      line-height: 1.2;
    }
  }
  .no-contact-yet {
    width: 100%;
    margin-top: 24px;
    display: flex;
    gap: 40px;
  }
`;

export const FormWrapStyles = styled.div`
  max-width: calc(100% - 280px);
  padding: 8px 0px 16px 0px;
  border-radius: 16px;
  padding-top: 0;
  width: 100%;
`;

export const ComingSoonStyles = styled.div<{ show?: Boolean; loading?: Boolean }>`
  bottom: 0px;
  overflow: ${(props) => (props.show ? "visible" : "hidden")};
  position: absolute;
  transition: all 0.1s ease-in-out;
  width: 100%;
  left: 32px;
  .comingSoonInner {
    background-color: #f2f2f2;
    border-radius: 10px;
    transition: all 0.3s ease;
    bottom: ${(props) => (props.show ? "24px" : "-320px")};
    display: flex;
    left: -2px;
    flex-direction: column;
    font-size: 15px;
    justify-content: space-between;
    line-height: 1.5;
    padding: 24px;
    position: absolute;
    width: calc(100% - 60px);
    z-index: 550;
  }
  .coming-soon {
    color: #141368;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 24px;
  }
  .ok_button {
    background-color: #141368;
  }
  .warning {
    color: #f80202;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 24px;
  }
`;

export const IframeWrap = styled.div<{ isNewContact?: boolean }>`
  /* right: ${(props) => !props.isNewContact && "0px"}; */
  align-items: center;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  height: 100vh;
  justify-content: center;
  padding: 24px;
  position: fixed;
  right: 0;
  top: 0;
  width: ${(props) => (props.isNewContact ? "100vw" : "700px")};
  z-index: 999999;
  .iframeInner {
    align-items: center;
    background-color: #fff;
    background-color: transparent;
    display: flex;
    gap: 40px;
    height: 100%;
  }
  .conttcardOverride {
    max-width: 300px;
    .edit-icon {
      display: none;
    }
    .tac {
      > div {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
`;

export const SuccessWrap = styled.div`
  align-items: center;
  background-color: #e9fcd4;
  border-radius: 8px;
  color: #08660d;
  display: flex;
  font-weight: 600;
  gap: 14px;
  justify-content: space-between;
  min-height: 50px;
  padding: 0 16px;
  button {
    background-color: #54d62c;
    color: #fff;
    border-radius: 4px;
    padding: 0px 12px;
    height: 32px;
    cursor: pointer;
    letter-spacing: 0.6px;
    :hover {
      background-color: #50d31c;
    }
  }
`;

export const ContactOptionWrap = styled.li`
  border-top: 1px solid #ccc;
  flex-direction: column;
  font-size: 13px;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  :first-of-type {
    border-top: none;
  }
  .top_row {
    align-items: flex-start;
    display: flex;
    width: 100%;
    align-items: center;
    .bold {
      font-size: 15px;
      margin-right: 12px;
    }
    .gray {
      color: #999;
    }
  }
  .bottom_row {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    width: 100%;
    div {
      line-height: 1.6;
    }
  }
`;

export const AdditionalEmailsWrap = styled.div`
  background-color: rgb(248, 249, 250);
  border-radius: 12px;
  margin-top: 36px;
  padding: 20px;
  .MuiOutlinedInput-notchedOutline {
    border-color: #000;
  }
  .sdfdsfsd {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 28px;
    span {
      display: inline-block;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.5;
      position: relative;
    }
  }
  .current_emails {
    margin-top: 24px;
    max-width: 310px;
  }
  .add-button {
    align-items: center;
    color: #4440db;
    cursor: pointer;
    display: flex;
    gap: 4px;
    margin-top: 24px;
    svg {
      color: #4440db;
      cursor: pointer;
      height: 20px;
      width: 20px;
    }
    :hover {
      color: #141368;
      svg {
        color: #141368;
      }
    }
  }
  .new_emails {
    .delete_icon {
      height: 20px;
      width: 20px;
    }
    .input_wrap {
      align-items: center;
      display: flex;
      gap: 8px;
      margin-top: 26px;
      max-width: 355px;
      width: 100%;
      :first-of-type {
        margin-top: 14px;
        .df.aic {
          display: none;
        }
      }
    }
  }
`;

export function CheckCircle() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 4.47715 4.47715 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM9.73 13.61L14.3 7.61V7.58C14.5179 7.29419 14.5668 6.91382 14.4283 6.58218C14.2897 6.25054 13.9848 6.01801 13.6283 5.97218C13.2718 5.92635 12.9179 6.07419 12.7 6.36L8.92 11.36L7.29 9.28C7.07028 8.99776 6.71668 8.85418 6.36239 8.90334C6.00811 8.9525 5.70696 9.18694 5.57239 9.51834C5.43783 9.84974 5.49028 10.2278 5.71 10.51L8.15 13.62C8.34082 13.8615 8.63222 14.0017 8.94 14C9.2495 13.9993 9.54121 13.8552 9.73 13.61Z"
        fill="#54D62C"
      />
    </svg>
  );
}
