import * as React from "react";
import { QueryParamProvider } from "use-query-params";
import Home from "../components/home";

const HomePage = () => {
  return (
    <QueryParamProvider>
      <Home />
    </QueryParamProvider>
  );
};

export default HomePage;
