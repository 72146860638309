import React, { useRef, useEffect, useState } from "react";

import { DocumentSubcategory } from "./types";
import { useDocuments } from "../../Providers/DocumentsProvider";
import { SubcategorySectionStyles } from "./documentsStyles";
import DocumentRow from "./DocumentRow";

const SubcategorySection = ({
  documentSubcategory,
  categoryName
}: {
  documentSubcategory: DocumentSubcategory;
  categoryName: string;
}) => {
  const { handleSubCategoryCheckbox, addRefToCategory, selectedDocuments } = useDocuments();
  const ref = useRef<HTMLDivElement>(null);
  const [clickedLinks, setClickedLinks] = useState([]);

  const onLinkClick = (id: number) => {
    setClickedLinks([...clickedLinks, id]);
  };
  useEffect(() => {
    addRefToCategory(documentSubcategory.scrollToName, ref);
  }, []);
  return (
    <SubcategorySectionStyles>
      <div ref={ref} className="subcat_wrap">
        <input
          type="checkbox"
          checked={documentSubcategory.documents.every((x) => selectedDocuments.includes(x))}
          onChange={(e) => handleSubCategoryCheckbox(e, documentSubcategory.id)}
          className="subcat_checkbox"
        />
        {/* <Checkbox /> */}
        <div className="subcatheader df aifs">
          <span className="mr1">{documentSubcategory.name}</span>
          <span style={{ fontSize: 12, color: "#333", fontWeight: 300, marginBottom: 3 }}>
            {documentSubcategory.documents.length}{" "}
            {documentSubcategory.documents.length === 1 ? "Document" : "Documents"}
          </span>
        </div>
        <div>Pg.</div>
        <div>Date</div>
        <div>User</div>
        <div className="actions-column-header">Actions</div>
      </div>
      {documentSubcategory.documents.map((doc) => (
        <DocumentRow
          document={doc}
          key={doc.id}
          hasBeenClicked={!!clickedLinks.find((d) => doc.id === d)}
          onLinkClick={() => onLinkClick(doc.id)}
        />
      ))}
    </SubcategorySectionStyles>
  );
};

export default SubcategorySection;
