/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import axios from "axios";
import { format } from "date-fns";
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import GetAppIcon from "@material-ui/icons/GetApp";
import styled from "@emotion/styled";

import { copyTextToClipboard } from "../../helpers/clipboard";
import useOnClickOutside from "../../hooks/use-on-outside-click";
import { useDocuments } from "../../Providers/DocumentsProvider";
import { useOrder } from "../../Providers/OrderProvider";
import { CreatePublicLink, Delete, DownloadFile, MissingDocument, Public, FedexLogo } from "../../ui/icons";
import EditModal from "./document-actions/EditModal";
import NotesModal from "./document-actions/NotesModal";
import { MobileRowStyles, RowStyles } from "./documentRowStyles";
import { Toast } from "./documentsStyles";
import { Document } from "./types";

import loaderDots from "../new-home/images/Dotloader.svg";
import { logNPIDocumentView } from "../../helpers/analyticsLogger";

const DocumentRow = ({
  document,
  hasBeenClicked,
  onLinkClick
}: {
  document: Document;
  hasBeenClicked: boolean;
  onLinkClick: () => void;
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [publicClickModalOpen, setPublicClickModalOpen] = useState(false);
  const { handleDocumentCheckbox, selectedDocuments, getDocuments } = useDocuments();
  const { order } = useOrder();
  const { orderNumber } = useParams();
  const deleteRef = useRef(null);
  const publicLinkRef = useRef(null);

  async function handleDeleteClick() {
    await axios.post(`/api/documents/delete/${document.id}`);
    await getDocuments();
    setDeleteModalOpen(false);
  }

  function handleLinkClick() {
    onLinkClick();
    logNPIDocumentView(document.id);
    axios.post("/api/Documents/LogNPI/", {
      documentId: document.id,
      orderNumber
    });
  }

  const shouldDisplayCreatePublicLink = () => {
    if (!order || !document) {
      return false;
    }
    if (!document.subcategoryId) {
      return false;
    }
    const subcategoryId = document.subcategoryId.trim();
    if (
      subcategoryId === "CMTCMT" ||
      subcategoryId === "CMTATT" ||
      subcategoryId == "SCHMUN" ||
      subcategoryId == "SCHSTA"
    ) {
      return true;
    }
    if (subcategoryId === "SCHMUN" || subcategoryId === "SCHSTA") {
      if (!order.stateCode) {
        return true;
      }
      const orderState = order.stateCode.trim();
      return orderState !== "NY";
    }

    return false;
  };

  useOnClickOutside(deleteRef, () => setDeleteModalOpen(false));
  useOnClickOutside(publicLinkRef, () => setPublicClickModalOpen(false));
  const onCreatePublicLinkClick = async () => {
    const { data } = await axios.get(`/api/documents/generatepubliclink?documentId=${document.id}`);
    copyTextToClipboard(data.url);
    setPublicClickModalOpen(true);
  };

  const handleUndoCreatePublicLink = async () => {
    await axios.post("/api/documents/removepubliclink", {
      documentId: document.id
    });
    setPublicClickModalOpen(false);
  };

  const handlePublicClick = async () => {
    await axios.post(`/api/documents/updatewebvisible/`, {
      documentId: document.id,
      visible: !document.web
    });
    await getDocuments();
  };

  const emailUser = async (username) => {
    const { data } = await axios.get(`/api/documents/getuseremail/${username}`);
    window.open(
      `mailto:${data}?subject=${order?.emailSubjectLine}&body=Regarding ` +
        encodeURIComponent(`https://internal.mcres.com/opendoc?id=${document.id}`)
    );
  };

  const isChecked = selectedDocuments.includes(document);
  const getinnerwidth = window.innerWidth;

  function spippingSwitch(shippingStatus) {
    switch (shippingStatus) {
      case "Accepted":
        return <div style={{ color: "blue" }}>Printed</div>;
      case "In Transit":
        return <div style={{ color: "#4D148C" }}>In Transit</div>;
      case "Delivered":
        return <div style={{ color: "#008A00" }}>Delivered</div>;
      default:
        return <div style={{ color: "#CD4765" }}>{shippingStatus}</div>;
    }
  }

  return (
    <React.Fragment>
      {getinnerwidth > 768 ? (
        <RowStyles
          isChecked={isChecked}
          fileExists={document.fileExists}
          css={
            !document.fileExists && {
              position: "relative",
              "&:before": {
                content: '""',
                position: "absolute",
                left: 0,
                height: "100%",
                borderLeft: "2px solid red"
              }
            }
          }
        >
          <div className="df aic jcsb">
            {document.fileExists ? (
              <input
                type="checkbox"
                checked={selectedDocuments.includes(document)}
                onChange={(e) => handleDocumentCheckbox(e.target.checked, document)}
              />
            ) : (
              <MissingDocument />
            )}
          </div>
          <div>
            <a
              onClick={handleLinkClick}
              target="_blank"
              rel="noopener noreferrer"
              className="doc-title"
              style={{ color: hasBeenClicked ? "#ED7257" : "" }}
              href={`/opendoc?id=${document.id}`}
            >
              {document.documentDescription}
            </a>
          </div>
          <div>{document.numPages}</div>
          <div>{format(new Date(document.date1), "MM/dd/yyyy h:mm a")}</div>
          <div>
            <Tooltip title="Email User" left>
              <a onClick={() => emailUser(document.userName)} style={{ cursor: "pointer" }}>
                {document.userName}
              </a>
            </Tooltip>
          </div>
          <div className="actions_wrap">
            <div>{shouldDisplayCreatePublicLink() && <CreatePublicLink onClick={onCreatePublicLinkClick} />}</div>
            {document.trackingNumber && !document.shippingStatus && (
              // Retrieving shipment status
              <div className="fedex_wrap_loading">
                <img src={loaderDots} className="loader_dots" alt="" />
              </div>
            )}
            {document.shippingStatus && (
              <a
                href={`https://www.fedex.com/fedextrack/?trknbr=${document.trackingNumber}`}
                target="_blank"
                className="fedex_wrap"
                rel="noopener noreferrer"
              >
                {document.shippingStatus && spippingSwitch(document.shippingStatus)}
                <div className="fedex-tooltip">
                  View in <FedexLogo />
                </div>
              </a>
            )}
            <Tooltip title="Download Document">
              <DownloadFile onClick={() => (window.location.href = `/opendoc/download?id=${document.id}`)} />
            </Tooltip>
            <Tooltip title="Add Note">
              <NotesModal document={document} />
            </Tooltip>
            <Tooltip title="Edit Document">
              <EditModal document={document} />
            </Tooltip>
            <Tooltip title="Delete Document">
              <Delete onClick={() => setDeleteModalOpen(true)} />
            </Tooltip>
            <Tooltip title="Make Document Public" right>
              <Public ispublic={document.web} onClick={handlePublicClick} />
            </Tooltip>
          </div>
          <Toast show={deleteModalOpen} ref={deleteRef}>
            <div>Do you want to delete {document.documentDescription}?</div>
            <div className="button-wrap">
              <button onClick={() => setDeleteModalOpen(false)}>Close</button>
              <button onClick={handleDeleteClick}>Delete</button>
            </div>
          </Toast>
          <Toast show={publicClickModalOpen} ref={publicLinkRef}>
            <div>Public link created and copied to your clipboard.</div>
            <div className="button-wrap">
              <button onClick={handleUndoCreatePublicLink}>Undo</button>
              <button onClick={() => setPublicClickModalOpen(false)}>Close</button>
            </div>
          </Toast>
        </RowStyles>
      ) : (
        <MobileRowStyles>
          <div className="row-one">
            <div className="check-desc">
              {document.fileExists ? (
                // <CheckBox />
                <input
                  type="checkbox"
                  checked={selectedDocuments.includes(document)}
                  onChange={(e) => handleDocumentCheckbox(e.target.checked, document)}
                />
              ) : (
                <MissingDocument />
              )}
              <a
                onClick={handleLinkClick}
                target="_blank"
                rel="noopener noreferrer"
                className="doc-title"
                style={{ color: hasBeenClicked ? "#ED7257" : "" }}
                href={`/opendoc?id=${document.id}`}
              >
                {document.documentDescription}
              </a>
            </div>
            <div className="df aic">
              <div className="df aic">
                {shouldDisplayCreatePublicLink() && (
                  <CreatePublicLink style={{ width: 16, height: "auto" }} onClick={onCreatePublicLinkClick} />
                )}
                <div className="ml1half mr1half">
                  <DownloadFile
                    style={{ width: 16, display: "block", height: "auto" }}
                    onClick={() => (window.location.href = `/opendoc/download?id=${document.id}`)}
                  />
                </div>
              </div>
              <div>{document.numPages} pg.</div>
              {/* <input
                style={{ marginLeft: '8px' }}
                type='checkbox'
                checked={selectedDocuments.includes(document)}
                onChange={(e) => handleDocumentCheckbox(e.target.checked, document)}
              /> */}
            </div>
          </div>
          <div className="row-two">
            <div className="mobile-date">{format(new Date(document.date1), "MM/dd/yyyy h:mm a")}</div>
            <div className="user-name">{document.userName}</div>
          </div>
          {publicClickModalOpen && (
            <Toast show={publicClickModalOpen} ref={publicLinkRef}>
              <div>Public link created and copied to your clipboard.</div>
              <div className="button-wrap">
                <button onClick={handleUndoCreatePublicLink}>Undo</button>
                <button onClick={() => setPublicClickModalOpen(false)}>Close</button>
              </div>
            </Toast>
          )}
        </MobileRowStyles>
      )}
    </React.Fragment>
  );
};

export default DocumentRow;

function Tooltip({
  title,
  children,
  left,
  right
}: {
  title: string;
  children: React.ReactNode;
  left?: boolean;
  right?: boolean;
}) {
  return (
    <TooltipStyles left={left} right={right}>
      {children}
      <div className="tooltip right">{title}</div>
    </TooltipStyles>
  );
}

const TooltipStyles = styled.div<{ left?: boolean; right: boolean }>`
  position: relative;
  .tooltip {
    position: absolute;
    top: 100%;
    left: ${(props) => (props.left ? "0px" : "50%")};
    right: ${(props) => props.right && "-4px"};
    transform: ${(props) => !props.right && !props.left && "translateX(-50%)"};
    background-color: #333;
    color: #fff;
    padding: 4px 8px;
    border-radius: 5px;
    z-index: 1;
    display: none;
    white-space: nowrap;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
  }
  .right {
    left: ${(props) => props.right && "unset"};
  }
  :hover .tooltip {
    display: block;
  }
`;
