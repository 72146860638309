import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Global } from "@emotion/react";

import { moveItem, sort } from "../../../helpers/draggableList";
import { convertToFileDownload } from "../../../helpers/fileDownload";
import { useDocuments } from "../../../Providers/DocumentsProvider";
import DraggableList from "../../../ui/DraggableList";
import ActionSideDraw from "../../../ui/ActionSideDraw";
import { ButtonsWrap, BaseButton, GhostButton } from "../../../ui/modalButtons";
import { Document } from "../types";
import { InnerModal } from "../../../ui/modalStyles";
import { XIcon } from "../../../ui/icons";
import { ModalType } from "../../../Providers/types";
import Spinner from "../../../ui/Spinner";
import { Box, TextField, Typography } from "@mui/material";
import { useOrder } from "../../../Providers/OrderProvider";

export default function MergePrint() {
  const { uncheckAllDocuments, selectedDocuments, toggleModal, closeModal, currentOpenModal, handleDocumentCheckbox } =
    useDocuments();
  const { order, exchangeData } = useOrder();
  const [modalDocuments, setModalDocuments] = useState<Document[]>([]);
  const action = "Merge & Print";
  const [mergedDocumentName, setMergedDocumentName] = useState<string>("");

  const [isMergePrintLoading, setIsMergePrintLoading] = useState(false);

  useEffect(() => {
    setModalDocuments(selectedDocuments);
    if (selectedDocuments.length > 0) {
      const initialOrderNumber = `${order.titleNumber} Document`;
      setMergedDocumentName(initialOrderNumber);
    } else {
      setMergedDocumentName("");
    }
  }, [selectedDocuments]);

  const handleMoveItem = (dragIndex, hoverIndex) => {
    const newList = moveItem(modalDocuments, dragIndex, hoverIndex);
    setModalDocuments(newList);
  };

  const handleSort = (sortASC = true) => {
    const newList = sort(modalDocuments, sortASC);
    setModalDocuments(newList);
  };

  const handleMerge = async () => {
    setIsMergePrintLoading(true);
    const payload = modalDocuments.map((d) => ({ sourceId: d.id }));

    const fileName = `${mergedDocumentName}.pdf`;

    const { data } = await axios.post("/api/documents/merge", {
      documents: payload
    });

    convertToFileDownload(data.mergedFile, fileName);
    setIsMergePrintLoading(false);
    uncheckAllDocuments();
    closeModal();
    setMergedDocumentName("");
  };

  return (
    <>
      <div onClick={() => toggleModal(ModalType.MergePrint)} className="file-action-button">
        {action}
      </div>
      {currentOpenModal === ModalType.MergePrint && (
        <>
          <Global
            styles={{
              body: { height: "100vh", overflow: "hidden" },
              ".mobile-order-header_or": { zIndex: "0 !important" }
            }}
          />
          <ActionSideDraw close={closeModal}>
            <InnerModalPrint style={{ padding: "0 10px" }}>
              <div className="modal-header">
                <div className="modal-title">{action}</div>
                <div className="close-icon" onClick={closeModal}>
                  <XIcon />
                </div>
              </div>
              <DraggableList
                items={modalDocuments}
                moveItem={handleMoveItem}
                handleSort={(sortASC) => handleSort(sortASC)}
                handleDelete={(item) => handleDocumentCheckbox(false, item)}
                handleDeleteAll={uncheckAllDocuments}
              />
            </InnerModalPrint>
            <Box width="100%" textAlign="right" paddingRight={2}>
              <TextField
                placeholder="Merged Name"
                label="Merged Name"
                value={mergedDocumentName}
                onChange={(e) => setMergedDocumentName(e.target.value.replace(".pdf", ""))}
                sx={{
                  maxWidth: 500,
                  width: "calc(100% - 16px)",
                  marginBottom: "16px"
                }}
              />
            </Box>
            <ButtonsWrap>
              <GhostButton onClick={closeModal}>Cancel</GhostButton>
              {/* <Box
                sx={{
                  width: "100%",
                  marginBottom: 2,
                }}
              >
              
              </Box> */}
              <BaseButton
                onClick={handleMerge}
                isLoading={isMergePrintLoading}
                isDisabled={modalDocuments.length === 0}
              >
                <Spinner local={true} display={isMergePrintLoading} />
                {action}
              </BaseButton>
            </ButtonsWrap>
          </ActionSideDraw>
        </>
      )}
    </>
  );
}

const InnerModalPrint = styled(InnerModal)`
  @media (max-width: 768px) {
    height: calc(100vh - 160px);
    max-width: 100%;
    .selected-header {
      padding: 8px;
    }
    .modal-header {
      height: 48px;
      .modal-title {
        font-size: 18px;
      }
      .close-icon {
        svg {
          max-width: 14px;
          g {
            stroke-width: 2;
          }
        }
      }
    }
    .sorter-sort {
      padding-left: 0;
      padding-right: 0;
    }
    .list-header {
      font-size: 14px;
    }
  }
  .list-wrap_or {
    max-height: calc(100vh - 320px);
  }
`;
