import styled from "@emotion/styled";
import { XYCoord } from "dnd-core";
import React, { FC, useRef } from "react";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { SelectIllus, SortIcon, XIcon } from "./icons";
import { useState } from "react";
interface DragItem {
  index: number;
  id: string;
  type: string;
}

export interface DraggableItemProps {
  id: any;
  text: any;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  deleteItem: (item: any) => void;
}

interface Props {
  items: Array<any>;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  handleSort: (sortASC) => void;
  doMerge?: boolean;
  handleMerge?: () => void;
  center?: boolean;
  displayMerge?: boolean;
  handleDelete: (item: any) => void;
  handleDeleteAll: () => void;
}

const DraggableItem: FC<DraggableItemProps> = ({ id, text, index, moveItem, deleteItem }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: "Document",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: "Document",
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div ref={ref} style={{ opacity }} className="draggable-item">
      <span>{text}</span>
      <div className="delete-file-icon" onClick={deleteItem}>
        <XIcon />
      </div>
    </div>
  );
};
export default function DraggableList({
  items,
  moveItem,
  handleSort,
  doMerge,
  handleMerge,
  displayMerge,
  handleDelete,
  handleDeleteAll
}: Props) {
  // const rowText = (
  //   <div>
  //     ${index + 1}. ${item.documentDescription}
  //   </div>
  // );

  const [isSortAsc, setIsSortAsc] = useState(false);

  function RowText({ index, documentDescription }) {
    return (
      <div className="row_text">
        <span>{index + 1}.</span> <span>{documentDescription}</span>
      </div>
    );
  }

  return (
    <Wrap>
      {items.length > 0 ? (
        <>
          <SelectedHeaderWrap className="selected-header">
            <div className="list-header">
              Selected Documents
              <div className="counter">{items.length}</div>
            </div>
            <div className="uncheck_all" onClick={handleDeleteAll}>
              Clear Selected
            </div>
          </SelectedHeaderWrap>
          <Actions>
            <div>
              <div
                className="sorter-sort"
                onClick={() => {
                  handleSort(isSortAsc);
                  setIsSortAsc(!isSortAsc);
                }}
              >
                {isSortAsc === true ? <div>Sort Old to New </div> : <div> Sort New to Old</div>}
                <SortIcon />
              </div>
            </div>
            <div className="click-drag">Click & Drag to Reorder</div>
          </Actions>
          <ListWrap className="list-wrap_or">
            <div className="list-items_wrap">
              {items.map((item, index) => {
                return (
                  <DraggableItem
                    key={item.id}
                    index={index}
                    id={item.id}
                    moveItem={moveItem}
                    text={<RowText index={index} documentDescription={item.documentDescription} />}
                    deleteItem={() => handleDelete(item)}
                  />
                );
              })}
            </div>
          </ListWrap>
        </>
      ) : (
        <NoSlecected className="please-select">
          <div className="illus-wrap">
            <SelectIllus />
          </div>
          <div className="please-please">Please Select a Document or Two</div>
        </NoSlecected>
      )}
    </Wrap>
  );
}

const Wrap = styled.div`
  background-color: #f6f7f9;
  margin-top: 16px;
  .click-drag {
    color: #777;
    font-weight: bold;
    font-size: 11px;
    line-height: 1.1;
  }
  .uncheck_all {
    cursor: pointer;
    transition: all 0.1s;
    color: #000e48;
    padding: 2px 4px;
    :hover {
      color: #00b8d4;
    }
  }
`;

const SelectedHeaderWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
  .list-header {
    font-size: 18px;
    font-weight: bold;
    color: rgb(236, 114, 88);
    display: flex;
    align-items: center;
    .counter {
      align-items: center;
      background-color: #1f2b5e;
      border-radius: 2px;
      color: #fff;
      display: flex;
      font-size: 12px;
      height: 16px;
      margin-left: 4px;
      padding: 2px 4px;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e9e9e9;
  justify-content: space-between;
  font-weight: 400;
  padding: 8px;
  .sorter-sort {
    padding: 2px 4px;
    transition: all 0.1s;
    font-size: 11px;
    cursor: pointer;
    color: #000e48;
    display: flex;
    align-items: center;
    svg {
      max-width: 20px;
      margin-left: 2px;
      g {
        stroke: currentColor;
      }
    }
    :hover {
      color: #00b8d4;
    }
  }
  .sort-square {
    height: 32px;
    width: 32px;
    svg {
      max-width: 20px;
    }
  }
  @media (min-width: 768px) {
    padding: 8px 16px;
  }
`;

const ListWrap = styled.div`
  max-height: 180px;
  overflow: auto;
  .list-items_wrap {
    padding: 10px 16px;
    .row_text {
      display: flex;
      span {
        margin-right: 0;
        :first-of-type {
          color: #ec7258;
          margin-right: 4px;
        }
      }
    }
  }
  .draggable-item {
    align-items: center;
    border: 1px solid #f6f7f9;
    cursor: pointer;
    display: flex;
    line-height: 1.7;
    padding: 1px 4px;
    position: relative;
    text-align: left;
    border-radius: 2px;
    span {
      margin-right: 24px;
    }
    .delete-file-icon {
      align-items: center;
      display: none;
      height: 100%;
      margin-left: 8px;
      position: absolute;
      right: 8px;
      top: 0;
      width: 16px;
      svg {
        max-width: 16px;
        width: 16px;
        g {
          stroke-width: 2px;
          stroke: rgb(238, 114, 90);
        }
        :hover {
          g {
            stroke: #00b8d4;
          }
        }
      }
    }
    :hover {
      background-color: #fff;
      .delete-file-icon {
        display: flex;
      }
    }
  }
  @media (max-width: 768px) {
    .list-items_wrap {
      padding: 8px;
      font-size: 13px;
    }
  }
`;

const NoSlecected = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 8px;
  svg {
    max-width: 100%;
    height: auto;
  }
  .illus-wrap {
    background-color: rgba(241, 241, 241, 0.3);
    border-radius: 10px;
    padding: 16px;
    svg {
      width: 100%;
      height: auto;
      max-width: 100%;
    }
  }
  .please-please {
    font-size: 20px;
    margin-top: 32px;
    line-height: 1.4em;
  }
`;
