/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { moveItem, sort } from "../../../helpers/draggableList";
import useDocumentDetailsFormState from "../../../hooks/use-document-details-form-state";
import { useDocuments } from "../../../Providers/DocumentsProvider";
import { ModalType } from "../../../Providers/types";
import ActionSideDraw from "../../../ui/ActionSideDraw";
import DraggableList from "../../../ui/DraggableList";
import { XIcon } from "../../../ui/icons";
import { BaseButton, ButtonsWrap, GhostButton } from "../../../ui/modalButtons";
import { InnerModal } from "../../../ui/modalStyles";
import Spinner from "../../../ui/Spinner";
import DocumentDetailsForm from "../document-actions/DocumentDetailsForm";
import { Document } from "../types";

export default function BasicMerge() {
  const {
    uncheckAllDocuments,
    selectedDocuments,
    getDocuments,
    toggleModal,
    closeModal,
    currentOpenModal,
    handleDocumentCheckbox
  } = useDocuments();
  const [modalDocuments, setModalDocuments] = useState<Document[]>([]);
  const [deleteDocs, setDeleteDocs] = useState(false);
  const [saveToWeb, setSaveToWeb] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setModalDocuments(selectedDocuments);
  }, [selectedDocuments]);

  const handleMoveItem = (dragIndex, hoverIndex) => {
    const newList = moveItem(modalDocuments, dragIndex, hoverIndex);
    setModalDocuments(newList);
  };

  const handleSort = (sortASC = true) => {
    const newList = sort(modalDocuments, sortASC);
    setModalDocuments(newList);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    let { description, additionalDescription } = data;
    if (description === "Other") {
      description = data.additionalDescription;
    } else if (!!additionalDescription) {
      description += ` ${additionalDescription}`;
    }

    const payload = {
      documents: modalDocuments.map((d) => ({ sourceId: d.id })),
      saveInfo: {
        mtaNum: data.mtaNum,
        description: `${description}`,
        textToLink: data.textToLink,
        subcategoryId: data.subcategoryId,
        web: saveToWeb,
        deleteOriginalDocuments: deleteDocs
      }
    };
    await axios.post("/api/documents/mergeandsave", payload);
    await getDocuments();
    setIsSubmitting(false);
    closeModal();
    uncheckAllDocuments();
  };

  const { data, errors, setData, setSubmitClicked } = useDocumentDetailsFormState(handleSubmit);

  return (
    <Fragment>
      <div className="file-action-button" onClick={() => toggleModal(ModalType.Merge)}>
        Merge
      </div>
      {currentOpenModal === ModalType.Merge && (
        <ActionSideDraw close={closeModal} tileStyles={{ maxWidth: 840 }}>
          <div onSubmit={(e) => e.preventDefault()} style={{ width: "100%" }}>
            <InnerModal css={{ display: "flex", " .modal-header": { maxWidth: 720 } }}>
              <div className="modal-header">
                <div className="modal-title">Merge To Web</div>
                <div className="close-icon" onClick={closeModal}>
                  <XIcon />
                </div>
              </div>
              <HalfWrap>
                <div className="half-header">
                  <div>
                    <div className="circle">1</div>
                    Form
                  </div>
                </div>
                <div className="half-body">
                  <DocumentDetailsForm data={data} setData={setData} errors={errors} />
                  <CheckboxesWrap>
                    <div>
                      <input type="checkbox" checked={deleteDocs} onChange={() => setDeleteDocs(!deleteDocs)} />
                      <span>Delete documents after merge</span>
                    </div>
                    <div>
                      <input type="checkbox" checked={saveToWeb} onChange={() => setSaveToWeb(!saveToWeb)} />
                      <span>Public</span>
                    </div>
                  </CheckboxesWrap>
                </div>
              </HalfWrap>
              <HalfWrap>
                <div className="half-header">
                  <div>
                    <div className="circle">2</div>
                    Documents
                  </div>
                </div>
                <div className="half-body">
                  <DraggableList
                    items={modalDocuments}
                    moveItem={handleMoveItem}
                    handleSort={(sortASC) => handleSort(sortASC)}
                    handleDelete={(item) => handleDocumentCheckbox(false, item)}
                    handleDeleteAll={uncheckAllDocuments}
                  />
                </div>
              </HalfWrap>
            </InnerModal>
            <ButtonsWrap>
              <GhostButton onClick={closeModal}>Cancel</GhostButton>
              <BaseButton type="submit" onClick={() => setSubmitClicked(true)}>
                <Spinner local={true} display={isSubmitting} />
                Submit
              </BaseButton>
            </ButtonsWrap>
          </div>
        </ActionSideDraw>
      )}
    </Fragment>
  );
}

const HalfWrap = styled.div`
  width: 100%;
  max-width: 420px;
  :last-child {
    max-width: 420px;
    .half-body {
      overflow: auto;
    }
  }
  .half-header {
    align-items: center;
    background-color: #eee;
    display: flex;
    font-size: 18px;
    font-weight: bold;
    height: 48px;
    justify-content: space-between;
    padding: 0 16px;
    position: relative;
    width: 100%;
    z-index: 5;
    > div {
      display: flex;
      align-items: center;
    }
    .circle {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background-color: #fff;
      color: #1f2b5e;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      font-size: 16px;
    }
  }
  .half-body {
    height: calc(100% - 48px);
    overflow: auto;
    padding: 8px 16px;
    overflow: visible;
    .list-items_wrap {
      height: 100%;
    }
    .list-wrap_or {
      max-height: calc(100vh - 320px);
    }
    .selected-header {
      text-align: left;
      .list-header {
        font-size: 14px;
      }
      .click-drag {
        font-size: 10px;
      }
    }
    .please-select {
      padding: 40px 8px 8px;
      svg {
        max-width: 65%;
      }
    }
  }
`;

const CheckboxesWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  align-items: center;
  max-width: 360px;
  > div {
    text-align: left;
    span {
      display: block;
    }
    input {
      margin-bottom: 1px;
      cursor: pointer;
    }
  }
`;
