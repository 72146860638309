import React from "react";
import { Root, Avatar, PhoneIcon, LocationIcon, SuitcaseIcon, FaxIcon } from "./contactCardStyles";
import { Contact } from "../../../Providers/types";

interface IProps {
  contact: Contact;
}

export default function GovernmentContactCard({ contact }: IProps) {
  const { name, payeeName, address1, address2, city, state, zip, phone, fax } = contact || {};
  const hasContact = Boolean(name);

  return (
    <Root className="conttcardOverride">
      <div className="card_top">
        <Avatar notAllowed={false} hasContact={hasContact} />
      </div>
      <div className="tac">
        <div className="bold fs16">{name || "????"}</div>
        {payeeName !== name && <div className="fs12">{payeeName}</div>}
        <div className="contact_info">
          <div>
            <PhoneIcon />
            <span>{phone || "--"}</span>
          </div>
          <div>
            <FaxIcon />
            <span>{fax || "--"}</span>
          </div>
          <div>
            <LocationIcon />
            {hasContact ? (
              <>
                <div>{address1}</div>
                <div>{address2}</div>
                <div>
                  {city}, {state} {zip}
                </div>
              </>
            ) : (
              "--"
            )}
          </div>
        </div>
      </div>
    </Root>
  );
}
