import styled from '@emotion/styled';
import React, { useState, useEffect, useRef } from 'react';
import { DownCarot } from './icons';
import useOnClickOutside from '../hooks/use-on-outside-click';
import ReactSelect from 'react-select';

export const SearchDropdown = ({ label, items, onClick, value, selectWidth = null, className = null }) => {
  const selectedValue = value ? { value, label: value?.name || value } : null;
  return (
    <AvrumiSelectWrap className={className}>
      <div className='label'>{label}</div>
      <ReactSelect
        options={items?.map((i) => {
          const text = i?.name || i;
          return { value: i, label: text };
        })}
        classNamePrefix='custom'
        value={selectedValue}
        onChange={({ value }) => onClick(value)}
      />
    </AvrumiSelectWrap>
  );
};

const AvrumiSelectWrap = styled.div`
  position: relative;
  .custom__control {
    cursor: pointer;
  }
  .label {
    background-color: #fff;
    color: black;
    font-size: 12px;
    left: 8px;
    letter-spacing: 0.02em;
    padding: 0 4px;
    position: absolute;
    top: -9px;
    transition: all 0.2s;
    z-index: 5;
  }
`;

export default function Dropdown({ label, items, onClick, value, selectWidth = null, className = null }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selected, setSelected] = useState();
  const dropDownRef = useRef(null);

  useOnClickOutside(dropDownRef, () => setDropdownOpen(false));

  useEffect(() => {
    setSelected(value);
  }, [items]); // eslint-disable-line

  const handleClick = (item) => {
    setSelected(item.name || item);
    onClick(item);
    setDropdownOpen(false);
  };

  return (
    <Root className='select-override' ref={dropDownRef}>
      <Field className={className} onClick={() => setDropdownOpen(!dropdownOpen)} dropdownOpen={dropdownOpen}>
        <div className='label'>{label}</div>
        <div>{selected || 'Select...'}</div>
        <DownCarot />
      </Field>
      {items && (
        <Select dropdownOpen={dropdownOpen} selectWidth={selectWidth}>
          <div>
            {items.map((item, i) => {
              return (
                item != null && (
                  <div key={i} className='option' onClick={() => handleClick(item)}>
                    {item?.name || item}
                  </div>
                )
              );
            })}
          </div>
        </Select>
      )}
    </Root>
  );
}

export function BaseDropdown({ label, children }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <Root className='select-override'>
      <Field onClick={() => setDropdownOpen(!dropdownOpen)} dropdownOpen={dropdownOpen}>
        <div className='label'>{label}</div>
        <div>Select...</div>
        <DownCarot />
      </Field>
      <Select dropdownOpen={dropdownOpen}>
        <div>{children}</div>
      </Select>
    </Root>
  );
}

const Root = styled.div`
  position: relative;
  margin-top: 20px;
  background-color: #fff;
`;
export const Field = styled.div`
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${(props) => (props.dropdownOpen ? '#1f2b5e' : '#c2c5d3')};
  color: #333;
  cursor: pointer;
  display: flex;
  height: 40px;
  padding-left: 16px;
  position: relative;
  transition: all 0.13s;
  width: 100%;
  :hover {
    border-color: #1f2b5e;
    svg {
      path {
        stroke: #1f2b5e;
      }
    }
  }
  svg {
    margin-bottom: 0;
    max-width: 16px;
    position: absolute;
    right: 16px;
    transform: rotate(${(props) => (props.dropdownOpen ? '-180deg' : '0deg')});
    transition: all 0.13s;
    path {
      stroke: ${(props) => (props.dropdownOpen ? '#1f2b5e' : '#c2c5d3')};
    }
  }
  .label {
    background-color: #fff;
    color: black;
    font-size: 12px;
    left: 8px;
    letter-spacing: 0.02em;
    padding: 0 4px;
    position: absolute;
    top: -10px;
    transition: all 0.2s;
  }
`;
export const Select = styled.div`
  background-color: #fff;
  height: ${(props) => (props.dropdownOpen ? 'auto' : '0px')};
  max-width: 320px;
  overflow: hidden;
  overflow: ${(props) => (props.dropdownOpen ? 'visible' : 'hidden')};
  position: absolute;
  top: 48px;
  width: 100%;
  z-index: 50;
  > div {
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #1f2b5e;
    max-height: 540px;
    overflow: auto;
    padding: 10px;
    width: ${(props) => (props.selectWidth ? props.selectWidth : '100%')};
    > div {
      border-radius: 4px;
      cursor: pointer;
      margin-top: 4px;
      padding: 4px 8px;
      :first-of-type {
        margin-top: 0;
      }
      :hover {
        background-color: rgba(242, 242, 242, 1);
      }
    }
  }
`;
