import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { OrdersTableProps } from "../types/home/prop-types";
import { format } from "date-fns";
import highlight from "../helpers/results-highlighter";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    overflow: "auto"
  },
  title: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    fontSize: 20,
    fontWeight: 700,
    padding: "12px 16px 6px",
    position: "sticky",
    top: 0,
    width: "100%",
    left: 0,
    right: 0
  },
  tableHeader: {
    backgroundColor: "#ececf1",
    position: "sticky",
    top: 50,
    width: "100%",
    left: 0,
    right: 0,
    "& th, span": { fontWeight: 700, fontSize: 14 }
  },
  orderNumberCell: {
    maxWidth: 150,
    "& p": { fontSize: 14 }
  },
  rowRoot: {
    cursor: "pointer",
    height: 73,
    "& td": { padding: "0 16px" }
  },
  textOverFlow: {
    maxHeight: 41,
    overflow: "auto",
    paddingRight: 4
  }
}));

export const statusColorMap: { [key: string]: string } = {
  "In process": "#3b49d4",
  Transferred: "#3b49d4",
  Tentative: "#252f85",
  Hold: "#7e60ab",
  Pending: "#552899",
  Firm: "#47a842",
  Completed: "#2a8a25",
  Closed: "#1a5417",
  Adjourned: "#E0a800",
  Canceled: "#FF2929",
  Duplicate: "#AC0000",
  Default: "#000000",
  Dormant: "#78909C"
};

const getStatusColor = (statusType: string) => {
  const color = statusColorMap[statusType];
  return { color };
};

export default ({ orders, searchText, onOrderClick, headerText }: OrdersTableProps) => {
  const classes = useToolbarStyles({});
  return (
    <TableContainer component={Paper} elevation={0} className={classes.root}>
      <Typography className={classes.title} variant="h6" id="tableTitle">
        {headerText}
      </Typography>
      <Table>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell align="left">
              <Typography noWrap component="span">
                Order Number
              </Typography>
            </TableCell>
            <TableCell align="left" style={{ whiteSpace: "nowrap" }}>
              Project Name
            </TableCell>
            <TableCell align="left">
              <Typography noWrap component="span">
                Closing Date
              </Typography>
            </TableCell>
            <TableCell align="left">Address</TableCell>
            <TableCell align="left">Block/Lot/Parcel</TableCell>

            <TableCell align="left">Marketing Source/Buyer/Seller</TableCell>
            <TableCell align="left">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order, i) => {
            let blockLot = "";
            if (order.blocks?.length > 0) {
              if (order.blocks.length === 1) {
                blockLot = `Block: ${order.blocks[0]}`;
              } else {
                blockLot = order.blocks.join(" & ");
                blockLot = `Blocks: ${blockLot}`;
              }

              if (order.lots.length > 0) {
                if (order.lots.length === 1) {
                  blockLot += ` Lot: ${order.lots[0]}`;
                } else {
                  blockLot = order.lots.join(" & ");
                  blockLot = `Lots: ${blockLot}`;
                }
              }

              if (order.parcels.length > 0) {
                if (order.parcels.length === 1) {
                  blockLot += `  Parcel: ${order.parcels[0]}`;
                } else {
                  blockLot = order.parcels.join(" & ");
                  blockLot = `Parcels: ${blockLot}`;
                }
              }
            } else {
              blockLot = order.escrowBriefLegal;
            }
            //@ts-ignore
            return (
              <TableRow
                component="a"
                href={`/new-home/${order.orderNumber}`}
                classes={{ root: classes.rowRoot }}
                hover
                key={i}
                onClick={() => onOrderClick(order)}
              >
                <TableCell align="left" className={classes.orderNumberCell}>
                  <Typography noWrap>{highlight(order.orderNumber, searchText)}</Typography>
                </TableCell>
                <TableCell align="left">
                  <div className={classes.textOverFlow}>{highlight(order.projectName, searchText)}</div>
                </TableCell>
                <TableCell align="left">
                  {order.settlementDate ? format(new Date(order.settlementDate), "MM/dd/yyy") : "Open"}
                </TableCell>
                <TableCell align="left">
                  {highlight(`${order.address || ""} ${order.city || ""} ${order.state || ""}`, searchText)}
                  {!!order.additionalPropertyAddresses && <br />}
                  {highlight(order.additionalPropertyAddresses?.join(", "), searchText)}
                </TableCell>

                <TableCell align="left">{highlight(blockLot, searchText)}</TableCell>
                <TableCell align="left">
                  <div style={{ minWidth: 400 }}>
                    {!!order.marketingSourceType && `${order.marketingSourceType}: `}
                    {!!order.marketingSourceType && highlight(order.marketingSource, searchText)}
                    {!!order.marketingSourceType && <br />}
                    {!!order.buyer && `Buyer: `} {!!order.buyer && highlight(order.buyer, searchText)}
                    {!!order.buyer && <br />}
                    {!!order.seller && `Seller: `} {!!order.seller && highlight(order.seller, searchText)}
                    {!!order.principal && `Principal: `} {!!order.principal && highlight(order.principal, searchText)}
                  </div>
                </TableCell>
                <TableCell align="left">
                  {Boolean(order.status) && <Chip
                      variant="outlined"
                      label={order.status}
                      style={{
                       height: "24px",
                       width: "100px",
                       borderRadius: "6px",
                       fontWeight: 600,
                       borderColor: getStatusColor(order.status).color,
                       color: getStatusColor(order.status).color}}
                  />
                  }
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
