import * as React from "react";
import axios, { AxiosResponse } from "axios";
import { useQueryParam, StringParam } from "use-query-params";
import { parse } from "query-string";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import { Button, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from "@material-ui/core";
import { Alert, Snackbar, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

import { Order } from "../types/home/order";
import useDebounce from "../hooks/use-debounce";
import { addToHistory, getAllFromHistory } from "../helpers/order-history";
import OrdersTable from "./orders-table";

import useOnClickOutside from "../hooks/use-on-outside-click";
import Layout from "../layouts/layout";
import theme from "../ui/theme";

import NoResults from "../ui/icons/NoResults";

import { RootStyles, useStyles, AdvancedSearchWrap } from "./homeStyles";
import { logEvent } from "../helpers/analyticsLogger";
import DashboardButtons from "./dashboard/Dashboard";

type AdvancedSearchFormData = {
  address: string;
  city: string;
  state: string;
  county: string;
  section: string;
  block: string;
  lot: string;
  parcelId: string;
  transactingParty: string;
  exchangeFilesOnly: boolean;
};

const { useState, useEffect, useRef } = React;

export default function Home() {
  const classes = useStyles({});
  const [orders, setOrders] = useState<Order[]>([]);
  const orderHistory = getAllFromHistory();
  const [isSearching, setIsSearching] = useState(false);
  const [query, setQuery] = useQueryParam("query", StringParam);
  const [searchText, setSearchText] = useState(query);
  const [advancedSearchMode, setAdvancedSearchMode] = useState(false);
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const advancedSearchRef = useRef<HTMLDivElement>(null);
  const advancedButtonRef = useRef<HTMLButtonElement>(null);
  const [exchangeFilesOnly, setExchangeFilesOnly] = useState(
    localStorage.getItem("exchange-only")?.toLowerCase() === "true"
  );
  const [advancedSearchForm, setAdvancedSearchForm] = useState<AdvancedSearchFormData>({
    address: "",
    city: "",
    state: "",
    county: "",
    section: "",
    block: "",
    lot: "",
    parcelId: "",
    transactingParty: "",
    exchangeFilesOnly: false
  });

  const [showDashboard, setShowDashboard] = useState(false);
  // useOnClickOutside(advancedSearchRef, () => setShowAdvancedSearch(false), advancedButtonRef);
  const [showSearchResultsAlert, setShowSearchResultsAlert] = useState(false);
  const anyAdvancedSearchHasText = () => Object.values(advancedSearchForm).some((r) => !!r);

  const buildDocumentPageLink = (orderNumber: string) => `/new-home/${orderNumber}`;

  const goToOrder = (order: Order) => {
    addToHistory(order);
    let a = document.createElement("a");
    a.href = buildDocumentPageLink(order.orderNumber);
    a.click();
  };

  const onSearchKeyPress = async (e: { keyCode: number }) => {
    const goToOrderNoHistory = (orderNumber: string) => {
      let a = document.createElement("a");
      a.href = buildDocumentPageLink(orderNumber);
      a.click();
    };

    if (e.keyCode !== 13 || !searchText) {
      return;
    }

    const { data: orders }: AxiosResponse<Order[]> = await axios.get(`/api/searching/search?query=${searchText}`);
    logEvent(
      "Search Page",
      "Search by pressing enter",
      JSON.stringify({ searchText, resultCount: orders.length }, null, 2)
    );
    if (!orders.length) {
      goToOrderNoHistory(searchText);
      return;
    }
    const order = orders.find((o) => o.orderNumber.toLowerCase() === searchText.trim().toLowerCase());
    if (!order) {
      return;
    }
    goToOrder(order);
  };

  useEffect(() => {
    const doSearch = async () => {
      setIsSearching(true);
      if (debouncedSearchTerm) {
        const orderPromise = axios.get(
          `/api/searching/search?query=${debouncedSearchTerm}&exchangeOnly=${exchangeFilesOnly}`
        );
        const { data: orders } = await orderPromise;
        if (orders.length > 200) {
          setShowSearchResultsAlert(true);
        }
        logEvent(
          "Search Page",
          "Search by typing",
          JSON.stringify({ searchText: debouncedSearchTerm, resultCount: orders.length }, null, 2)
        );
        setOrders(orders);
      } else {
        setOrders([]);
      }

      setIsSearching(false);
      setAdvancedSearchMode(false);
    };

    doSearch();

    setQuery(debouncedSearchTerm, "replaceIn");
  }, [debouncedSearchTerm, exchangeFilesOnly]);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get("/api/Dashboard/IsDashboardEnabledForCurrentUser");
      setShowDashboard(data.enabled);
    })();
  }, []);

  const doAdvancedSearch = async () => {
    setIsSearching(true);
    const { data: orders } = await axios.get("/api/Searching/advancedsearch", {
      params: advancedSearchForm
    });
    setOrders(orders);
    setIsSearching(false);
    setShowAdvancedSearch(false);
    setAdvancedSearchMode(true);
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      doAdvancedSearch();
    }
  };
  const clearAdvancedSearch = () => {
    setAdvancedSearchForm({
      address: "",
      city: "",
      state: "",
      county: "",
      section: "",
      block: "",
      lot: "",
      parcelId: "",
      transactingParty: "",
      exchangeFilesOnly: false
    });
  };

  const setAdvancedSearchFormState = (obj: Partial<AdvancedSearchFormData>) => {
    setAdvancedSearchForm({ ...advancedSearchForm, ...obj });
  };

  return (
    <ThemeProvider theme={theme}>
      {isSearching && (
        <div
          style={{
            position: "absolute",
            width: "calc(100% - 72px)",
            top: 0,
            right: 0,
            left: 72
          }}
        >
          <LinearProgress color="secondary" />
        </div>
      )}
      <Layout>
        <RootStyles>
          <div className={classes.root}>
            {showDashboard && <DashboardButtons />}
            <div className={classes.search}>
              <TextField
                autoFocus
                id="filled-basic"
                placeholder="Search for file number, contact name or address"
                variant="filled"
                onKeyDown={onSearchKeyPress}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  setShowSearchResultsAlert(false);
                }}
                className={classes.inputRoot}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
              <div className={classes.advancedText}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={exchangeFilesOnly}
                      onChange={(e) => {
                        localStorage.setItem("exchange-only", `${e.target.checked}`);
                        setExchangeFilesOnly(e.target.checked);
                      }}
                      name="exchangeFilesOnly"
                      size="small"
                    />
                  }
                  label="Exchange files only"
                />
                <Button
                  ref={advancedButtonRef}
                  size="small"
                  variant="outlined"
                  onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
                >
                  Advanced Search
                </Button>
              </div>
            </div>
            <AdvancedSearchWrap open={showAdvancedSearch} ref={advancedSearchRef} onKeyDown={handleKeyDown}>
              <div className="inner_wrap">
                <form action="">
                  <div
                  // className='fields_wrap'
                  >
                    <Grid container columnSpacing={4} rowSpacing={1}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={advancedSearchForm.address}
                          fullWidth
                          onChange={(e) =>
                            setAdvancedSearchFormState({
                              address: e.target.value
                            })
                          }
                          size="small"
                          label="Address"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={advancedSearchForm.city}
                          fullWidth
                          onChange={(e) => setAdvancedSearchFormState({ city: e.target.value })}
                          size="small"
                          label="City"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="search-for-state">State</InputLabel>
                          <Select
                            labelId="search-for-state"
                            value={advancedSearchForm.state}
                            onChange={(e) =>
                              setAdvancedSearchFormState({
                                state: e.target.value as string
                              })
                            }
                            style={{ width: "100%" }}
                          >
                            {statesArray.map((state) => (
                              <MenuItem value={state}>{state}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={advancedSearchForm.county}
                          fullWidth
                          onChange={(e) =>
                            setAdvancedSearchFormState({
                              county: e.target.value
                            })
                          }
                          size="small"
                          label="County"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={advancedSearchForm.section}
                          fullWidth
                          onChange={(e) =>
                            setAdvancedSearchFormState({
                              section: e.target.value
                            })
                          }
                          size="small"
                          label="Section"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={advancedSearchForm.block}
                          fullWidth
                          onChange={(e) =>
                            setAdvancedSearchFormState({
                              block: e.target.value
                            })
                          }
                          size="small"
                          label="Block"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          value={advancedSearchForm.lot}
                          onChange={(e) => setAdvancedSearchFormState({ lot: e.target.value })}
                          size="small"
                          label="Lot"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          value={advancedSearchForm.parcelId}
                          onChange={(e) =>
                            setAdvancedSearchFormState({
                              parcelId: e.target.value
                            })
                          }
                          size="small"
                          label="APN/Parcel ID"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          value={advancedSearchForm.transactingParty}
                          onChange={(e) =>
                            setAdvancedSearchFormState({
                              transactingParty: e.target.value
                            })
                          }
                          size="small"
                          label="Buyer/Seller/Attorney/Principal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={advancedSearchForm.exchangeFilesOnly || false}
                              onChange={(e) =>
                                setAdvancedSearchFormState({
                                  ...advancedSearchForm,
                                  exchangeFilesOnly: e.target.checked
                                })
                              }
                              name="exchangeFilesOnly"
                            />
                          }
                          label="Exchange files only"
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div className="df jcfe mt4">
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      style={{ marginRight: 16 }}
                      onClick={clearAdvancedSearch}
                    >
                      Clear
                    </Button>
                    <Button variant="contained" size="small" color="primary" onClick={doAdvancedSearch}>
                      Search
                    </Button>
                  </div>
                </form>
              </div>
            </AdvancedSearchWrap>
          </div>
          <div className="main-wrap">
            {!!orders.length && (searchText || advancedSearchMode) && (
              <>
                <OrdersTable
                  orders={orders}
                  searchText={debouncedSearchTerm}
                  onOrderClick={(order) => {
                    addToHistory(order);
                    logEvent("Search Page", "Search results table click", order.orderNumber);
                  }}
                  headerText="Search Results"
                />
              </>
            )}
            {!!orderHistory.length && !searchText && !advancedSearchMode && (
              <OrdersTable
                orders={orderHistory}
                onOrderClick={(order) => {
                  addToHistory(order);
                  logEvent("Search Page", "History table click", order.orderNumber);
                }}
                headerText="Recent Orders"
              />
            )}
            {!isSearching && ((debouncedSearchTerm && searchText) || advancedSearchMode) && !orders.length && (
              <div className="no-results_sorry">
                <h1 className="mb3">No results found</h1>
                <p className="mb6">
                  Sorry about that.
                  <br />
                  If you believe this is an error, please email{" "}
                  <a href="mailto:devsupport@madisoncres.com">devsupport@madisoncres.com</a>
                </p>
                <NoResults />
              </div>
            )}
          </div>
          <Snackbar open={showSearchResultsAlert} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
            <Alert onClose={() => setShowSearchResultsAlert(false)} severity="info" sx={{ backgroundColor: "#eee" }}>
              Please Note! Only the{" "}
              <Typography display="inline-block" variant="body2" color="error">
                Top 200 Results are shown.
              </Typography>{" "}
              To see different results, refine your search with advanced search.{" "}
            </Alert>
          </Snackbar>
        </RootStyles>
      </Layout>
    </ThemeProvider>
  );
}

const statesArray = [
  "",
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY"
];
