import React from "react";
import { Button } from "@mui/material";

export const AddNewContactOption = ({
  searchedContact,
  onClick
}: {
  searchedContact: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  if (!searchedContact) return;
  return (
    <div style={{ padding: "8px 16px" }}>
      <Button
        sx={{
          textTransform: "lowercase",
          display: "inline-flex",
          width: "100%",
          margin: "0px auto"
        }}
        fullWidth
        onClick={onClick}
        variant="outlined"
      >
        Add "<span style={{ color: "#4440DB" }}>{searchedContact}</span>" to Phonebook
      </Button>
    </div>
  );
};

export default AddNewContactOption;
