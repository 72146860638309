import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { useDocuments } from "../../../Providers/DocumentsProvider";
import { useOrder } from "../../../Providers/OrderProvider";
import { SearchDropdown } from "../../../ui/Dropdown";

export default function DocumentDetailsForm({ data, setData, errors }) {
  const { mtaNum, textToLink, category, subcategoryId, subcategory, description, additionalDescription } = data;
  const { order } = useOrder();
  let { categories } = useDocuments();
  categories = [...categories].sort((c1, c2) => (c1.name > c2.name ? 1 : -1));
  categories.forEach((c) => {
    c.subcategories.sort((sc1, sc2) => (sc1.name > sc2.name ? 1 : -1));
    c.subcategories.forEach((sc) => sc.descriptions?.sort());
  });

  const quickSearchItems = categories.flatMap((category) =>
    category.subcategories.flatMap((subcategory) =>
      subcategory.descriptions.map((description) => ({
        label: `${category.name} > ${subcategory.name} > ${description}`,
        category: category.name,
        subcategory: subcategory.name,
        description: description
      }))
    )
  );

  useEffect(() => {
    setData({ ...data, mtaNum: order?.orderNumber });
  }, []);

  return (
    <FormWrap>
      <TextfieldWrap>
        <label>MTA#</label>
        <input
          type="text"
          name="mta-num"
          value={mtaNum || ""}
          onChange={(e) => setData({ ...data, mtaNum: e.target.value })}
          className={errors.mtaNum ? "required" : ""}
        />
      </TextfieldWrap>
      <TextfieldWrap>
        <label>Text to Link</label>
        <input
          name="text-to-link"
          type="text"
          value={textToLink || ""}
          onChange={(e) => setData({ ...data, textToLink: e.target.value })}
        />
      </TextfieldWrap>
      <SearchDropdown
        label="QuickSearch of Category > Subcategory > Description "
        items={quickSearchItems.map((item) => item.label)}
        onClick={(e) => {
          const selectedItem = quickSearchItems.find((item) => item.label === e);
          if (selectedItem) {
            setData({
              ...data,
              category: selectedItem.category,
              subcategory: selectedItem.subcategory,
              subcategoryId: categories
                .find((c) => c.name === selectedItem.category)
                ?.subcategories.find((s) => s.name === selectedItem.subcategory)?.id,
              description: selectedItem.description
            });
          }
        }}
        value={
          data.category || data.subcategory || data.description
            ? `${data.category || ""} ${data.subcategory && ">"} ${data.subcategory || ""} ${data.description && ">"} ${
                data.description || ""
              }`
            : null
        }
        className={errors.category ? "required" : ""}
      />
      <SearchDropdown
        label="Category"
        items={categories}
        onClick={(e) =>
          setData({
            ...data,
            category: e.name,
            subcategoryId: null,
            subcategory: "",
            description: ""
          })
        }
        value={category}
        className={errors.category ? "required" : ""}
      />
      <SearchDropdown
        label="Subcategory"
        items={categories.find((c) => c.name === category)?.subcategories}
        onClick={(e) =>
          setData({
            ...data,
            subcategoryId: e.id,
            subcategory: e.name,
            description: ""
          })
        }
        value={subcategory}
        className={errors.subcategoryId ? "required" : ""}
      />
      <SearchDropdown
        label="Description"
        items={
          categories.flatMap((c) => c.subcategories)?.find((s) => s.id === subcategoryId)?.descriptions.length
            ? [
                ...categories.flatMap((c) => c.subcategories)?.find((s) => s.id === subcategoryId)?.descriptions,
                "Other"
              ]
            : ["Other"]
        }
        onClick={(e) => setData({ ...data, description: e })}
        value={description}
        className={errors.description ? "required" : ""}
        selectWidth="320px"
      />
      <TextfieldWrap>
        <label>Additional Description</label>
        <input
          name="additional-description"
          type="text"
          value={additionalDescription}
          onChange={(e) => setData({ ...data, additionalDescription: e.target.value })}
          className={errors.additionalDescription ? "required" : ""}
        />
      </TextfieldWrap>
    </FormWrap>
  );
}

const FormWrap = styled.form`
  text-align: left;
  .custom__menu {
    z-index: 60;
  }
  > * {
    width: 100%;
    margin-top: 28px;
    :first-of-type {
      margin-top: 20px;
    }
  }
  .row {
    display: flex;
    align-items: center;
    margin-top: 16px;
    span {
      margin-left: 8px;
    }
  }
  .required {
    border: 1px solid red;
    border-radius: 5px;
  }
`;

const TextfieldWrap = styled.div`
  position: relative;

  input {
    border-radius: 5px;
    border: 1px solid #c2c5d3;
    color: #333;
    height: 36px;
    padding: 0 16px;
    transition: all 0.2s;
    width: 100%;
  }
  label {
    background-color: #fff;
    color: black;
    font-size: 12px;
    left: 8px;
    letter-spacing: 0.02em;
    padding: 0 4px;
    position: absolute;
    top: -10px;
    transition: all 0.2s;
  }
  :focus-within {
    input {
      border-color: #1f2b5e;
    }
  }
`;
