import styled from "@emotion/styled";
import React from "react";
import { CloseIcon } from "../icons";

interface SiderawProps {
  children: React.ReactNode;
  isOpen: boolean;
  close: () => {};
  header?: React.ReactNode;
  openWidth?: string;
}

export default function Sidedraw({ children, isOpen, close, header, openWidth }: SiderawProps) {
  return (
    <Root isOpen={isOpen} openWidth={openWidth}>
      {header && (
        <div className="header_wrap">
          <DrawHeader>{header}</DrawHeader>
          <CloseIcon onClick={close} />
        </div>
      )}
      {children}
    </Root>
  );
}

const Root = styled.div<{ isOpen: boolean; openWidth?: string }>`
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 4px 0px 32px rgba(8, 22, 81, 0.05);
  height: calc(100vh - 16px);
  overflow-x: hidden;
  position: fixed;
  right: ${(props) => (props.isOpen ? "8px" : "-100vw")};
  top: 8px;
  transition: right 0.1s linear;
  width: calc(100vw - 16px);
  z-index: 210;
  box-shadow:
    0px 1px 2px rgb(46 46 54 / 10%),
    0px 1px 3px rgb(46 46 54 / 5%);
  .header_wrap {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #c1c1c1;
    svg {
      cursor: pointer;
      :hover {
        stroke: #00b8d4;
      }
    }
  }
  @media (min-width: 768px) {
    right: ${(props) => (props.isOpen ? "8px" : props.openWidth ? -props.openWidth : "-480px")};
    width: 450px;
    width: ${(props) => props.openWidth || `450px`};
    top: 16px;
    height: calc(100vh - 32px);
  }
`;

export const DrawHeader = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #1f2b5e;
`;
