import styled from "@emotion/styled";
import axios from "axios";
import React, { useEffect, useState } from "react";
import useDocumentDetailsFormState from "../../../hooks/use-document-details-form-state";
import { useDocuments } from "../../../Providers/DocumentsProvider";
import { Edit, XIcon } from "../../../ui/icons";
import ActionSideDraw from "../../../ui/ActionSideDraw";
import { ButtonsWrap, GhostButton, BaseButton } from "../../../ui/modalButtons";
import { InnerModal } from "../../../ui/modalStyles";
import { Document } from "../types";
import DocumentDetailsForm from "./DocumentDetailsForm";
import Spinner from "../../../ui/Spinner";

export default function EditModal({ document }: { document: Document }) {
  const [showModal, setShowModal] = useState(false);
  const [modalDocument, setModalDocument] = useState<Document>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { getDocuments, setIsEditBarSectionOpen } = useDocuments();

  useEffect(() => {
    setIsEditBarSectionOpen(showModal);
  }, [showModal]);
  useEffect(() => {
    setModalDocument({ ...document });
  }, [document, showModal]);

  useEffect(() => {
    modalDocument &&
      setData({
        ...data,
        textToLink: modalDocument.textToLink,
        category: modalDocument.category,
        subcategoryId: modalDocument.subcategoryId,
        subcategory: modalDocument.subcategory,
        description: modalDocument.descriptionOnly || "Other",
        additionalDescription: modalDocument.additionalDescription
      });
  }, [modalDocument]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    let { description, additionalDescription } = data;
    if (description === "Other") {
      description = data.additionalDescription;
    } else if (!!additionalDescription) {
      description += ` ${additionalDescription}`;
    }

    const payload = {
      document: {
        ...modalDocument,
        mtaNum: data.mtaNum,
        textToLink: data.textToLink,
        category: data.category,
        subcategoryId: data.subcategoryId,
        documentDescription: `${description}`
      },
      doOverwrite: document.documentDescription !== description
    };
    await updateDoc(payload);
    setIsSubmitting(false);
    setShowModal(false);
  };

  async function updateDoc(payload: { document: Document; doOverwrite: boolean }) {
    await axios.post("/api/documents/update", payload);
    await getDocuments();
  }

  const { data, errors, setData, setSubmitClicked } = useDocumentDetailsFormState(handleSubmit);

  return (
    <>
      <Edit onClick={() => setShowModal(true)} />
      {showModal && (
        <ActionSideDraw close={() => setShowModal(false)}>
          <InnerModalEdit>
            <div className="modal-header">
              <div className="modal-title">Edit Document</div>
              <div className="close-icon" onClick={() => setShowModal(false)}>
                <XIcon />
              </div>
            </div>
            <DocumentDetailsForm data={data} setData={setData} errors={errors} />
          </InnerModalEdit>
          <ButtonsWrap>
            <GhostButton onClick={() => setShowModal(false)}>Cancel</GhostButton>
            <BaseButton onClick={() => setSubmitClicked(true)}>
              <Spinner local={true} display={isSubmitting} />
              Edit Document
            </BaseButton>
          </ButtonsWrap>
        </ActionSideDraw>
      )}
    </>
  );
}

const InnerModalEdit = styled(InnerModal)`
  padding: 20px;
  input {
    border: 1px solid black;
  }
`;
