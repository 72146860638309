import * as React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { useOrder } from "../Providers/OrderProvider";

import {
  CalendarIcon,
  BarcodeIcon,
  BusinessGraphIcon,
  FilesIcon,
  LinkIcon,
  CalculatorIcon,
  BankingBillIcon,
  InterfaceIcon,
  ListIcon,
  WiredIcon,
  FlipIcon
} from "../ui/icons/icon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 16
    },
    header: {
      padding: theme.spacing(2),
      fontWeight: 700,
      fontFamily: "sans-serif"
    },
    quip: {
      fontSize: 12,
      padding: theme.spacing(3, 2, 2),
      color: "#9e9e9e"
    },
    secondSection: {
      marginBottom: 16
    }
  })
);

const usefulLinks = {
  name: "Useful Links",
  icon: <LinkIcon />,
  links: [
    {
      name: "Help Desk",
      link: "https://madisonconnect.org/forms/help-desk/"
      // groupsAllowed: []
    },
    {
      name: "ADP",
      link: "https://workforcenow.adp.com/public/index.htm"
      // groupsAllowed: []
    },
    {
      name: "Teams",
      link: "https://teams.microsoft.com"
      // groupsAllowed: []
    },
    {
      name: "Old Homepage",
      link: "http://home/homefeatures/"
      // groupsAllowed: []
    },
    {
      name: "J2 Pizza",
      link: "https://www.j2-pizzasouth.com/"
      // groupsAllowed: []
    }
  ]
};
interface TemporaryDrawerProps {
  state: string;
}

export default function TemporaryDrawer(props: TemporaryDrawerProps) {
  const { state } = props;
  const classes = useStyles({});

  const orderData = useOrder();

  const appList = [
    {
      name: "Intel",
      icon: <InterfaceIcon />,
      links: [
        {
          name: "Title - NY",
          link: "https://madisondocs.com/title/ny/"
          // groupsAllowed: [groups.MTA_NY]
        },
        {
          name: "Title - National",
          link: "https://madisonintel.com"
          // groupsAllowed: [groups.MTA_NATIONAL]
        },
        {
          name: "NJ Title Production",
          link: "http://njcb.madisondocs.com/"
          // groupsAllowed: [groups.MTA_NATIONAL]
        },
        {
          name: "NY Title Production",
          link: "http://nycb.madisondocs.com/"
          // groupsAllowed: [groups.MTA_NATIONAL]
        },
        {
          name: "1031 Exchange",
          link: "https://1031.madisondocs.com/"
          // groupsAllowed: [groups.HUDSON]
        }
      ]
    },
    {
      name: "Verify",
      link: "https://verify.madisontitle.com/",
      icon: <BankingBillIcon />
    },
    {
      name: "Calculators",
      icon: <CalculatorIcon />,
      links: [
        {
          name: "Transfer Tax",
          link: "https://www.madisontitle.com/Title.Site/Frontend/Calculators/TransferTax.aspx"
          // groupsAllowed: []
        },
        {
          name: "Title Fees - NY",
          link: "https://www.madisontitle.com/Title.Site/Frontend/Calculators/TitleCalculator.aspx?state=NY"
          // groupsAllowed: [groups.MTA_NY]
        },
        {
          name: "Title Fees - NJ",
          link: "https://www.madisontitle.com/Title.Site/Frontend/Calculators/TitleCalculator.aspx?state=NJ"
          // groupsAllowed: [groups.MTA_NJ]
        },
        {
          name: "Mortgage Rate Calculator",
          link: "https://www.madisontitle.com/Title.Site/Frontend/Calculators/MortgageCalculator.aspx"
          // groupsAllowed: []
        },
        {
          name: "§1031 Exchange Calculator",
          link: "https://www.madisontitle.com/title.site/frontend/Calculators/1031ExchangeFees.aspx"
          // groupsAllowed: [groups.EXCHANGE]
        }
      ]
    },
    {
      name: "Wired",
      link: "https://wired.mcres.com",
      icon: <WiredIcon />
    },
    {
      name: "Legal Forms",
      link: `https://www.madisontitle.com/Title.Site/Frontend/LegalForms/${
        !!state ? `FormsLibrary.aspx?tabID=&state=${state}` : "Forms.aspx"
      }`,
      icon: <FilesIcon />
    },
    {
      name: "Calendars",
      icon: <CalendarIcon />,
      links: [
        {
          name: "Closing Calendar",
          link: "https://titleq.mcres.com/calendar"
          // groupsAllowed: []
        },
        {
          name: "§1031 Exchange Deadline",
          link: "https://explore.madison1031.com"
          // groupsAllowed: [groups.EXCHANGE]
        }
      ]
    },
    {
      name: "Reports",
      link: "http://www.madisoncres.com/reporting/reports.aspx",
      icon: <BusinessGraphIcon />
    },
    {
      name: "View Orders",
      link: "/orders",
      icon: <ListIcon />
    },
    {
      name: "Training",
      link: "https://training.mcres.com",
      icon: <ListIcon />
    },
    {
      name: "Barcodes",
      icon: <BarcodeIcon />,
      links: [
        {
          name: "Single Upload",
          link: "http://home/homefeatures/BarCodes/PrintBarCode.aspx"
          // groupsAllowed: []
        },
        {
          name: "Accounting",
          link: "http://home/homefeatures/barcodes/printmultBarcodesBankRecs.aspx"
          // groupsAllowed: []
        }
      ]
    }
  ];

  if (orderData) {
    appList.splice(4, 0, {
      name: "Flip Approvals",
      link: `https://titleq.mcres.com/task-management/flips?order=${orderData.order.orderNumber}`,
      icon: <FlipIcon />
    });
  }

  return (
    <>
      <Typography className={classes.header}>Apps</Typography>
      <Divider />
      <List className={classes.root}>
        {appList.map(function (app, index) {
          if (app.links && app.links.length) {
            return <MultiLinks key={index} app={app} />;
          } else {
            return <AppLink key={index} app={app} />;
          }
        })}
        <Divider className={classes.secondSection} />
        <MultiLinks app={usefulLinks} />
      </List>
    </>
  );
}

function MultiLinks({ app }: any) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <React.Fragment>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>{app.icon}</ListItemIcon>
        <ListItemText primary={app.name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <div className="pl3">
            {app.links.map((link: any, idx: number) => (
              <AppLink app={link} key={idx} />
            ))}
          </div>
        </List>
      </Collapse>
    </React.Fragment>
  );
}

function AppLink({ app }: any) {
  return (
    <a href={app.link} target="_blank" rel="noopener noreferrer">
      <ListItem button>
        {app.icon && <ListItemIcon>{app.icon}</ListItemIcon>}
        <ListItemText primary={app.name} />
      </ListItem>
    </a>
  );
}
