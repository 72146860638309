export function formatAsCurrency(value) {
  const isWholeNumber = Number.isSafeInteger(value);
  const currencyOptions = {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: isWholeNumber ? 0 : 2,
    maximumFractionDigits: isWholeNumber ? 0 : 2
  };
  return new Intl.NumberFormat("en-US", currencyOptions).format(value);
}

export const isMobile = (maxSize = 500) => document.documentElement.clientWidth <= maxSize;

export function removeMiddleInitial(name: string) {
  // Step 1: Remove non-alphabetic characters except for spaces
  const cleanedName = name.replace(/[^a-zA-Z\s]/g, "");
  // Step 2: Split the string into an array of words
  const words = cleanedName.split(" ");
  // Step 3: Filter out any single-character words
  const filteredWords = words.filter((word) => word.length > 1);
  // Step 4: Join the remaining words back into a single string
  const result = filteredWords.join(" ");
  return result.trim();
}
