import * as React from "react";

export default function NoResults({ ...props }) {
  // width='459' height='384'
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 459 384"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ maxWidth: 459, maxHeight: 384 }}
      {...props}
    >
      <path d="M438.47 382.57H443.75" stroke="#757575" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M383.75 206.57H290.75V382.57H383.75V206.57Z"
        fill="white"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M197.75 206.57H104.75V382.57H197.75V206.57Z"
        fill="white"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M299.43 206.58H104.07V382.58H114.07V215.58H290.43V382.58H299.43V206.58Z"
        fill="white"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M10.05 382.57H434.09" stroke="#757575" strokeWidth="2" strokeLinecap="round" />
      <path d="M1 382.57H5.63" stroke="#757575" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M44 382.58H86.5L91.5 321.27H39L44 382.58Z"
        fill="white"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M52.3301 335.32L54.4201 382.57" stroke="#757575" strokeWidth="2" strokeLinecap="round" />
      <path d="M80.4199 329.64L75.4199 382.57" stroke="#757575" strokeWidth="2" strokeLinecap="round" />
      <path d="M65.25 329.64V382.57" stroke="#757575" strokeWidth="2" strokeLinecap="round" />
      <path d="M90.8199 329.64H39.6799" stroke="#757575" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M93.1601 321.27H37.3301V329.64H93.1601V321.27Z"
        fill="white"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M78.2401 313.96H78.1301L69.0801 307.91L70.5001 314.12L73.0201 318.56L77.3601 315.39C77.5735 315.23 77.8757 315.27 78.0401 315.48L82.6901 321.32H87.8501L85.0001 316.48L78.2401 313.96Z"
        fill="#E0E0E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.71 321.27L49.37 313.58L55.04 315.24L68.37 306.24L78.47 312.99L85.37 315.58L88.79 321.27H41.71Z"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M290.75 215.57H113.75V382.57H290.75V215.57Z"
        fill="white"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M167.59 332.95L164.88 336.11" stroke="#757575" strokeWidth="2" strokeLinecap="round" />
      <path d="M240.78 247.41L171.91 327.9" stroke="#757575" strokeWidth="2" strokeLinecap="round" />
      <path d="M193.18 319.26L189.98 323" stroke="#757575" strokeWidth="2" strokeLinecap="round" />
      <path d="M235.04 270.35L196.64 315.22" stroke="#757575" strokeWidth="2" strokeLinecap="round" />
      <path d="M289.72 216.59H114.47V226.84H289.72V216.59Z" fill="#E0E0E0" />
      <path d="M299.75 215.57H383.75" stroke="#757575" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M251.25 190.58H150.25L140.25 94.58H241.25L251.25 190.58Z"
        fill="white"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M178.75 206.58H201.75L208.75 169.58H185.75L178.75 206.58Z"
        fill="white"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M194.142 147.883C197.157 147.519 199.174 143.669 198.645 139.282C198.117 134.895 195.244 131.634 192.228 131.997C189.212 132.361 187.196 136.211 187.724 140.598C188.253 144.984 191.126 148.246 194.142 147.883Z"
        fill="#E0E0E0"
      />
      <path
        d="M383.75 206.58H299.75L318.55 103.16C319.238 99.3446 322.563 96.5717 326.44 96.58H395.91C397.851 96.5814 399.692 97.4437 400.935 98.9342C402.178 100.425 402.697 102.39 402.35 104.3L383.75 206.58Z"
        fill="#E0E0E0"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M436.66 68.03C442.501 65.3332 447.59 61.2434 451.48 56.12C457.81 47.68 457.14 46.03 457.14 46.03C457.14 46.03 456.64 45.03 455.14 45.83L457.55 42.45C457.55 42.45 454.47 40.45 452.19 43.97C452.19 43.97 450.33 44.08 447.45 47.23C444.57 50.38 438.98 55.12 435.51 54.33C432.04 53.54 434.33 45.61 430.86 44.75C427.39 43.89 429.56 48.09 429.32 51.24C429.08 54.39 425.95 60.24 426.79 61.74C427.63 63.24 427.79 67.16 427.79 67.16L436.66 68.03Z"
        fill="white"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M247.57 65.26C241.729 62.5631 236.64 58.4734 232.75 53.35C226.41 44.91 227.08 43.26 227.08 43.26C227.08 43.26 227.58 42.26 229.08 43.06L226.67 39.68C226.67 39.68 229.74 37.68 232.02 41.2C232.02 41.2 233.89 41.31 236.76 44.46C239.63 47.61 245.23 52.35 248.7 51.56C252.17 50.77 249.89 42.84 253.35 41.98C256.81 41.12 254.66 45.32 254.89 48.47C255.12 51.62 258.26 57.47 257.43 58.97C256.6 60.47 256.43 64.39 256.43 64.39L247.57 65.26Z"
        fill="white"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M439.65 68.51C432.65 57.84 424.65 61.63 424.65 61.63L412.65 113.21L323.88 124.67L275.95 99.86L258.95 61.71C258.95 61.71 246.32 57.6 244.28 68.85L260.28 114.74L309.68 151.13L299.75 206.58H373.75L384 151.13L429.38 129.58L439.65 68.51Z"
        fill="white"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M366.33 84.99C367.49 86.9 359.26 91.54 358.95 114.34C358.75 129.57 328.75 137.57 328.75 137.57L329.56 119.07C329.56 119.07 317.23 121.24 314.81 110.07C312.39 98.9 315.03 67.07 315.03 67.07C315.03 67.07 354.46 65.52 366.33 84.99Z"
        fill="white"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M336.58 134.1L330.87 144.39L325.5 206.58H306.74L325.75 145.58L328.9 134.1H336.58Z"
        fill="#E0E0E0"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M325.5 164.27H332.16" stroke="#757575" strokeWidth="2" strokeLinecap="round" />
      <path d="M360.22 157.32H362.09" stroke="#757575" strokeWidth="2" strokeLinecap="round" />
      <path d="M346.5 157.32H356.97" stroke="#757575" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M329.36 118.99C331.285 118.825 333.175 118.377 334.97 117.66"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M236.13 105.25L245.44 190.57" stroke="#757575" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M366.44 83.99C366.44 72.14 364.08 60.57 350.39 56.99C342.59 54.99 334.81 58.55 324 58.99C319.325 59.2039 314.64 59.0065 310 58.4C310 58.4 308 61.64 311.08 66.58C313.82 70.92 319.61 70.91 319.61 70.91C323.885 72.1504 327.532 74.9619 329.82 78.78C334.88 87.41 346.82 88.78 346.82 88.78L346.03 95.73L349.03 96.07C349.03 96.07 349.59 87.07 355.72 88.28C361.85 89.49 351.88 104.96 358.16 107.94C358.09 107.96 366.39 102.1 366.44 83.99Z"
        fill="white"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M327.61 109.02C330.24 108.35 333.73 106.88 335.54 103.7"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M234.97 94.57L235.64 100.75" stroke="#757575" strokeWidth="2" strokeLinecap="round" />
      <path d="M333.39 90.01H346.5" stroke="#757575" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M328.82 96.07C331.344 96.07 333.39 93.3569 333.39 90.01C333.39 86.6632 331.344 83.95 328.82 83.95C326.296 83.95 324.25 86.6632 324.25 90.01C324.25 93.3569 326.296 96.07 328.82 96.07Z"
        fill="white"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M314.29 96.07C316.814 96.07 318.86 93.3569 318.86 90.01C318.86 86.6632 316.814 83.95 314.29 83.95C311.766 83.95 309.72 86.6632 309.72 90.01C309.72 93.3569 311.766 96.07 314.29 96.07Z"
        fill="white"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M324.25 89.22C321.75 86.36 318.82 89.22 318.82 89.22L317.82 99.82H321.64"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M365.38 84.07C365.38 75.79 364.38 65.67 356.05 60.53C350.696 61.0954 345.386 62.0276 340.16 63.32C336.72 64.09 333.16 64.88 329.62 65.48C323.063 66.7267 316.278 65.7039 310.38 62.58C310.632 63.856 311.128 65.0715 311.84 66.16C314.24 69.97 319.47 70.03 319.52 70.03H319.82C324.33 71.3518 328.178 74.3218 330.6 78.35C335.34 86.46 346.71 87.84 346.83 87.85L347.83 87.97L347.04 94.97L348.04 95.08C348.3 93.39 349.04 90.08 351.29 88.38C352.21 87.6584 353.35 87.2737 354.52 87.29C354.953 87.2907 355.385 87.3342 355.81 87.42C356.705 87.5642 357.498 88.0753 358 88.83C359.27 90.75 358.56 94.03 357.81 97.51C356.99 101.35 356.14 105.31 357.97 106.82C359.66 105.21 365.34 98.81 365.38 84.07Z"
        fill="#E0E0E0"
      />
      <path
        opacity="0.4"
        d="M125.29 59.91C126.693 59.91 127.83 58.7728 127.83 57.37C127.83 55.9672 126.693 54.83 125.29 54.83C123.887 54.83 122.75 55.9672 122.75 57.37C122.75 58.7728 123.887 59.91 125.29 59.91Z"
        fill="white"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        opacity="0.4"
        d="M309.32 30.65C310.723 30.65 311.86 29.5128 311.86 28.11C311.86 26.7072 310.723 25.57 309.32 25.57C307.917 25.57 306.78 26.7072 306.78 28.11C306.78 29.5128 307.917 30.65 309.32 30.65Z"
        fill="white"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M208.26 8.78001C209.663 8.78001 210.8 7.64282 210.8 6.24001C210.8 4.83721 209.663 3.70001 208.26 3.70001C206.857 3.70001 205.72 4.83721 205.72 6.24001C205.72 7.64282 206.857 8.78001 208.26 8.78001Z"
        fill="white"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M415.21 6.42C416.707 6.42 417.92 5.20669 417.92 3.71C417.92 2.21331 416.707 1 415.21 1C413.713 1 412.5 2.21331 412.5 3.71C412.5 5.20669 413.713 6.42 415.21 6.42Z"
        fill="white"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
