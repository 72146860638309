import styled from "@emotion/styled";
import axios from "axios";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";

import { useDocuments } from "../../../Providers/DocumentsProvider";
import { DocumentNotes, XIcon } from "../../../ui/icons";
import ActionSideDraw from "../../../ui/ActionSideDraw";
import { GhostButton, BaseButton } from "../../../ui/modalButtons";
import { InnerModal } from "../../../ui/modalStyles";
import { Document } from "../types";

export default function NotesModal({ document }: { document: Document }) {
  const [showModal, setShowModal] = useState(false);
  const { getDocuments } = useDocuments();
  const [newNote, setNewNote] = useState<string>("");
  const action = "Notes";

  useEffect(() => {
    setNewNote("");
  }, [document]);

  const handleSubmit = async () => {
    if (newNote) {
      await axios.post(`/api/documents/addNote/`, { documentId: document.id, note: newNote });
      await getDocuments();
      setShowModal(false);
    }
  };

  return (
    <>
      <DocumentNotes onClick={() => setShowModal(true)} hasnotes={document.notes?.length} />
      {showModal && (
        <ActionSideDraw close={() => setShowModal(false)}>
          <InnerModalNotes style={{ padding: "0 16px" }}>
            <div className="modal-header">
              <div className="modal-title">{action}</div>
              <div className="close-icon" onClick={() => setShowModal(false)}>
                <XIcon />
              </div>
            </div>
            <NewNoteWrap>
              <textarea value={newNote} onChange={(e) => setNewNote(e.target.value)} autoFocus />
              <div className="df aic mt2">
                <GhostButton onClick={() => setShowModal(false)}>Cancel</GhostButton>
                <BaseButton onClick={handleSubmit}>Add Note</BaseButton>
              </div>
            </NewNoteWrap>
            {document.notes.length > 0 &&
              document.notes.map((note, index) => {
                return (
                  <SavedNotes key={index}>
                    <div className="note">{note.note}</div>
                    <div className="author">
                      <div>{note.userName}</div>
                      <div>{format(new Date(note.date), "MM/dd/yyyy h:mm a")}</div>
                    </div>
                  </SavedNotes>
                );
              })}
          </InnerModalNotes>
        </ActionSideDraw>
      )}
    </>
  );
}

const InnerModalNotes = styled(InnerModal)`
  margin-top: 16px;
  text-align: left;
  .header-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 24px;
  }
`;

const SavedNotes = styled.div`
  padding: 10px;
  background-color: #f5f6f8;
  border-radius: 4px;
  color: #000;
  margin-top: 16px;
  .author {
    font-size: 14px;
    color: #00b8d4;
    font-weight: bold;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const NewNoteWrap = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
  button {
    width: 124px;
  }
  textarea {
    border-radius: 4px;
    border: 1px solid #c2c5d3;
    color: #333;
    height: 64px;
    padding: 8px;
    transition: all 0.1s;
    width: 100%;
    outline: none;
    :focus,
    :active {
      border: 1px solid #1f2b5e;
    }
  }
`;
