import styled from "@emotion/styled";

export const Tile = styled.div`
  @media (max-width: 768px) {
    max-width: 100%;
    height: 100vh;
    max-height: 100vh;
    z-index: 50000;
    padding-top: 48px;
    padding-bottom: 48px;
    overflow: hidden;
  }
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 9px 112px rgba(31, 43, 94, 0.03),
    0px 3.75998px 46.7909px rgba(31, 43, 94, 0.0215656),
    0px 2.01027px 25.0167px rgba(31, 43, 94, 0.0178832),
    0px 1.12694px 14.0241px rgba(31, 43, 94, 0.015),
    0px 0.598509px 7.44811px rgba(31, 43, 94, 0.0121168),
    0px 0.249053px 3.09933px rgba(31, 43, 94, 0.00843437);
  color: #1f2b5e;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  height: 100vh;
  justify-content: flex-start;
  line-height: 18px;
  max-width: 500px;
  min-height: 200px;
  overflow: hidden;
  padding: 64px 0px 8px;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  transition: all 0.15s;
  width: 100%;
  z-index: 60;
  svg {
    max-width: 24px;
  }
  .close {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    z-index: 5;
    //doesnt work yet, so hiding it for the time being
    display: none;
    svg {
      margin-bottom: 0;
    }
    :hover {
      svg {
        stroke: #707898;
        stroke-width: 2;
      }
    }
  }
`;

export const InnerModal = styled.div`
  height: calc(100vh - 116px);
  width: 100%;
  position: relative;
  overflow: auto;
  /*  from sendemail */
  .merge-checkbox {
    display: flex;
    align-items: center;
    height: 18px;
    span {
      margin-left: 4px;
    }
  }
  /*  */
  svg {
    margin-bottom: 0;
  }
  .modal-header {
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    display: flex;
    height: 64px;
    justify-content: space-between;
    right: 0;
    max-width: 500px;
    padding: 0 20px;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 10;
    .modal-title {
      font-size: 28px;
      line-height: 1.4;
      text-align: left;
    }
    .close-icon {
      width: 24px;
      max-width: 24px;
      height: auto;
      cursor: pointer;
      transition: all 0.1s;
      :hover {
        g {
          stroke: #000;
        }
      }
    }
  }
  .sort-button {
    background-color: rgba(31, 43, 94, 0.1);
    border-radius: 4px;
    color: #1f2b5e;
    cursor: pointer;
    margin-right: 16px;
    padding: 4px 8px;
    transition: all 0.15s;
    :hover {
      background-color: #1f2b5e;
      color: #fff;
    }
  }
  .list_wrap {
    border-bottom: 1px solid #000;
    max-height: calc(100% - 127px);
    > div {
      padding-left: 16px;
      margin-left: 16px;
      margin-top: 24px;
    }
    .list-header {
      text-align: left;
      text-transform: uppercase;
      color: #333;
      font-weight: bold;
    }
  }
  ol {
    list-style-type: decimal;
    padding: 16px;
    padding-bottom: 24px;
    text-align: left;
    li {
      cursor: pointer;
      margin-top: 6px;
    }
  }
  .email-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    color: #1f2b5e;
    p {
      margin-right: 10px;
    }
    svg {
      max-width: 16px;
      margin-left: 5px;
      path {
        stroke: currentColor;
      }
    }
  }
`;
