import styled from '@emotion/styled';

export const ColumnsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  .list-header.list-header {
    font-size: 16px;
  }
  .uncheck_all {
    font-size: 13px;
  }
  .please-select {
    padding: 24px 16px 32px;
    .please-please {
      font-size: 18px;
    }
  }
  .right-side_options {
    width: calc(100% - 376px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > div {
      border: 1px solid #e9e9e9;
    }
  }
`;

export const RowOne = styled.div`
  width: 360px;
  > div {
    border: 1px solid #e9e9e9;
    border-radius: 4px;
  }
  .selected-header {
    padding: 14px 16px;
  }
  .list-wrap_or {
    max-height: calc(100vh - 246px);
    background-color: #fff;
    border-top: none;
    .draggable-item {
      border: none;
      padding: 4px 0;
      :hover {
        background-color: #f5f5f5;
      }
    }
    .list-items_wrap {
      padding: 8px;
    }
  }
`;

export const EmailListStyles = styled.div`
  text-align: left;
  background-color: #f6f7f9;
  margin-top: 16px;
  border-radius: 4px;
  .contacts-header {
    font-size: 16px;
    font-weight: bold;
    color: #ec7258;
    border-bottom: 1px solid #e9e9e9;
    padding: 16px;
  }
  .contacts-list-wrap {
    padding: 16px;
    overflow: auto;
    max-height: calc(100vh - 387px);
    overflow: auto;
    background-color: #fff;
  }
  .to-cc {
    position: relative;
    left: -2px;
    span {
      font-size: 12px;
      margin-right: 18px;
      margin-left: 4px;
      :last-of-type {
        color: #00b8d4;
        margin-right: 6px;
        margin-left: 0;
      }
    }
  }
`;

export const EmailStyle = styled.div`
  display: flex;
  min-height: 18px;
  margin-bottom: 12px;
  position: relative;
  align-items: center;
  :last-of-type {
    margin-bottom: 0;
  }
  .input-b4fb {
    display: inline-flex;
    align-items: center;
    flex: 0 1 auto;
    input {
      cursor: pointer;
      height: 16px;
      margin-right: 12px;
      width: 16px;
      :last-of-type {
        border: 1px solid red;
        overflow: hidden;
        position: relative;
        ::before {
          border-radius: 2px;
          border: 1px solid #00b8d4;
          content: ' ';
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
        :checked {
          ::before {
            border: 1px solid #0375fe;
          }
        }
      }
    }
  }
  .name {
    color: #1f2b5e;
    font-size: 12px;
    font-weight: bold;
    flex: 1 1 auto;
  }
`;

export const RowTwo = styled.div`
  background-color: #fff;
  border: 1px solid #e9e9e9;
  margin-top: 16px;
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  > p {
    color: #ec7258;
    font-size: 16px;
    font-weight: bold;
    padding: 8px;
    background-color: #f6f7f9;
    flex: 1 0 auto;
    align-items: center;
    display: inline-flex;
  }
  .toggle-dropdown {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 12px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    .email-toggle.email-toggle {
      max-width: 248px;
      margin-top: 0;
      > div {
        margin-right: 0;
      }
      p {
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 4px;
      }
    }
  }
  .select-override {
    max-width: 228px;
    text-align: left;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 16px;
  }
`;

export const buttonWrapOverride = {
  justifyContent: 'space-between',
  paddingLeft: 16,
  '.ds56cr': {
    display: 'flex',
    alignItems: 'center',
  },
};
