import React, { useState } from "react";
import { TitleQButton } from "../../../ui/modalButtons";
import { AddIcon } from "../../../ui/icons";
import AddEditOrderContact from "./AddEditOrderContact";

export default function AddContact() {
  const [addContact, setAddContact] = useState(false);
  const [key, setKey] = useState(0);

  const handleAddContactClick = () => {
    setKey(key + 1);
    setAddContact(true);
  };

  return (
    <>
      <TitleQButton onClick={handleAddContactClick}>
        <AddIcon />
        <span>Add</span>
      </TitleQButton>
      {addContact && <AddEditOrderContact key={key} isEdit={false} />}
    </>
  );
}
