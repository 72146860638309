/** @jsxRuntime classic */
/** @jsx jsx */
/* @jsxFrag React.Fragment */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { moveItem, sort } from "../../../helpers/draggableList";
import { useDocuments } from "../../../Providers/DocumentsProvider";
import { ModalType } from "../../../Providers/types";
import ActionSideDraw from "../../../ui/ActionSideDraw";
import DraggableList from "../../../ui/DraggableList";
import { XIcon, Public } from "../../../ui/icons";
import { BaseButton, ButtonsWrap, GhostButton } from "../../../ui/modalButtons";
import { InnerModal } from "../../../ui/modalStyles";
import Spinner from "../../../ui/Spinner";
import { Document } from "../types";

export default function Copy() {
  const {
    uncheckAllDocuments,
    selectedDocuments,
    getDocuments,
    toggleModal,
    closeModal,
    currentOpenModal,
    handleDocumentCheckbox
  } = useDocuments();
  const [modalDocuments, setModalDocuments] = useState<Document[]>([]);
  const [destinationOrderNumbers, setDestinationOrderNumbers] = useState([]);
  const [isCopyLoading, setIsCopyLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [copiedDocuments, setCopiedDocuments] = useState([]);
  const [copyWeb, setCopyWeb] = useState<boolean>(false);
  const action = "Copy";

  useEffect(() => {
    setModalDocuments(selectedDocuments);
  }, [selectedDocuments]);

  const handleCheckboxChange = (checked: boolean) => {
    setCopyWeb(checked);
  };

  const handleMoveItem = (dragIndex, hoverIndex) => {
    const newList = moveItem(modalDocuments, dragIndex, hoverIndex);
    setModalDocuments(newList);
  };

  const handleSort = (sortASC = true) => {
    const newList = sort(modalDocuments, sortASC);
    setModalDocuments(newList);
  };

  const handleSubmit = async () => {
    setIsCopyLoading(true);
    const payload = {
      sourceIds: modalDocuments.map((d) => d.id),
      destinationOrderNumbers: destinationOrderNumbers.map((x) => x.trim()),
      copyWeb: copyWeb
    };
    await axios.post("/api/documents/copy", payload);
    await getDocuments();
    setIsCopyLoading(false);
    setIsSuccess(true);
    setCopiedDocuments([...modalDocuments]);
  };

  const closeSuccessModal = () => {
    setIsSuccess(false);
    uncheckAllDocuments();
    closeModal();
  };

  const multipleDocs = modalDocuments.length > 1;
  const multipleOrders = destinationOrderNumbers.length > 1;
  return (
    <React.Fragment>
      <div onClick={() => toggleModal(ModalType.Copy)} className="file-action-button">
        {action}
      </div>
      {currentOpenModal === ModalType.Copy && (
        <ActionSideDraw close={closeModal}>
          {!isSuccess && (
            <Fragment>
              <InnerModalCopy>
                <div className="modal-header">
                  <div className="modal-title">Duplicate to Another File</div>
                  <div className="close-icon" onClick={closeModal}>
                    <XIcon />
                  </div>
                </div>
                {modalDocuments.length > 0 && (
                  <Fragment>
                    <div css={{ marginBottom: 40, textAlign: "left", "> div": { color: "#1f2b5e", marginBottom: 4 } }}>
                      <div>* required</div>
                      <input
                        type="text"
                        value={destinationOrderNumbers}
                        onChange={(e) => setDestinationOrderNumbers(e.target.value.split(","))}
                        placeholder="Please list file #s separated by a comma"
                        autoFocus
                        className="text-field_copy"
                      />
                    </div>
                    <ConfirmCopy>
                      <input
                        onChange={(e) => handleCheckboxChange(e.target.checked)}
                        checked={copyWeb}
                        type="checkbox"
                      />
                      <div className="public-copy-text">
                        <span>Documents that are marked as public - &nbsp; </span>
                        <Public ispublic={true.toString()} /> &nbsp; - &nbsp; <span> should be copied as public.</span>
                      </div>
                    </ConfirmCopy>
                  </Fragment>
                )}
                <DraggableList
                  items={modalDocuments}
                  moveItem={handleMoveItem}
                  handleSort={(sortASC) => handleSort(sortASC)}
                  handleDelete={(item) => handleDocumentCheckbox(false, item)}
                  handleDeleteAll={uncheckAllDocuments}
                />
              </InnerModalCopy>
              <ButtonsWrap>
                <GhostButton onClick={closeModal}>Cancel</GhostButton>
                <BaseButton onClick={handleSubmit} isLoading={isCopyLoading} isDisabled={modalDocuments.length === 0}>
                  <Spinner local={true} display={isCopyLoading} />
                  {action}
                </BaseButton>
              </ButtonsWrap>
            </Fragment>
          )}

          {isSuccess && (
            <InnerModalSuccess>
              <ConfirmModal>
                <div className="confirm-tile">
                  <h1>Success!</h1>
                  <div className="message-38dhe">{`Selected document${multipleDocs ? "s" : ""}`}</div>
                  <div className="selectedocs">
                    {copiedDocuments.map((i, index) => (
                      <div key={index}>{i.documentDescription}</div>
                    ))}
                  </div>
                  <div className="message-38dhe">
                    {`${multipleDocs ? "Have" : "Has"} been successfully copied to the following order${
                      multipleOrders ? "s" : ""
                    }:`}
                  </div>
                  <div className="link-list">
                    {destinationOrderNumbers.map((orderNumber, index) => (
                      <a key={index} href={`/new-home/${orderNumber.trim()}`} target="_blank" rel="noopener noreferrer">
                        {orderNumber.trim()}
                        <br />
                      </a>
                    ))}
                  </div>
                  <GhostButton className="close-it" onClick={closeSuccessModal}>
                    Close
                  </GhostButton>
                </div>
              </ConfirmModal>
            </InnerModalSuccess>
          )}
        </ActionSideDraw>
      )}
    </React.Fragment>
  );
}

const InnerModalCopy = styled(InnerModal)`
  padding: 20px;
  .text-field_copy {
    border-radius: 4px;
    border: 1px solid #c2c5d3;
    color: #333;
    height: 54px;
    padding: 8px;
    transition: all 0.1s;
    width: 100%;
    outline: none;
    :focus,
    :active {
      border: 1px solid #1f2b5e;
    }
  }
  .list-wrap_or {
    max-height: calc(100vh - 430px);
  }
`;

const ConfirmCopy = styled.div`
  border: 1px solid rgb(238, 114, 90);
  border-radius: 4px;
  display: flex;
  align-items: center;
  line-height: 1.5;
  padding: 16px;
  font-size: 16px;
  text-align: left;
  align-items: flex-start;
  margin-bottom: 24px;
  input {
    height: 32px;
    width: 32px;
    margin-right: 16px;
    cursor: pointer;
    :hover {
      outline: blue;
    }
  }
  .public-copy-text {
    display: flex;
    flex-wrap: wrap;
  }
`;

const InnerModalSuccess = styled(InnerModal)`
  align-items: center;
  display: flex;
  padding: 20px;
`;

const ConfirmModal = styled.div<{ isConfirm?: boolean }>`
  width: 100%;
  .confirm-tile {
    background-color: #fff;
    border-radius: 5px;
    box-shadow:
      0px 9px 112px rgba(31, 43, 94, 0.03),
      0px 3.75998px 46.7909px rgba(31, 43, 94, 0.0215656),
      0px 2.01027px 25.0167px rgba(31, 43, 94, 0.0178832),
      0px 1.12694px 14.0241px rgba(31, 43, 94, 0.015),
      0px 0.598509px 7.44811px rgba(31, 43, 94, 0.0121168),
      0px 0.249053px 3.09933px rgba(31, 43, 94, 0.00843437);
    height: auto;
    padding: 24px;
    text-align: center;
    width: 100%;
    h1 {
      color: #3aa413;
      font-size: 20px;
      margin-bottom: 24px;
    }
    .selectedocs {
      background-color: #f9f9f9;
      border-radius: 4px;
      line-height: 1.6;
      margin-bottom: 24px;
      padding: 16px;
      text-align: left;
      > div {
        display: list-item;
        list-style-type: number;
        list-style-position: inside;
      }
    }
    .message-38dhe {
      /* max-width: 75%; */
      margin: 0 auto 8px;
      line-height: 1.6;
      text-align: left;
    }
    .link-list {
      background-color: #f9f9f9;
      border-radius: 4px;
      margin: 8px 0 24px;
      padding: 16px;
      text-align: left;
      a {
        color: #00b8d4;
        height: 24px;
        :hover {
          color: #1f2b5e;
        }
      }
    }
    .close-it {
      margin-left: auto;
    }
  }
`;
