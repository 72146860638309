/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useRef, useState } from "react";
import useOnClickOutside from "../../hooks/use-on-outside-click";
import { useDocuments } from "../../Providers/DocumentsProvider";
import { useOrder } from "../../Providers/OrderProvider";
import { ListIcon, Mail, SearchGlassIcon, TabIcon, XIcon } from "../../ui/icons";
import { NotesBar } from "../new-home/NotesBar";
import {
  ActionBarWrap,
  DateCategoryToggle,
  ImportantNote,
  ImportantNoteWrap,
  OrderSearchWrap,
  RepWrap,
  SearchWrap,
  SwitchViewWrap
} from "./actionsBarStyles";
import MergePrint from "./selected-file-actions/MergePrint";
import { ButtonGroup } from "../../ui/ButtonGroup";
import { NavButton } from "./navbarStyles";
import SelectedFileActions from "./selected-file-actions";
import { UploadModal } from "./UploadModal";

export default function ActionBar({
  setTabView,
  tabView,
  setListView,
  hideSide
}: {
  tabView: any;
  setTabView?: any;
  setListView?: any;
  hideSide?: boolean;
}) {
  const notesBarRef = useRef();
  const notesButtonRef = useRef(null);
  const contactsButtonRef = useRef(null);
  const [infoBarState, setInfoBarState] = useState({
    shown: false,
    startWithContacts: true
  });
  const {
    searchText,
    setSearchText,
    handleSearchInputChange,
    documentViewMode,
    setDocumentViewMode,
    setIsTabView,
    checkAllDocuments,
    uncheckAllDocuments,
    areAllChecked
  } = useDocuments();
  const { order } = useOrder();

  const { setIsNotesBarSectionOpen } = useDocuments();
  setIsNotesBarSectionOpen(infoBarState.shown);

  const noted = order.notes.filter((n) => n.displayOnOrderOpen === true);

  useOnClickOutside(
    notesBarRef,
    () => setInfoBarState({ ...infoBarState, shown: false }),
    notesButtonRef,
    contactsButtonRef
  );

  const onCheckAllClick = () => {
    if (areAllChecked()) {
      uncheckAllDocuments();
    } else {
      checkAllDocuments();
    }
  };

  const onContactsButtonClick = () => {
    let { shown, startWithContacts } = infoBarState;
    if (!shown) {
      setInfoBarState({ shown: true, startWithContacts: true });
      return;
    }
    if (startWithContacts) {
      setInfoBarState({ shown: false, startWithContacts: true });
      return;
    }
    setInfoBarState({ shown: true, startWithContacts: true });
  };

  const onNotesButtonClick = () => {
    let { shown, startWithContacts } = infoBarState;
    if (!shown) {
      setInfoBarState({ shown: true, startWithContacts: false });
      return;
    }
    if (!startWithContacts) {
      setInfoBarState({ shown: false, startWithContacts: false });
      return;
    }
    setInfoBarState({ shown: true, startWithContacts: false });
  };

  return (
    <div css={{ flex: "0 1 auto" }}>
      <ImportantNoteWrap>
        {noted.map((n, index) => (
          <ImportantNote key={index}>{n.text}</ImportantNote>
        ))}
      </ImportantNoteWrap>
      <ActionBarWrap hideSide={hideSide} allChecked={areAllChecked()}>
        <div className="top-row">
          <div className="left-side">
            {order && order.accountRep && (
              <a
                href={`mailto:${order?.accountRep.email}?subject=${encodeURIComponent(order?.emailSubjectLine)}`}
                className="account-rep_override"
              >
                <RepWrap>{order?.accountRep.fullName}</RepWrap>
              </a>
            )}
            <OrderSearch />
          </div>
          <div className="middle">{order.titleNumber}</div>
          <div className="mobile-merge-print">
            <MergePrint />
          </div>
          <div className="right-side">
            <DateCategoryToggle>
              <div
                onClick={() => setDocumentViewMode("category")}
                className={documentViewMode !== "date" ? "selected-item" : ""}
              >
                Category
              </div>
              <div
                className={documentViewMode === "date" ? "selected-item" : ""}
                onClick={() => {
                  setDocumentViewMode("date");
                  setIsTabView(false);
                }}
              >
                Date
              </div>
            </DateCategoryToggle>
            <SearchWrap>
              <SearchGlassIcon />
              <input value={searchText} onChange={handleSearchInputChange} type="text" placeholder="Search Documents" />
              {searchText?.length > 0 && <XIcon width="100%" height="100%" onClick={() => setSearchText("")} />}
            </SearchWrap>
          </div>
        </div>

        <div className="bottom-row">
          <ButtonGroup allChecked={areAllChecked()}>
            <div className="active-button buttonGroup-button" onClick={onCheckAllClick}>
              {areAllChecked() ? "Uncheck all" : "Check All"}
            </div>
            <div className="buttonGroup-button" ref={contactsButtonRef} onClick={onContactsButtonClick}>
              Contacts
            </div>
            <div className="buttonGroup-button" ref={notesButtonRef} onClick={onNotesButtonClick}>
              Notes
            </div>
            <a
              className="buttonGroup-button"
              href={order ? `sp-select://./ordertracking/order?id=${order.guid}` : ""}
              title="Open in Select"
            >
              Open in Select
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={order ? `https://momentum-geshikt.azurewebsites.net/ship/${order.orderNumber}` : ""}
              title="shipping link"
              className="buttonGroup-button"
            >
              Shipping
            </a>
            <div className="buttonGroup-button">
              <UploadModal />
            </div>
          </ButtonGroup>

          {documentViewMode === "category" && (
            <SwitchViewWrap tabView={tabView}>
              <div onClick={setListView} className={tabView ? "" : "current-view"}>
                <ListIcon />
              </div>
              <div onClick={setTabView} className={tabView ? "current-view" : ""}>
                <TabIcon className="tabview-rotate" />
              </div>
            </SwitchViewWrap>
          )}
          <SelectedFileActions />
        </div>
      </ActionBarWrap>

      <div ref={notesBarRef}>
        <NotesBar
          startWithContacts={infoBarState.startWithContacts}
          isOpen={infoBarState.shown}
          close={() => setInfoBarState({ ...infoBarState, shown: false })}
          setClose={() => setInfoBarState({ ...infoBarState, shown: false })}
        />
      </div>
    </div>
  );
}

export function OrderSearch() {
  const [openSearch, setOpenSearch] = useState(false);
  const inputElementRef = useRef(null);
  const searchDivRef = useRef(null);
  const [searchOrderNumber, setSearchOrderNumber] = useState("");

  useOnClickOutside(searchDivRef, () => setOpenSearch(false));
  const onSearchIconHover = () => {
    setOpenSearch(true);
    inputElementRef.current.focus();
  };

  const handleSearchOrderEnterPress = () => {
    let link = document.createElement("a");
    link.setAttribute("href", `/new-home/${searchOrderNumber}`);
    link.setAttribute("target", "_blank");
    link.click();
    setOpenSearch(false);
  };

  return (
    <OrderSearchWrap openSearch={openSearch} ref={searchDivRef} className="order-search_wrap-syles">
      <NavButton className="order-search_icon" onMouseEnter={onSearchIconHover} onClick={onSearchIconHover}>
        <SearchGlassIcon />
      </NavButton>
      <div className="order-search_search">
        <input
          ref={inputElementRef}
          type="text"
          placeholder="enter order # to open"
          value={searchOrderNumber}
          onChange={(e) => setSearchOrderNumber(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSearchOrderEnterPress();
            }
          }}
        />
      </div>
    </OrderSearchWrap>
  );
}
