const map = new Map();
map.set("AL", 1);
map.set("AK", 2);
map.set("AZ", 3);
map.set("AR", 4);
map.set("CA", 5);
map.set("CO", 6);
map.set("CT", 7);
map.set("DC", 8);
map.set("DE", 9);
map.set("FL", 10);
map.set("GA", 11);
map.set("HI", 12);
map.set("ID", 13);
map.set("IL", 14);
map.set("IN", 15);
map.set("IA", 16);
map.set("KS", 17);
map.set("KY", 18);
map.set("LA", 19);
map.set("ME", 20);
map.set("MD", 21);
map.set("MA", 22);
map.set("MI", 23);
map.set("MN", 24);
map.set("MS", 25);
map.set("MO", 26);
map.set("MT", 27);
map.set("NE", 28);
map.set("NV", 29);
map.set("NH", 30);
map.set("NJ", 31);
map.set("NM", 32);
map.set("NY", 33);
map.set("NC", 34);
map.set("ND", 35);
map.set("OH", 36);
map.set("OK", 37);
map.set("OR", 38);
map.set("PA", 39);
map.set("RI", 40);
map.set("SC", 41);
map.set("SD", 42);
map.set("TN", 43);
map.set("TX", 44);
map.set("UT", 45);
map.set("VT", 46);
map.set("VA", 47);
map.set("WA", 48);
map.set("WV", 49);
map.set("WI", 50);
map.set("WY", 51);

const buildUrl = (state: string) => {
  const id = map.get(state);
  return id
    ? `https://madisonintel.com/search/states/${id}/filter/Contacts`
    : "https://madisonintel.com/search/states/no_state/filter/Contacts";
};

export default buildUrl;
