import React, { useCallback, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import { debounce } from "lodash";
import { useOrder } from "../../../Providers/OrderProvider";
import GovernmentContactCard from "./GovernmentContactCard";
import { transformGovernmentContactToContact } from "../../../helpers/mappers";
import { ContactOptionWrap } from "./phonebookContactStyles";
import { Contact, IGovernmentContact } from "../../../Providers/types";

export default function GovernmentContact({
  contact,
  setContact
}: {
  contact: Contact;
  setContact: React.Dispatch<React.SetStateAction<Contact>>;
}) {
  const { order } = useOrder();

  const [options, setOptions] = useState<Contact[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchedContact, setSearchedContact] = useState<string>(null);

  const searchGovernmentContacts = async (text) => {
    if (!text || text.length < 3) {
      setOptions([]);
      setIsLoading(false);
      return;
    }
    const { data } = await axios.get<IGovernmentContact[]>(
      `/api/orders/searchGovernmentContacts?searchTerm=${text}&state=${order?.stateCode || ""}`
    );
    const contacts = data?.map(transformGovernmentContactToContact);
    setOptions(contacts || []);
    setIsLoading(false);
  };

  const debouncedOnContactSearchChange = useCallback(debounce(searchGovernmentContacts, 500), []);

  const handleInputChange = async (event: React.SyntheticEvent, newInputValue: string, reason: string) => {
    setSearchedContact(newInputValue);
    if (reason === "reset") {
      return;
    }
    ///We only want to search the options if the user entered the option and not selected a the autocomplete of a partially typed option
    if (reason == "input") {
      setIsLoading(true);
      await debouncedOnContactSearchChange(newInputValue);
    }
  };

  const handleChange = async (e, value) => {
    if (typeof value === "object") {
      setContact((c) => ({ ...value, code: c.code }));
    }
  };

  return (
    <>
      <div className="df full-width mt5 jcsb">
        <GovernmentContactCard contact={contact} />
        <Autocomplete
          sx={{ marginLeft: 2 }}
          autoHighlight
          disableClearable
          multiple={false}
          fullWidth
          loading={isLoading}
          placeholder="Start typing..."
          value={contact}
          inputValue={searchedContact}
          options={options}
          onInputChange={handleInputChange}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} label="Contact" placeholder="Start typing..." />}
          getOptionLabel={(option) => option.name || ""}
          renderOption={(props, option) => {
            return (
              <ContactOptionWrap {...props} key={option?.lookupCode}>
                <div className="top_row">
                  <div className="bold">{`${option?.name || ""}`}</div>
                  <div className="gray">{option?.email && `${option?.email || ""}`}</div>
                </div>
                <div className="bottom_row">
                  <div>
                    <div>{option?.address1}</div>
                    <div>{option?.phone}</div>
                  </div>
                  <div className="df col aife">
                    <div>{option?.company}</div>
                    {option?.company && <div className="gray italic">Company</div>}
                  </div>
                </div>
              </ContactOptionWrap>
            );
          }}
        />
      </div>
    </>
  );
}
