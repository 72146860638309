import React, { useState, useContext, useEffect } from "react";
import axios from "axios";

interface UserContextType {
  userEmail: string;
}

const OrderContext = React.createContext<UserContextType | null>(null);

interface UserProviderProps {
  children: React.ReactNode;
}

function UserProvider({ children }: UserProviderProps) {
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    const getUserData = async () => {
      const { data } = await axios.get("/login/getuserpersonalinfo");
      if (data.email) {
        setUserEmail(data.email.toLowerCase());
      }
    };

    (async () => {
      await getUserData();
    })();
  }, []);

  const value: UserContextType = {
    userEmail
  };

  return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>;
}

function useUser() {
  return useContext(OrderContext);
}

export { UserProvider, useUser };
