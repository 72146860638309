import * as React from "react";
import AppSwitcherLinks from "./app-switcher-links";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";

type AppSwitcherProps = {
  isOpen: boolean;
  onCloseClick: () => void;
  side?: "left" | "right";
  state?: string;
};

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250
  }
}));

const AppSwitcher = ({ isOpen, onCloseClick, side, state }: AppSwitcherProps) => {
  const classes = useStyles({});

  return (
    <Drawer anchor={side} open={isOpen} onClose={onCloseClick}>
      <div className={classes.list} role="presentation">
        <AppSwitcherLinks state={state} />
      </div>
    </Drawer>
  );
};

export default AppSwitcher;
