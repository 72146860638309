import { Alert, Box, Button, Snackbar, Stack, Typography } from "@mui/material";
import React from "react";

export default function PatriotSearchAlert({
  showPatriotSearchResultsAlert,
  setShowPatriotSearchResultsAlert,
  patriotSearchResult
}: {
  showPatriotSearchResultsAlert: boolean;
  setShowPatriotSearchResultsAlert: React.Dispatch<React.SetStateAction<boolean>>;
  patriotSearchResult: string[];
}) {
  const getNames = () => {
    if (!patriotSearchResult) {
      return "";
    }
    return patriotSearchResult.map((name, index) => `${index + 1}) ${name}`).join(", ");
  };
  return (
    <Alert severity="error">
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography variant="body2">
          Patriot search matches found: {getNames()}!!{" "}
          <span style={{ fontWeight: "bold", color: "red" }}>DO NOT CONTINUE </span>
          transaction until confirmed to be not a match.
        </Typography>
        <Box>
          <Button
            color="error"
            variant="contained"
            size="small"
            onClick={() => setShowPatriotSearchResultsAlert(false)}
            sx={{ textTransform: "unset", width: 100 }}
          >
            I understand
          </Button>
        </Box>
      </Stack>
    </Alert>
  );
}
