import * as React from "react";
import axios from "axios";
import grey from "@material-ui/core/colors/grey";
import styled from "@emotion/styled";
import { makeStyles } from "@material-ui/core/styles";
import AppsIcon from "@material-ui/icons/Apps";
import Avatar from "@material-ui/core/Avatar";
import AppSwitcher from "./app-switcher";
import { Root } from "../pages/new-home/navbarStyles";
import { Logo } from "../ui/icons";
import "../ui/styles.css";

const { useState, useEffect } = React;

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1
  },
  appBar: {
    backgroundColor: "#fff",
    borderBottom: "1px solid #ddd",
    boxShadow: "none",
    boxSizing: "border-box"
  },
  appWrap: {
    backgroundColor: "#FAFAFA",
    boxSizing: "border-box",
    minHeight: "calc(100vh - 66px)",
    padding: theme.spacing(3),
    overflow: "auto"
  },
  beta: {
    fontSize: 12,
    position: "absolute",
    right: -28
  },
  avatar: {
    backgroundColor: "#fff",
    color: grey[900],
    height: 32,
    width: 32,
    fontSize: ".9rem",
    marginLeft: 12,
    "@media(min-width: 900px)": {
      marginTop: 12,
      marginLeft: 0,
      height: 48,
      width: 48,
      fontSize: "1.2rem"
    }
  }
}));

export default function ButtonAppBar({ children }: any) {
  const [initials, setInitials] = useState("");
  const [isAppSwitcherOpen, setIsAppSwitcherOpen] = React.useState(false);

  useEffect(() => {
    const getInitials = async () => {
      const { data } = await axios.get("/login/GetInitials");
      setInitials(data.initials);
    };
    getInitials();
  }, []);

  const classes = useStyles({});
  return (
    <RootStyles>
      <NavBarStyles>
        <div className="inner-wrap">
          <a href="/" className="momentum-logo">
            <Logo />
          </a>
          <div className="user-utils">
            <AppsIcon color="inherit" className="pointer" onClick={() => setIsAppSwitcherOpen(true)} />
            <Avatar className={classes.avatar}>{initials}</Avatar>
          </div>
        </div>
      </NavBarStyles>
      <AppSwitcher side="left" isOpen={isAppSwitcherOpen} onCloseClick={() => setIsAppSwitcherOpen(false)} />
      <div className="content_wrap">{children}</div>
    </RootStyles>
  );
}

const RootStyles = styled.div`
  background-color: #f8f8fc;
  display: flex;
  min-height: 100vh;
  padding-top: 56px;
  .navbar_override {
    flex: 0 1 auto;
  }
  > .content_wrap {
    flex: 1 1 auto;
    max-height: calc(100vh - 60px);
    padding: 0 8px 8px;
  }
  @media (min-width: 900px) {
    flex-direction: row;
    padding-top: 0;
    > .content_wrap {
      margin-left: 72px;
      max-height: 100vh;
      max-width: calc(100% - 72px);
      padding: 0 16px;
      width: 100%;
    }
  }
`;

const NavBarStyles = styled(Root)`
  height: unset;
  box-sizing: border-box;
  .inner-wrap {
    justify-content: space-between;
    width: 100%;
  }
  .user-utils {
    align-items: center;
    display: inline-flex;
    height: 56px;
    svg {
      max-height: 32px;
      width: auto;
    }
  }
  @media (min-width: 900px) {
    padding-bottom: 16px;
    .inner-wrap {
      height: 100%;
      justify-content: space-between;
    }
    .user-utils {
      flex-direction: column;
      height: auto;
      width: 100%;
      svg {
        max-height: 40px;
      }
    }
  }
`;
