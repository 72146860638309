/** @jsxRuntime classic */
/* @jsxFrag React.Fragment */
import { jsx, css, keyframes } from '@emotion/react'; // eslint-disable-line
import React from 'react';
import styled from '@emotion/styled';

export default function Spinner({ local, display }) {
  return (
    <>
      {local ? (
        <JustSpinner shouldDisplay={display}>
          <div>
            <div className="typing_loader"></div>
          </div>
        </JustSpinner>
      ) : (
        <WithOverlay className="with-overlay-override">
          <div className="help"></div>
        </WithOverlay>
      )}
    </>
  );
}

const typing = keyframes`
      0% {
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.2), 24px 0px 0px 0px rgba(255, 255, 255, 0.2);
    }
    25% {
      background-color: rgba(255, 255, 255, 0.4);
      box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 2), 24px 0px 0px 0px rgba(255, 255, 255, 0.2);
    }
    75% {
      background-color: rgba(255, 255, 255, 0.4);
      box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.2), 24px 0px 0px 0px rgba(255, 255, 255, 1);
    }
`;

const rotation = keyframes`
  0%{transform: rotate(0deg);}
  100%{transform: rotate(360deg);}
`;

const WithOverlay = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 606;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  .help {
    width: 100px;
    position: relative;
    height: 100px;
    border: 1px #fff solid;
    border-radius: 50%;
    animation: ${rotation} 1s ease-in-out infinite;
    z-index: 2;
  }
  .help:before,
  .help:after {
    top: 16px;
    width: 16px;
    height: 16px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 100%;
    position: absolute;
    content: '';
    z-index: 3;
  }
  .help:before {
    /* background-color: blue; */
    width: 20px;
    height: 20px;
    top: 14px;
    z-index: 1;
    left: -2px;
  }
`;

const JustSpinner = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: ${(props) => (props.shouldDisplay ? 'flex' : 'none')};
  z-index: 260;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  > div {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
  }
  .typing_loader {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    animation: ${typing} 1s linear infinite alternate;
    position: relative;
    left: -12px;
  }
`;
