import styled from "@emotion/styled";
import { format } from "date-fns";
import React from "react";

export default function OrderRow({ order }) {
  const {
    orderNumber,
    titleNumber,
    orderDate,
    address,
    orderStatus,
    cityStateZip,
    buyer,
    client,
    transactionType,
    createdBy
  } = order;
  return (
    <Root>
      <a href={`/new-home/${orderNumber}`} className="order-number">
        {titleNumber}
      </a>
      <div className="client">{client}</div>
      <div className="buyer">{buyer}</div>
      <div className="address">
        {address}
        <br />
        {cityStateZip}
      </div>
      <div className="status">
        <div>{transactionType}</div>
        <div className={orderStatus.charAt(0).toLowerCase() + orderStatus.slice(1)}>{orderStatus}</div>
      </div>
      <div className="date">{format(new Date(orderDate), "MM/dd/yyyy h:mm a")}</div>
      <div className="client">{createdBy}</div>
    </Root>
  );
}

const Root = styled.div`
  align-items: center;
  border-top: 1px solid #eee;
  display: grid;
  grid-column-gap: 12px;
  grid-template-columns: 8fr 10fr 10fr 10fr 7fr 7fr 6fr;
  padding: 10px 24px;
  :first-of-type,
  :nth-of-type(2) {
    border-top: none;
  }
  .order-number {
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    color: #4440db;
    cursor: pointer;
  }
  .address {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }
  .buyer,
  .client {
    color: #2e2e36;
    font-size: 14px;
    font-weight: 500;
  }
  .status {
    align-items: center;
    display: flex;
    > div {
      align-items: center;
      background: rgba(68, 64, 219, 0.1);
      border-radius: 4px;
      color: #4440db;
      display: flex;
      font-size: 13px;
      font-weight: 600;
      height: 24px;
      justify-content: center;
      letter-spacing: 0.05em;
      line-height: 20px;
      margin-right: 8px;
      padding: 0 10px;
      text-transform: uppercase;
      :last-of-type {
        margin-right: 0;
      }
    }
    .new {
      background-color: #39b54a;
      color: #fff;
    }
    .canceled {
      background-color: #be2026;
      color: #fff;
    }
    .commitment {
      background-color: lightblue;
      color: blue;
      letter-spacing: 0.03em;
    }
  }
  .date {
    color: #818795;
    color: #000;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }
`;
