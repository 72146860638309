import { Order } from "../types/home/order";
const STORAGE_KEY = "order-history";
const MAX_HISTORY_SIZE = 10;

const addToHistory = (order: Order) => {
  let existingOrders = getAllFromHistory()
    .filter((o) => o.orderNumber !== order.orderNumber)
    .slice(0, MAX_HISTORY_SIZE - 1);
  saveToLocalStorage([order, ...existingOrders]);
};

const getAllFromHistory = (): Array<Order> => {
  const json = localStorage.getItem(STORAGE_KEY);
  if (!json) {
    return [];
  }
  return JSON.parse(json);
};

//private functions
const saveToLocalStorage = (orders: Array<Order>) => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(orders));
};

export { addToHistory, getAllFromHistory };
