/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { moveItem, sort } from "../../../helpers/draggableList";
import { useDocuments } from "../../../Providers/DocumentsProvider";
import { useOrder } from "../../../Providers/OrderProvider";
import { ModalType, possibleEndPointsTypes } from "../../../Providers/types";
import ActionSideDraw from "../../../ui/ActionSideDraw";
import DraggableList from "../../../ui/DraggableList";
import Dropdown from "../../../ui/Dropdown";
import { Attachment, Link, XIcon } from "../../../ui/icons";
import { BaseButton, ButtonsWrap, GhostButton } from "../../../ui/modalButtons";
import { InnerModal } from "../../../ui/modalStyles";
import Spinner from "../../../ui/Spinner";
import { Toggle } from "../../../ui/toggle";
import { Document } from "../types";
import { buttonWrapOverride, EmailListStyles, EmailStyle, ColumnsWrap, RowOne, RowTwo } from "./mergeEmailStyles";

type AttachmentType = "Attachment" | "Link";

export default function MergeEmail() {
  const [attachmentType, setAttachmentType] = useState<AttachmentType>("Attachment");
  const [shouldMerge, setShouldMerge] = useState(false);
  const {
    selectedDocuments,
    emailSendingHandler,
    toggleModal,
    closeModal,
    currentOpenModal,
    handleDocumentCheckbox,
    uncheckAllDocuments
  } = useDocuments();
  const [modalDocuments, setModalDocuments] = useState<Document[]>([]);
  const [contactsTo, setContactsTo] = useState<string[]>([]);
  const [contactsCc, setContactsCc] = useState<string[]>([]);
  const [bodyTemplate, setBodyTemplate] = useState("None");
  const [isEmailSubmitLoading, setIsEmailSubmitLoading] = useState(false);
  const [isMergeEmailSubmitLoading, setIsMergeEmailSubmitLoading] = useState(false);
  const { order } = useOrder();
  const action = "Email";

  useEffect(() => {
    if (currentOpenModal === ModalType.MergeEmail) {
      setShouldMerge(false);
      setContactsTo([]);
      setContactsCc([]);
      setBodyTemplate("None");
    }
  }, [currentOpenModal]);

  useEffect(() => {
    setModalDocuments(selectedDocuments);
  }, [selectedDocuments]);

  const handleMoveItem = (dragIndex, hoverIndex) => {
    const newList = moveItem(modalDocuments, dragIndex, hoverIndex);
    setModalDocuments(newList);
  };

  const handleSort = (sortASC = true) => {
    const newList = sort(modalDocuments, sortASC);
    setModalDocuments(newList);
  };

  function handleCheckboxChange(checked: boolean, email: string, isCc: boolean) {
    let contacts1 = isCc ? [...contactsCc] : [...contactsTo];
    let contacts2 = isCc ? [...contactsTo] : [...contactsCc];
    if (checked) {
      contacts1.push(email);
      contacts2 = contacts2.filter((selected) => selected !== email);
      isCc ? setContactsTo(contacts2) : setContactsCc(contacts2);
    } else {
      contacts1 = contacts1.filter((selected) => selected !== email);
    }
    isCc ? setContactsCc(contacts1) : setContactsTo(contacts1);
  }

  const onEmailClick = async () => {
    setIsEmailSubmitLoading(true);
    await handleSubmit();
  };

  const onEmailMergeClick = async () => {
    setShouldMerge(true);
    setIsMergeEmailSubmitLoading(true);
    await handleSubmit(true);
  };

  const handleSubmit = async (merge = shouldMerge) => {
    let endpointType: possibleEndPointsTypes;

    switch (bodyTemplate) {
      case "None":
        endpointType = attachmentType === "Link" ? "documentAsLink" : "documentAsAttachment";
        break;
      case "E-Commitment":
        endpointType = attachmentType === "Link" ? "commitmentAsLink" : "commitmentAsAttachment";
        break;
      case "E-Confirmation":
        endpointType = attachmentType === "Link" ? "confirmationAsLink" : "confirmationAsAttachment";
        break;
    }

    await emailSendingHandler(
      {
        merge,
        documents: modalDocuments,
        contactEmails: contactsTo.map((e) => e.trim()),
        contactEmailsCc: contactsCc.map((e) => e.trim()),
        firstContact: {
          name: order.contacts.length ? order.contacts[0].name : undefined,
          lookupCode: order.contacts.length ? order.contacts[0].lookupCode : undefined
        },
        subject: order.emailSubjectLine,
        titleUrl: order.contacts.find((c) => c.code === "T")?.url
      },
      endpointType
    );
    setIsEmailSubmitLoading(false);
    setIsMergeEmailSubmitLoading(false);
    if (!merge) {
      uncheckAllDocuments();
    }
    closeModal();
  };

  return (
    <React.Fragment>
      <div onClick={() => toggleModal(ModalType.MergeEmail)} className="file-action-button">
        {action}
      </div>
      {currentOpenModal === ModalType.MergeEmail && (
        <React.Fragment>
          <ActionSideDraw close={closeModal} tileStyles={{ maxWidth: 720 }}>
            <InnerModal css={{ padding: "0 16px", " .modal-header": { maxWidth: 720 } }}>
              <div className="modal-header">
                <div className="modal-title">{action}</div>
                <div className="close-icon" onClick={closeModal}>
                  <XIcon />
                </div>
              </div>
              <ColumnsWrap>
                <RowOne>
                  <DraggableList
                    displayMerge={bodyTemplate === "None"}
                    items={modalDocuments}
                    moveItem={handleMoveItem}
                    handleSort={(sortASC) => handleSort(sortASC)}
                    doMerge={shouldMerge}
                    handleMerge={() => setShouldMerge(!shouldMerge)}
                    handleDelete={(item) => handleDocumentCheckbox(false, item)}
                    handleDeleteAll={uncheckAllDocuments}
                  />
                </RowOne>
                <div className="right-side_options">
                  <EmailListStyles>
                    <div className="contacts-header">Email Contacts</div>
                    <div className="contacts-list-wrap">
                      <div className="to-cc">
                        <span>To</span>
                        <span>Cc</span>
                      </div>
                      {order &&
                        order.contacts
                          .filter((c) => c.email)
                          .map((c, index) => (
                            <EmailOption
                              index={index}
                              email={c.email}
                              name={c.name}
                              contactsTo={contactsTo}
                              contactsCc={contactsCc}
                              type={c.type}
                              handleCheckboxChange={handleCheckboxChange}
                            />
                          ))}
                      {order.accountRep && (
                        <EmailOption
                          email={order.accountRep.email}
                          name={order.accountRep.fullName}
                          contactsTo={contactsTo}
                          contactsCc={contactsCc}
                          type={"Account Rep"}
                          handleCheckboxChange={handleCheckboxChange}
                        />
                      )}
                    </div>
                  </EmailListStyles>
                  <RowTwo>
                    <p>Email Details</p>
                    <div className="toggle-dropdown">
                      <Dropdown
                        label="Email Template"
                        items={["None", "E-Commitment", "E-Confirmation"]}
                        onClick={setBodyTemplate}
                        value={bodyTemplate}
                      />
                      <div className="email-toggle">
                        <Toggle
                          width="110px"
                          onClick={() => setAttachmentType(attachmentType === "Link" ? "Attachment" : "Link")}
                          toggled={attachmentType === "Link"}
                        >
                          <div>
                            Attachment <Attachment />
                          </div>
                          <div>
                            Link <Link />
                          </div>
                        </Toggle>
                      </div>
                    </div>
                  </RowTwo>
                </div>
              </ColumnsWrap>
            </InnerModal>
            <ButtonsWrap css={buttonWrapOverride}>
              <GhostButton onClick={() => closeModal()}>Cancel</GhostButton>
              <div className="ds56cr">
                <BaseButton onClick={onEmailClick}>
                  <Spinner local={true} display={isEmailSubmitLoading} />
                  {action}
                </BaseButton>
                <BaseButton
                  onClick={onEmailMergeClick}
                  isLoading={isMergeEmailSubmitLoading}
                  isDisabled={modalDocuments.length === 0}
                >
                  <Spinner local={true} display={isMergeEmailSubmitLoading} />
                  Merge And Email
                </BaseButton>
              </div>
            </ButtonsWrap>
          </ActionSideDraw>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

function EmailOption({
  index,
  email,
  name,
  contactsTo,
  contactsCc,
  handleCheckboxChange,
  type
}: {
  index?: number;
  email: string;
  name: string;
  contactsTo: string[];
  contactsCc: string[];
  type: string;
  handleCheckboxChange: (checked: boolean, email: string, value: boolean) => void;
}) {
  return (
    <EmailStyle key={index}>
      <div className="input-b4fb">
        <input
          onChange={(e) => handleCheckboxChange(e.target.checked, email, false)}
          checked={contactsTo.includes(email)}
          type="checkbox"
        />
        <input
          onChange={(e) => handleCheckboxChange(e.target.checked, email, true)}
          checked={contactsCc.includes(email)}
          type="checkbox"
        />
      </div>
      <div className="name">{`${name} (${type})`}</div>
    </EmailStyle>
  );
}
