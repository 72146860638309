import axios from "axios";

const logEvent = (eventFunction: string, description: string, additionalInfo: string | undefined = undefined) => {
  (async () => {
    try {
      await axios.post("/api/AnalyticsLogging/logevent", {
        function: eventFunction,
        url: window.location.href,
        description,
        additionalInfo
      });
    } catch {}
  })();
};

const logPageView = () => {
  (async () => {
    try {
      await axios.post("/api/AnalyticsLogging/logpageview", { url: window.location.href });
    } catch {}
  })();
};

const logNPIPageView = (orderNumber: string) => {
  (async () => {
    try {
      await axios.post("/api/AnalyticsLogging/LogNPIPageView", { orderNumber });
    } catch {}
  })();
};

const logNPIDocumentView = (documentId: number) => {
  (async () => {
    try {
      await axios.post("/api/AnalyticsLogging/LogNPIDocumentView", { documentId });
    } catch {}
  })();
};

export { logEvent, logPageView, logNPIPageView, logNPIDocumentView };
