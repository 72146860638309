import styled from "@emotion/styled";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useParams } from "react-router-dom";
import AppSwitcher from "../../../src/layouts/app-switcher";
import { DocumentsProvider, useDocuments } from "../../Providers/DocumentsProvider";
import { OrderProvider, useOrder } from "../../Providers/OrderProvider";
import "../../styles/index.css";
import { RightArrow } from "../../ui/icons";
import DocumentsSection from "./DocumentsSection";
import { MainSectionWrap } from "./documentsStyles";
import FileDirectory from "./FileDirectory";
import { MainContentStyles, Root, OrderNotFound, AppLoadingSpinner } from "./indexStyles";
import NavBar from "./NavBar";
import OrderInfo from "./OrderInfo";
import { UserProvider, useUser } from "../../Providers/UserProvider";
import { logNPIPageView } from "../../helpers/analyticsLogger";

const AppSwitcherWrapper = (props) => {
  const { isAppSwitcherOpen, setIsAppSwitcherOpen } = props;
  const { order } = useOrder();
  return (
    <AppSwitcher
      side="left"
      isOpen={isAppSwitcherOpen}
      onCloseClick={() => setIsAppSwitcherOpen(false)}
      state={order?.stateCode}
    />
  );
};

export default function NewHome() {
  const { orderNumber } = useParams();
  const [isAppSwitcherOpen, setIsAppSwitcherOpen] = useState(false);
  useEffect(() => {
    if (orderNumber) {
      document.title = orderNumber;
      logNPIPageView(orderNumber);
    }
  }, [orderNumber]);

  useEffect(() => {
    const getEmail = async () => {
      const { data } = await axios.get("/login/getuserpersonalinfo");
      const beamerConfigScript = document.createElement("script");
      beamerConfigScript.text = `
      var beamer_config = {
        product_id : 'WdqQStfQ41060',
        user_email: '${data.email}',
        user_lastname: '${data.name}',
        user_id: '${data.email}',
        filter_by_url: true,
        file_number:'${orderNumber}'
      };`;
      document.head.appendChild(beamerConfigScript);

      const beamerSrcScript = document.createElement("script");
      beamerSrcScript.type = "text/javascript";
      beamerSrcScript.src = "https://app.getbeamer.com/js/beamer-embed.js";

      document.head.appendChild(beamerSrcScript);
    };

    getEmail();
  }, []);

  return (
    <>
      <Root>
        <DndProvider backend={HTML5Backend}>
          <DocumentsProvider>
            <UserProvider>
              <OrderProvider>
                <NavBar openAppSwitcher={() => setIsAppSwitcherOpen(true)} />
                <NewHomeContent />
                <AppSwitcherWrapper isAppSwitcherOpen={isAppSwitcherOpen} setIsAppSwitcherOpen={setIsAppSwitcherOpen} />
              </OrderProvider>
            </UserProvider>
          </DocumentsProvider>
        </DndProvider>
      </Root>
    </>
  );
}

const blockedOrders = new Map<string, string[]>();
blockedOrders.set("135088", ["mstern@madisontitle.com", "ikaras@madisoncres.com"]);
blockedOrders.set("118900", [
  "mstern@madisontitle.com",
  "ymeister@madisontitle.com",
  "dsmith@madisoncres.com",
  "ikaras@madisoncres.com",
  "lfreeman@madisoncres.com",
  "ffeigelstock@madisontitle.com"
]);
blockedOrders.set("MTANY-012937", ["mstern@madisontitle.com"]);
blockedOrders.set("164096", ["mstern@madisontitle.com", "ikaras@madisoncres.com", "ymeister@madisontitle.com"]);

const NewHomeContent = () => {
  const { order, isLoading: isOrderLoading } = useOrder();
  const { orderNumber } = useParams();
  const user = useUser();
  const { isLoading: isDocumentsLoading } = useDocuments();
  const [hideSide, setHideSide] = useState(false);

  useEffect(() => {
    if (!user.userEmail) {
      return;
    }

    if ([...blockedOrders.keys()].some((k) => orderNumber.toLowerCase().startsWith(k.toLowerCase()))) {
      const allowedEmails = blockedOrders.get(
        [...blockedOrders.keys()].find((k) => orderNumber.toLowerCase().startsWith(k.toLowerCase()))
      );
      if (!allowedEmails.includes(user.userEmail.toLowerCase())) {
        window.location.href = "/unauthorized";
      }
    }
  }, [user]);
  const getContent = () => {
    if (isOrderLoading) {
      return <AppLoadingSpinner />;
    }

    return (
      <MainContentStyles hideSide={hideSide}>
        <div className="hide-button" onClick={() => setHideSide(!hideSide)}>
          <RightArrow />
        </div>
        <div className="info-sidebar_wrap skeleton-box">
          <OrderInfo vertical={true} closeSideBar={() => setHideSide(true)} />
          <FileDirectory />
        </div>
        <MainSectionWrap hideSide={hideSide}>
          <DocumentsSection
            vertical={true}
            hideSide={hideSide}
            isDocumentsLoading={isDocumentsLoading}
            hasOrder={!!order && !!order.orderNumber}
          />
          {!isDocumentsLoading && !order && <OrderNotFound>Order {orderNumber} not found.</OrderNotFound>}
        </MainSectionWrap>
      </MainContentStyles>
    );
  };

  return getContent();
};
